<script>
	export let orientation = 'vertical';
	export let position = 'start';
	export let lineHeight = '100%';

	// $: console.log(position, orientation);
</script>

{#each Array(13).fill() as _, twohour}
	<div
		class="absolute flex gap-0.5 items-center text-gray-500 {orientation !== 'vertical'
			? 'h-full ' + (position === 'start' ? 'flex-col -top' : ' flex-col-reverse bottom')
			: 'w-full left'}-1 "
		style="font-size:10px;{orientation === 'vertical' ? 'top' : 'left'}: {twohour * 2 * 4}%;"
	>
		{('0' + ((twohour * 2 + 4) % 24)).slice(-2)}:00
		<div
			class="{orientation === 'vertical' ? 'h-px' : 'w-px'} bg-gray-800"
			style="mix-blend-mode:exclusion;{orientation === 'vertical' ? 'top' : 'left'}: {0.75 +
				twohour * 4}%;{orientation === 'vertical'
				? 'width: calc(100% - 28px);'
				: `height: ${lineHeight};position:absolute;`}"
		/>
	</div>
{/each}
