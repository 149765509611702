<script context="module">
	let timesUsed = 0;
	function update() {
// console.log("update")
		timesUsed++;
	}
</script>

<script>
	// 	import * as echarts from 'echarts/core';
	// import {
	//     GaugeChart
	// } from 'echarts/charts';
	// import {
	//     SVGRenderer
	// } from 'echarts/renderers';

	import dayjs from 'dayjs';
	export let divName = 'echartBar';
	update();
	let numberedDivName = divName + timesUsed;
	export let minHeight = '150px';
	export let maxHeight = '100%';
	export let action;
	export let renderer = 'canvas';

	let data = {
		bar: [3, 7, 2, 8, 5, 6, 5],
		axis: ['dude', 'bro', 'sis', 'gal', 'cat', 'red', '2020'],
		dots: false
	};
	var category = data.axis;
	var dotsData = data.dots.data;
	var lineData = data.line ? data.line.data : false;
	var barData = data.bar.data;
	export let option;

	// export let values = {}
	
	let myChart;
	let firstPass;

	function makeChart() {
// console.log("makeChart")
		if (!option) return;
		var chartDom = document.getElementById(numberedDivName);
		try {
		const { width, height } = chartDom?.getBoundingClientRect() || {width: 0, height:0};
		;
		if (typeof echarts === "undefined") return;
		myChart = echarts.init(chartDom, 'transparent', {
			devicePixelRatio: window.devicePixelRatio,
			renderer
		});} catch (e) {
			console.error(e, numberedDivName, divName)
		}
		// 			echarts.use(
		//     [TooltipComponent, GridComponent, LegendComponent, LineChart, BarChart, PictorialBarChart, CanvasRenderer]
		// );
		option.textStyle = {
			fontFamily: 'Exo 2',
			fontSize: '12px',
			fill: '#aaaaaa',
			color: '#aaaaaa',
			stroke: 'transparent',
			textBorderWidth: 0,
			...(option.textStyle || {})
		};
		firstPass = true;
		if (action) myChart?.dispatchAction(action);
	}
	let echartsLoaded;
	let divLoaded;
	let optionExists;
	if (!optionExists) optionExists = !!option
	$: if (echartsLoaded && divLoaded && optionExists && !firstPass && typeof echarts !== "undefined") {
		makeChart();
	};
	let innerWidth;
	$: if (firstPass) {
		try {
		option && myChart?.setOption(option);
		} catch (e) {
			console.error(e, divName, action, option)
		}
		try{

		
		if (action) {
			myChart?.dispatchAction(action);
			// console.log("dispatching: ", action, divName)
		}
	} catch (e) {
		console.error(e)
	}
		// alreadyHighlighted = action;
		// if (alreadyHighlighted) {
		//   alreadyHighlighted.type = "downplay";
		//   myChart?.dispatchAction(action);
		//   alreadyHighlighted = action;
		// }
		if (action) {
			myChart?.dispatchAction(action);
			// console.log("dispatching: ", action, divName)
		}
	}
	let prevWidth;
	$: if (firstPass && innerWidth !== prevWidth) {
		prevWidth = innerWidth;
		myChart?.resize();
		if (action) {
			myChart?.dispatchAction(action);
			// console.log("dispatching: ", action, divName)
		}
	}
	let setDivLoaded = () => divLoaded = true;
</script>

<svelte:head>
	{#if option}
	<script id={divName} src="/echarts.min.js" on:load={() => echartsLoaded = true}>
	</script>
	{/if}
</svelte:head>
<svelte:window bind:innerWidth />


<div
	id={numberedDivName}
	class="flex justify-center items-center"
	style="position:relative;width:100%;height:100%;min-height:{minHeight};max-height:{maxHeight}"
	use:setDivLoaded
>
<div class="absolute centreInContext w-full h-full">
	<slot></slot>
</div>
</div>
