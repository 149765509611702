<script>
	import dayjs from 'dayjs';
	import { screenTimeCategories } from '$lib/screenTimeCategories.js';
	import {
		makeDecimalIntoTime,
		mergeEventsCloserThan,
		categoryTask,
		query
	} from '$lib/connectUtils.js';

	import {
		IsElectron,
		UserEmail,
		Data,
		UserInfo,
		TodaysData,
		DateRange,
		IsDev
	} from '$lib/store.js';
	import { postUserInfo } from '$lib/utils.js';
	import Modal from './Modal.svelte';
	export let activityDetails;
	export let todaysDayjs;
	export let editingCategories;
	// $: console.log({ editingCategories });
	let [type, title] =
		activityDetails?.type?.split(': ') ||
		(activityDetails?.data?.url
			? [
					'Site',
					(activityDetails?.data?.url?.split('://')[1] || activityDetails?.data?.url)?.split('/')[0]
			  ]
			: ['App', activityDetails?.data?.app]);
	let originalCategory = activityDetails?.data?.categories?.[0];
	let originalSubcategory = activityDetails?.data?.categories[1];
	let selectedType = activityDetails?.data?.focus
		? 'Focus Work'
		: `${activityDetails?.data?.work ? 'Work' : 'Not work'}`;
	let selectedCategory;

	$: possibleSubcategories =
		Array.from(
			new Set(
				screenTimeCategories
					.filter(
						([cat, regex]) =>
							cat.filter((c) => c !== 'Focus Work' && c !== 'Work')[0] ===
							(selectedCategory || originalCategory)
					)
					.map(([cat, regex]) => cat.filter((c) => c !== 'Focus Work' && c !== 'Work')[1])
			)
		) || [];

	let selectedSubcategory;
	$: if (!selectedSubcategory || !possibleSubcategories.includes(selectedSubcategory))
		selectedSubcategory = selectedCategory && possibleSubcategories?.[0];
	let newCategory = '',
		work = false,
		focus = false,
		editWork,
		isProject;
	let newStrings =
		activityDetails?.data?.url
			?.split('://')
			[activityDetails?.data?.url?.split('://').length - 1]?.split(
				activityDetails?.data?.categories?.includes('Web Browsing') ? '/' : '|'
			)[0] ||
		activityDetails?.data?.title ||
		activityDetails?.data?.app;
	let existingMatchReasonStrength = Math.max(
		...(((activityDetails?.data?.reason || [])[0] || [[], []])[1]?.map(
			(r) => r && r[1] && r[1]?.[0] && r[1]?.[0].length
		) || [0])
	);
	let newMatchReasonStrength, patternMatches, patternMatchIsStronger;
	$: try {
		newMatchReasonStrength =
			(newStrings?.length > (activityDetails?.data?.url ? 4 : 0) &&
				Math.max(
					...['title', 'url', 'app', 'domain', 'path', 'bundleId']
						.filter((string) =>
							new RegExp(newStrings, 'i').test(activityDetails?.data[string] || '')
						)
						.map(
							(string) =>
								((activityDetails?.data[string] || '').match(new RegExp(newStrings, 'i'))[0] || '')
									.length
						)
				)) ||
			0;
		patternMatches = !!newMatchReasonStrength;
		patternMatchIsStronger = newMatchReasonStrength > existingMatchReasonStrength;
	} catch {}

	// $: console.log({
	// 	activityDetails,
	// 	newMatchReasonStrength,
	// 	existingMatchReasonStrength,
	// 	newStrings
	// });
	export let events;
	$: events = (events || $TodaysData.activitywatch?.window?.events || [])?.filter((event) =>
		type === 'App'
			? event.app === activityDetails?.data?.app
			: (event?.url || event.title || event.app)?.includes(title)
	);
	let showFullEvent;
	let showReason;
	let showPersonal, addPersonal;
	async function deleteActivity(clickEvent, data) {
		console.log(clickEvent, data);
		clickEvent?.stopPropagation?.();
		if (
			!confirm(
				'Delete this ' +
					makeDecimalIntoTime(data?.duration / 3600, 'durationNoZeroWithSeconds') +
					' activity?'
			)
		)
			return;
		let target = clickEvent?.currentTarget || clickEvent?.target;
		target?.classList.add('animate-pulse');
		if (target) target.innerText = 'Deleting...';
		await window.api?.call('deleteActivity', data);

		if (target) target.innerText = 'Deleted!';
		setTimeout(() => {
			events = (events || $TodaysData.activitywatch?.window?.events || [])?.filter(
				(event) =>
					event.id !== data.id &&
					(type === 'App'
						? event.app === activityDetails?.data?.app
						: (event?.url || event.title || event.app)?.includes(title))
			);
			query('screentime')(0, true, true).then((_) => target?.classList?.remove('animate-pulse'));
		}, 1200);
	}

	function updateLiveData(activityDetails, newCategory, newStrings, regex) {
		activityDetails.data = {
			...(activityDetails.data || []),
			categories: newCategory?.filter((cat) => cat !== 'Work' && cat !== 'Focus Work') || [
				'No category'
			],
			reason: !newStrings
				? activityDetails.data.reason
				: [[newCategory, [[activityDetails.data.url ? 'url' : 'app', [newStrings, null]]]]],
			work: newCategory.includes('Work'),
			focus: newCategory.includes('Focus Work')
		};
		activityDetails.data.work = newCategory.includes('Work');
		activityDetails.data.focus = newCategory.includes('Focus Work');

		Object.entries($Data[`["timeseries","time_series/activitywatch","noCache"]`]).map(
			([date, data]) => {
				if (data?.window?.events) {
					data.window.events = data?.window?.events?.map((e) => {
						if (
							(!regex && newCategory.includes(e.categories[0])) ||
							(regex &&
								['title', 'url', 'app', 'domain'].some((string) => regex.test(e[string] || '')))
						)
							e = {
								...(e || []),
								categories: newCategory?.filter(
									(cat) => cat !== 'Work' && cat !== 'Focus Work'
								) || ['No category'],
								reason: !newStrings
									? e.reason
									: [[newCategory, [[e.url ? 'url' : 'app', [newStrings, null]]]]],
								work: newCategory.includes('Work'),
								focus: newCategory.includes('Focus Work')
							};
						return e;
					});
					if (
						dayjs(activityDetails.data.timestamp).format('YYYY-MM-DD') ===
						dayjs().subtract(4, 'hour').subtract($DateRange.daysInPast, 'day').format('YYYY-MM-DD')
					) {
						$TodaysData.productiveEvents = $Data[
							`["timeseries","time_series/activitywatch","noCache"]`
						][dayjs(activityDetails.data.timestamp).format('YYYY-MM-DD')]?.window.events.filter(
							(e) => e?.work
						);
						$TodaysData.unproductiveEvents = $Data[
							`["timeseries","time_series/activitywatch","noCache"]`
						][dayjs(activityDetails.data.timestamp).format('YYYY-MM-DD')]?.window.events.filter(
							(e) => !e?.work
						);
					}
					$Data[`["timeseries","time_series/activitywatch","noCache"]`][date] = data;
				}
			}
		);
	}
</script>

{#if showFullEvent}
	<Modal bind:show={showFullEvent}>
		<div class="h-full max-h-full flex flex-col gap-2 overflow-scroll">
			{#each Object.entries(showFullEvent || {}) as [key, value]}
				<div class="uppercase text-sm font-semibold text-gray-200">
					{key}
				</div>
				{#if key === 'reason'}
					<div class="grid grid-cols-2 gap-4">
						{#each value as [categoryChosen, stringsMatched]}
							<div class="uppercase text-sm font-semibold text-gray-200">
								{categoryChosen}
							</div>
							<div>
								{#each (stringsMatched?.length && stringsMatched) || [] as [string, matches]}
									<div class="uppercase text-sm font-semibold text-gray-200">
										{string}
									</div>
									<div>
										{#each (matches?.length && matches) || [] as match}
											<div class="uppercase text-sm font-semibold text-gray-200">
												{match}
											</div>
										{/each}
									</div>
								{/each}
							</div>
						{/each}
					</div>
				{:else if typeof value === 'object'}
					<div class="text-sm text-gray-200">
						{JSON.stringify(value, null, 2)}
					</div>
				{:else}
					<div class="text-sm text-gray-200">
						{value}
					</div>
				{/if}
			{/each}
		</div>
	</Modal>
{/if}
<div class="grid gap-3">
	{#if activityDetails}
		<div>
			<div class="uppercase text-xs font-semibold text-gray-300">
				{type}
			</div>
			<div
				on:click={() => ($IsDev ? (showFullEvent = activityDetails.data) : '')}
				style="color:{activityDetails.data.work ? '#00ff33' : '#ff5050'}"
			>
				{title || activityDetails.data.app}
			</div>
		</div>
		{#if editingCategories}
			<!-- <Modal bind:show={editingCategories} options={{ topLevel: false, cssText: 'z-index:52;' }}> -->
			<div>
				<div class="uppercase text-xs font-semibold text-gray-300">Work status</div>
				<div class="flex flex-wrap mt-1 gap-2 text-xs uppercase">
					{#each ['Focus Work', 'Work', 'Not work'] as category}
						<button
							on:click={() => {
								selectedType = category;
							}}
							class="px-2 py-0.5 uppercase {category === selectedType ? 'bg-white text-black' : ''}"
							>{category == 'Work' ? 'Shallow work' : category}</button
						>
					{/each}
				</div>
			</div>
			<div>
				<div class="uppercase text-xs font-semibold text-gray-300">New Category</div>
				<div class="flex flex-wrap mt-1 gap-2 text-xs uppercase">
					{#each Array.from(new Set(screenTimeCategories
								.filter(([cat, regex]) => (cat.includes(selectedType) && !(selectedType == 'Work' && cat.includes('Focus Work'))) || (selectedType === 'Not work' && !cat.includes('Work')))
								.map(([cat, regex]) => cat.filter((c) => c !== 'Focus Work' && c !== 'Work')[0]))) as category}
						<button
							on:click={() => {
								selectedCategory = category;
								selectedSubcategory = possibleSubcategories?.[0];
							}}
							class="px-2 py-0.5 uppercase {category === (selectedCategory || originalCategory)
								? 'bg-white text-black'
								: ''}">{category}</button
						>
					{/each}
				</div>
			</div>
			<div class="mt-4">
				<div class="uppercase text-xs font-semibold text-gray-300">New Subcategory</div>
				<div class="flex flex-wrap mt-1 gap-2 text-xs uppercase">
					{#each possibleSubcategories as category}
						<button
							on:click={() => {
								selectedSubcategory = category;
							}}
							disabled={!category}
							class="px-2 py-0.5 uppercase {category ===
							(selectedSubcategory || originalSubcategory)
								? 'bg-white text-black'
								: ''}">{category || 'No subcategories present'}</button
						>
					{/each}
				</div>
			</div>
			<div class="mt-4 text-sm">
				<div class="uppercase text-xs font-semibold text-gray-300">
					Pattern from Title, App or URL to recognise
				</div>
				<textarea
					class="mt-1 p-2 bg-black w-full"
					bind:value={newStrings}
					placeholder={'e.g. ' +
						[
							activityDetails.data.title,
							activityDetails.data.app,
							activityDetails.data?.url
								? activityDetails.data?.url
										?.split('/')
										[/(http|file)(s)*:\/\//.test(activityDetails.data.url) ? 2 : 0]?.replace(
											'www.',
											''
										)
								: 'medium.com'
						].join(', or ')}
				/>
			</div>
			<div>
				<p class="text-sm">
					The pattern is a Regular Expression. To add more than one pattern, separate them with the
					| character.
				</p>
				{#if selectedSubcategory && selectedSubcategory !== originalSubcategory && !newStrings}
					<p class="text-red-600 text-sm">
						Tell us how to recognise that an event is in the new category.
					</p>
				{/if}
				{#if newStrings && newStrings?.length < 5}
					<p class="text-red-600 text-sm">
						The longer the pattern, the more reliably it will match - patterns should be at least 5
						characters long.
					</p>
				{:else if selectedSubcategory || newStrings?.length > 4}
					<p class="text-sm {patternMatches ? 'text-green-500' : 'text-red-600'}">
						{patternMatches ? `Pattern matches event.` : 'Pattern does not match event.'}
					</p>
				{/if}
			</div>
			<div class="flex justify-between">
				<button
					class="bg-white text-black w-1/3"
					on:click={() => {
						editingCategories = false;
					}}>{'Cancel'}</button
				>
				<button
					disabled={((selectedSubcategory === originalSubcategory || !selectedSubcategory) &&
						selectedCategory === originalCategory) ||
						!patternMatches ||
						newStrings?.length < 5}
					class="bg-white text-black w-1/3"
					on:click={() => {
						let newCategory = screenTimeCategories.find(
							([cat, regex]) =>
								cat.includes(selectedCategory) &&
								(!selectedSubcategory || cat.includes(selectedSubcategory))
						)[0];
						let regex;
						try {
							regex = new RegExp(newStrings, 'i');
						} catch (e) {
							console.error(e);
							if (
								newStrings.startsWith('*') ||
								newStrings.startsWith('+') ||
								newStrings.includes('(+') ||
								newStrings.includes('(*')
							) {
								try {
									newStrings = newStrings.replace(/(\*|\+)/, '.$1');
									regex = new RegExp(newStrings, 'i');
								} catch (e) {
									newStrings = newStrings.replace(/\[\-\[\]\{\}\(\)\*\+\?,\\\^\$\|\#]/g, '.');
									regex = new RegExp(newStrings, 'i');
									console.error(e);
								}
							} else {
								newStrings = newStrings.replace(/\[\-\[\]\{\}\(\)\*\+\?,\\\^\$\|\#]/g, '.');
								regex = new RegExp(newStrings, 'i');
							}
						}
						let data = activityDetails.data;
						if (
							data.categories?.includes('Web Browsing') ||
							!data?.categories?.[0] ||
							patternMatchIsStronger
						) {
							categoryTask(
								'updateCategories',
								String.fromCharCode(...$UserEmail?.split('').map((l) => l.charCodeAt(0) + 2)),
								[[newCategory, newStrings?.trim()]]
							);
						}
						$UserInfo.personalCategories = [
							...($UserInfo.personalCategories || []),
							[newCategory, newStrings?.trim()]
						];
						postUserInfo();

						updateLiveData(activityDetails, newCategory, newStrings, regex);

						// categoryTask(
						// 	'updateCategories',
						// 	String.fromCharCode(...$UserEmail?.split('').map((l) => l.charCodeAt(0) + 2)),
						// 	[[newCategory, newStrings.trim()]]
						// );
						editingCategories = false;
					}}>{'Done'}</button
				>
			</div>
			<!-- </Modal> -->
		{:else}
			<div>
				<div class="uppercase text-xs font-semibold text-gray-300">Category</div>
				<div class="flex gap-2 mt-1 text-xs items-center">
					{#each activityDetails.data.categories as category}
						<div
							class="rounded-full uppercase font-semibold text-black px-2 py-0.5"
							style="background-color:{activityDetails.data?.work ? '#00ff33' : '#ff5050'}"
						>
							{category}
						</div>
					{/each}
					<button
						on:click={() => {
							editingCategories = !editingCategories;
							selectedCategory = activityDetails.data.categories?.[0];
							selectedSubcategory = activityDetails.data.categories[1];
						}}
						class="py-0 px-1.5"
					>
						{!editingCategories ? 'edit' : 'done'}
					</button>
					{#if !editWork}
						<button
							on:click={() => {
								if (!activityDetails.data.work) editWork = true;
								else {
									$UserInfo.workModifications = {
										...($UserInfo.workModifications || {}),
										[activityDetails.data?.categories[0]]: false
									};
									postUserInfo();
									activityDetails.data.work = false;
									activityDetails.data.focus = false;
									updateLiveData(activityDetails, activityDetails.data.categories);
								}
							}}
							class="py-0 px-1.5"
						>
							This category {activityDetails.data.work ? "shouldn't" : 'should'} be work
						</button>
						{#if $UserInfo.workModifications?.[activityDetails.data?.categories[0]] !== undefined}
							<button
								on:click={() => {
									if (!confirm('Are you sure you want to reset this category?')) return;

									activityDetails.data.work =
										$UserInfo.workModifications?.[activityDetails.data?.categories[0]] !== true;
									activityDetails.data.focus =
										$UserInfo.focusModifications?.[activityDetails.data?.categories[0]] !== true;

									delete $UserInfo.workModifications?.[activityDetails.data?.categories[0]];
									delete $UserInfo.focusModifications?.[activityDetails.data?.categories[0]];
									console.log($UserInfo.workModifications);
									postUserInfo();
									updateLiveData(activityDetails, activityDetails.data.categories);
								}}
								class="py-0 px-1.5"
							>
								Edited. Reset?
							</button>
						{/if}
					{:else}
						<div class="flex items-center gap-2 bg-black rounded-lg px-2 py-1 font-semibold">
							What kind of work?
							<button
								on:click={() => {
									activityDetails.data.work = true;
									activityDetails.data.focus = true;
									$UserInfo.workModifications = {
										...($UserInfo.workModifications || {}),
										[activityDetails.data?.categories[0]]: true
									};
									$UserInfo.focusModifications = {
										...($UserInfo.focusModifications || {}),
										[activityDetails.data?.categories[0]]: true
									};
									query('screentime')(0, true, true);
									postUserInfo();
									updateLiveData(
										activityDetails,
										[
											'Work',
											'Focus Work',
											...activityDetails.data?.categories.filter(
												(a) => a != 'Work' && a != 'Focus Work'
											)
										],
										null,
										null
									);
									editWork = false;
								}}>Deep work</button
							>
							<button
								on:click={() => {
									activityDetails.data.work = true;
									$UserInfo.workModifications = {
										...($UserInfo.workModifications || {}),
										[activityDetails.data?.categories[0]]: true
									};
									query('screentime')(0, true, true);
									postUserInfo();
									updateLiveData(
										activityDetails,
										[
											'Work',
											...activityDetails.data?.categories.filter(
												(a) => a != 'Work' && a != 'Focus Work'
											)
										],
										null,
										null
									);
									editWork = false;
								}}>Shallow work</button
							>
						</div>
					{/if}
				</div>
				<div class="mt-2">
					<button
						on:click={() => (showReason = true)}
						class="cursor-pointer text-xs font-semibold text-[#3397ff] mt-2"
					>
						View categorisation reason
					</button><br />

					<button
						on:click={() => {
							showPersonal = true;
							addPersonal = true;
						}}
						class="cursor-pointer text-xs font-semibold text-[#3397ff] px-2 py-0.5"
					>
						Add new custom category
					</button>

					<button
						on:click={() => (showPersonal = true)}
						class="cursor-pointer text-xs font-semibold text-[#3397ff] px-2 py-0.5"
					>
						View all custom categories
					</button>
					{#if $IsElectron}
						<button
							on:click={(event) => {
								deleteActivity(event, activityDetails.data);
							}}
							class="cursor-pointer text-xs font-semibold text-[#da3957] px-2 py-0.5"
						>
							Delete activity
						</button>
					{/if}
				</div>
			</div>
			{#if showReason === true}
				<Modal bind:show={showReason}>
					{#each activityDetails.data.reason as [categoryChosen, stringsMatched], i}
						<div class="uppercase text-sm flex gap-2 flex-wrap font-semibold text-gray-200">
							{#each categoryChosen.filter((a) => !a.toLowerCase().includes('work')) as category}
								<div
									class="rounded-full uppercase font-semibold text-black px-2 py-0.5"
									style="background-color:{activityDetails.data.work ? '#00ff33' : '#ff5050'}"
								>
									{category}
								</div>
							{/each}
						</div>
						<div class="grid gap-4" style="grid-template-columns: 1fr 1fr;">
							<div class="uppercase text-xs font-semibold">Match type</div>
							<div class="uppercase text-xs font-semibold">Matching string</div>
							<!-- <div class="uppercase text-xs font-semibold">Action</div> -->
							{#each (stringsMatched?.length && stringsMatched?.filter((a) => a)) || [] as [string, matches, regex] (string)}
								<div class="text-sm text-gray-200">
									{string}
								</div>
								<div>
									{#each (matches?.length && matches?.filter((a, i, ar) => a && i === ar.findIndex((m) => m == a))) || [] as match (match)}
										<div class="text-sm text-gray-200">
											{match} matched by: {regex}
											<button
												on:click={async (event) => {
													event.currentTarget.innerText = 'Deleting...';
													event.currentTarget.disabled = true;
													event.currentTarget.classList.add('animate-pulse');
													let res = await fetch(
														`https://leaderboards-queue.magicflow.workers.dev/?path=deleteCategories&string=|${regex}&category=${categoryChosen
															.map((c) => encodeURIComponent(c))
															.join(',')}`
													).catch((e) => console.error(e)); // Coding,Editing%20%26%20IDEs
													let r = await res?.json();
													console.log(r.error, r, event);
													let target = event.currentTarget || event.target;
													if (i === 0 && activityDetails.data?.reason?.[1]) {
														let nextCategory = activityDetails.data.reason[1];
														let newCategory = nextCategory[0];
														let newStrings =
															nextCategory[1]?.[0]?.[2] || nextCategory[1]?.[0]?.[1]?.[0];

														console.log(
															'updateLiveData for: ',
															activityDetails,
															newStrings,
															newCategory,
															nextCategory
														);
														try {
															updateLiveData(
																activityDetails,
																newCategory,
																newStrings,
																new RegExp(newStrings, 'i')
															);
														} catch (e) {
															console.error('updateLiveData', e);
														}
													}
													function wsend(obj) {
														if (window.ws?.readyState === WebSocket.OPEN) {
															try {
																window.ws?.send(JSON.stringify(obj));
															} catch (e) {
																console.error(e);
																setTimeout(() => {
																	wsend(obj);
																}, 1000);
															}
														} else {
															setTimeout(() => {
																wsend(obj);
															}, 100);
														}
													}
													try {
														window?.api?.call('updateCategories');
														wsend({ name: 'updateCategories', args: [] });
													} catch (e) {
														console.error(e);
													}
													if (r && !r.error && target) {
														target.innerText = 'Deleted!';
														target.classList.remove('animate-pulse');
														target.classList.add('bg-green-500');
													} else if (target) {
														target.innerText = 'Error';
														target.classList.remove('animate-pulse');
														target.classList.add('bg-red-500');
														setTimeout(() => {
															target.innerText = 'Delete this match';
															target.classList.remove('bg-red-500');
															target.disabled = false;
														}, 2000);
													}
												}}
												class="text-sm">Delete this match</button
											>
										</div>
									{/each}
								</div>
							{/each}
						</div>
					{/each}
				</Modal>
			{/if}
			{#if showPersonal}
				<Modal bind:show={showPersonal}>
					<div class="text-xl font-semibold mb-4">Your custom categories</div>
					{#if addPersonal}
						<input class="w-full" bind:value={newCategory} placeholder="Category name" />
						<input class="w-full" bind:value={newStrings} placeholder="String to match" />
						<label>
							<input type="checkbox" bind:checked={work} /> Work
						</label>
						<label>
							<input type="checkbox" bind:checked={focus} /> Focus
						</label>
						<!-- <label>
						<input type="checkbox" bind:checked={activityDetails.focus}/> Override
					</label> -->
						<button
							on:click={() => {
								$UserInfo.personalCategories = [
									...($UserInfo.personalCategories || []),
									[
										[...(focus ? ['Focus Work'] : []), ...(work ? ['Work'] : []), newCategory],
										newStrings?.trim()
									]
								];
								postUserInfo();
								addPersonal = false;
							}}>Add</button
						>
					{:else}
						<div class="grid gap-2 items-center" style="grid-template-columns: 1fr 1fr auto;">
							<div class="uppercase text-sm font-semibold text-gray-200">Category</div>
							<div class="uppercase text-sm font-semibold text-gray-200">Match pattern</div>
							<div class="uppercase text-sm font-semibold text-gray-200">Action</div>
							{#each $UserInfo.personalCategories || [[['No custom categories'], '']] as [categoryChosen, stringsMatched]}
								<div class="uppercase text-sm flex gap-2 flex-wrap font-semibold text-gray-200">
									{#each (typeof categoryChosen === 'string' ? [categoryChosen] : categoryChosen)?.filter((a) => !a
												.toLowerCase()
												.includes('work')) as category}
										<div
											class="rounded-full uppercase font-semibold text-black px-2 py-0.5"
											style="background-color:{activityDetails.data.work ? '#00ff33' : '#ff5050'}"
										>
											{category}
										</div>
									{/each}
								</div>
								{#if stringsMatched}
									{JSON.stringify(stringsMatched)}
									<button
										on:click={() => {
											$UserInfo.personalCategories = $UserInfo.personalCategories.filter(
												([catChosen, strMatched]) =>
													strMatched +
														(typeof catChosen === 'string' ? [catChosen] : catChosen).join() !==
													stringsMatched +
														(typeof categoryChosen === 'string'
															? [categoryChosen]
															: categoryChosen
														).join()
											);
											postUserInfo();
											query('screentime')(0, true, true);
										}}>Delete</button
									>
								{/if}
							{/each}
						</div>
					{/if}
				</Modal>
			{/if}
			{#if activityDetails.data.title}
				<div>
					<div class="uppercase text-xs font-semibold text-gray-300">Window Title</div>
					<div>
						{activityDetails.data.title}
					</div>
				</div>
			{/if}
			{#if activityDetails.data.url}
				<div>
					<div class="uppercase text-xs font-semibold text-gray-300">URL</div>
					<div class=" break-all">
						{activityDetails.data.url}
					</div>
				</div>
			{/if}
			<div>
				<div class="uppercase text-xs font-semibold text-gray-300">Date</div>
				<div>
					{dayjs(activityDetails.data.timestamp).format('HH:mm on YYYY-MM-DD (Z)')}
				</div>
			</div>
		{/if}
		<!-- {#each Object.entries(activityDetails.data) as [key, value]}
        <div>
            {key + ': ' + value}
        </div>
    {/each} -->
		<!-- <select>
        {#each screenTimeCategories as [category, regex]}
            <option value={category}>{category.join(': ')}</option>
        {/each}
    </select> -->
		<div class="text-sm bg-gray-800 rounded-xl border-2 p-2 my-2 border-gray-700">
			<div class="font-semibold text-base text-center">
				Your usage of {title}
				{todaysDayjs ? 'on ' + todaysDayjs.format('MMM DD YYYY') : 'today'}
			</div>
			<div class="flex justify-between px-4">
				<div class="flex gap-2">
					<div class="font-semibold">Total time</div>
					<div>
						{makeDecimalIntoTime(
							events.map((e) => e.duration).reduce((a, b) => a + b, 0) / 3600,
							'durationNoZeroWithSeconds'
						)}
					</div>
				</div>
				<div class="flex gap-2">
					<div class="font-semibold"># of events</div>
					<div>{events.length}</div>
				</div>
			</div>
			<div>
				{#each [...events]
					.filter((e, i, ar) => e.duration && i === ar.findIndex((m) => m.id === e.id))
					.reverse() as event (event.id)}
					<div
						on:click={() => ($IsDev ? (showFullEvent = event) : '')}
						class="flex items-center gap-4 text-sm bg-gray-700 rounded-full p-1 px-4 border-2 my-2 border-gray-600"
					>
						<div class="flex">
							{#each dayjs(event.timestamp).format('HH:mm') as l}
								<span class="w-[0.55em] text-center">{l}</span>
							{/each}
						</div>
						<div class="mr-2 break-words">
							{(event.title || event.url || event.app).slice(0, 60)}
						</div>
						<div class=" ml-auto">
							{makeDecimalIntoTime(event.duration / 3600, 'durationNoZeroWithSeconds')}
						</div>
						{#each [event.categories[0]] as category}
							<div
								class="rounded-full uppercase text-xs font-semibold text-black px-2 py-0.5"
								style="background-color:{event.work ? '#00ff33' : '#ff5050'}"
							>
								{category}
							</div>
						{/each}
						{#if event.url}
							<a
								class="flex-shrink h-4 w-4"
								href="https://{event.url?.replace(/https?:\/\//, '')}"
								target="_blank"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fill-rule="evenodd"
										d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
										clip-rule="evenodd"
									/>
								</svg>
							</a>
						{/if}
						<!-- delete activity button -->
						{#if $IsElectron}
							<button class="flex-shrink text-red-500" on:click={(e) => deleteActivity(e, event)}>
								<svg
									class="h-4 w-4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
										clip-rule="evenodd"
									/>
								</svg>
							</button>
						{/if}
					</div>
				{/each}
			</div>
		</div>
	{/if}
</div>
