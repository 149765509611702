<script context="module">
	import Echart from './Echart.svelte';
	import dayjs from 'dayjs';
	import { makeDecimalIntoTime } from '$lib/connectUtils.js';
</script>

<script>
	// 	import * as echarts from 'echarts/core';
	// import {
	//     GaugeChart
	// } from 'echarts/charts';
	// import {
	//     SVGRenderer
	// } from 'echarts/renderers';
	export let valueFormatter;
	export let divName = 'echartBar';
	export let options = {};
	export let colors = ['#1478f4', '#439ef6', '#ff0073'];
	export let type = 'category';
	export let xName;
	export let zeroScale = true;
	export let grid;
	export let showXAxis = true;
	export let symbol = 'emptyCircle';
	export let minHeight = '150px';
	export let name;
	export let data = {
		dots: {
			name: 'dotlines',
			data: []
		},
		line: {
			name: 'line',
			data: []
		},
		bar: {
			name: 'bar',
			data: []
		},
		axis: []
	};
	// $: if (divName.startsWith('metricGraph')) console.log({ data }, divName);

	// Generate data
	$: axis = data.axis || [];
	$: dots = data.dots || {};
	$: line = data.line || {};
	$: line2 = data.line2 || {};
	$: bar = data.bar || {};
	$: dotsData = (dots && dots.data) || [];
	$: lineData = (line && line.data) || [];
	$: line2Data = (line2 && line2.data) || [];
	$: barData = (bar && bar.data) || [];

	let gradients = [
		{
			type: 'linear',
			x: 1,
			y: 0,
			x2: 0,
			y2: 0,
			colorStops: [
				{
					offset: 0,
					color: colors?.[0] // color at 0%
				},
				{
					offset: 1,
					color: colors[1] // color at 100%
				}
			],
			global: false // default is false
		},
		{
			type: 'linear',
			x: 0,
			y: 0,
			x2: 0,
			y2: 1,
			colorStops: [
				{
					offset: 0,
					color: colors?.[0] // color at 0%
				},
				{
					offset: 0.2,
					color: colors?.[0].slice(0, 7) + '33' // color at 0%
				},
				{
					offset: 0.6,
					color: colors?.[0].slice(0, 7) + '11' // color at 0%
				},
				{
					offset: 1,
					color: colors?.[0].slice(0, 7) + '00' // color at 100%
				}
			],
			global: false // default is false
		}
	];

	$: option = {
		responsive: true,
		maintainAspectRatio: false,
		grid: grid || {
			left: 30,
			top: Number(minHeight.replace('px', '')) / 6,
			right: 10,
			bottom: (xName ? 10 : 0) + showXAxis * 10 + Number(minHeight.replace('px', '')) / 6
		},
		tooltip: {
			trigger: 'axis',
			appendToBody: true,
			axisPointer: {
				type: 'shadow'
			},
			backgroundColor: '#112244',
			borderColor: '#223366',
			textStyle: {
				color: '#efefff'
			},
			valueFormatter
		},
		legend: options.hideLegend
			? false
			: {
					data: [dots.name || name, bar.name || name, line ? line.name || name : false].filter(
						(a) => a
					),
					textStyle: {
						color: '#ccc'
					}
			  },
		xAxis: {
			show: showXAxis,
			type,
			name: xName,
			nameLocation: 'middle',
			nameTextStyle: {
				lineHeight: 20
			},
			minInterval: type === 'time' ? 3600 * 24 * 1000 : false,
			data: type === 'category' ? axis : false,
			axisLine: {
				lineStyle: {
					color: '#ccc'
				}
			},
			axisLabel: {
				formatter:
					type === 'category'
						? '{value}'
						: function (value, index) {
								return dayjs(value).format('MMM D');
						  }
			}
		},
		yAxis: {
			scale: !zeroScale,
			axisLabel: {
				formatter:
					Math.max(...(dotsData || []), ...(barData || []), ...(lineData || [])) > 1000
						? function (value, index) {
								return Math.round(value / 100) / 10 + 'k';
						  }
						: '{value}'
			},
			splitLine: { show: false },
			minInterval: 1,
			axisLine: {
				lineStyle: {
					color: '#ccc'
				}
			}
		},
		series: [
			line
				? {
						name: line.name || name,
						type: 'line',
						smooth: true,
						showAllSymbol: symbol ? true : false,
						connectNulls: true,
						symbol,
						itemStyle: {
							color: colors?.[0].slice(0, 7) + (line.opacity ? '44' : 'ff'),
							opacity: line.opacity || 1
						},
						emphasis: { itemStyle: { color: colors?.[0] } },
						symbolSize: symbol ? 7 : false,
						data:
							type === 'category'
								? lineData
								: lineData.map((line, i) => [dayjs(axis[i]).format(), line])
				  }
				: false,
			line2
				? {
						name: line2.name || name,
						type: 'line',
						smooth: true,
						showAllSymbol: symbol ? true : false,
						connectNulls: true,
						symbol: line2.symbol || symbol,
						itemStyle: {
							color: colors?.[0].slice(0, 7) + (line2.opacity ? '44' : 'ff'),
							opacity: line2.opacity || 1
						},
						emphasis: { itemStyle: { color: colors?.[0] } },
						symbolSize: line2.symbol !== false && symbol ? 7 : false,
						data: line2Data
				  }
				: false,
			dots
				? {
						name: dots.name || name,
						type: 'pictorialBar',
						symbol: 'rect',
						itemStyle: {
							color: gradients[1]
							// `url(#${divName}34-gradient-2)`
						},
						emphasis: {
							itemStyle: {
								color: gradients[1]
								// `url(#${divName}34-gradient-2)`
							}
						},
						symbolRepeat: true,
						symbolSize: [10, 10],
						symbolMargin: 0,
						z: -10,
						data:
							type === 'category'
								? dotsData
								: dotsData.map((dot, i) => [dayjs(axis[i]).format(), dot])
				  }
				: false,
			bar
				? {
						name: bar.name || name,
						type: 'bar',
						barWidth: 10,
						itemStyle: {
							barBorderRadius: 5,
							color: gradients?.[0]
							// `url(#${divName}23-gradient-0)`
						},
						emphasis: { itemStyle: { color: colors?.[0] } },
						data:
							type === 'category'
								? barData
								: barData.map((bar, i) => [dayjs(axis[i]).format(), bar || 0])
				  }
				: false
		].filter((a) => a)
	};
</script>

<Echart {option} {divName} {minHeight} />
<svg style="position:fixed;height:0;width:0;">
	<defs>
		<linearGradient
			id="{divName}23-gradient-0"
			x1="1"
			y1="0"
			x2="0"
			y2="0"
			gradientUnits="objectBoundingBox"
		>
			<stop offset="0%" stop-color={colors?.[0]} />
			<stop offset="100%" stop-color={colors[1]} />
		</linearGradient>
	</defs></svg
>

{#if dots && dotsData}
	<svg style="position:fixed;height:0;width:0;">
		<defs>
			<linearGradient id="{divName}34-gradient-2" x1="0" y1="0" x2="0" y2="1">
				<stop offset="0%" stop-color={colors?.[0]} />
				<stop offset="20%" stop-color={colors?.[0].slice(0, 7) + '33'} />
				<stop offset="60%" stop-color={colors?.[0].slice(0, 7) + '11'} />
				<stop offset="100%" stop-color={colors?.[0].slice(0, 7) + '00'} />
			</linearGradient>
		</defs></svg
	>
{/if}
