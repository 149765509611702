<script>
	import ProductivityMetrics from './ProductivityMetrics.svelte';
	import ComparisonGraph from './ComparisonGraph.svelte';
	import BarLineCombo from './chartFunctions/BarLineCombo.svelte';
	import { screenTimeCategories } from '$lib/screenTimeCategories.js';
	import Icon from '../Icon.svelte';
	import Modal from '../Modal.svelte';

	import TimeTracked from './TimeTracked.svelte';
	import CategoryPie from './chartFunctions/CategoryPie.svelte';
	import MetricGraphs from './MetricGraphs.svelte';
	import dayjs from 'dayjs';
	import QuickRecategorize from '../QuickRecategorize.svelte';
	import { makeDecimalIntoTime, mergeEventsCloserThan } from '$lib/connectUtils.js';
	import { postUserInfo, delay } from '$lib/utils.js';
	import { timeRangeForActivity } from '$lib/dataProcessing.js';
	import {
		TodaysData,
		IsDev,
		IsNative,
		Platform,
		UserEmail,
		UserInfo,
		DateRange,
		Data
	} from '$lib/store.js';

	export let showDetailsFor,
		showActivity,
		activityDetails,
		summary = true;
	export let datesScreenTime,
		datesToAnalyse,
		aggregateAndSort,
		small,
		workTypes,
		since,
		categories,
		// total_time,
		// dataIndex,
		divName,
		productivityMetrics,
		// productivityByHour,
		productivityLastHour,
		switchesSorted,
		browsers;
	// $: if (datesScreenTime) console.log(productivityMetrics);

	$: sessions =
		datesToAnalyse && datesScreenTime
			? datesScreenTime.map((d) => d?.window?.sessions).flat()
			: $TodaysData?.activitywatch?.window?.sessions || [];
	// $: console.log({ sessions }, datesToAnalyse && datesScreenTime);
	$: if (!$IsDev && showDetailsFor && typeof window !== 'undefined')
		window.analytics?.track?.('Details Viewed', {
			platform: $Platform,
			metric: showDetailsFor,
			environment: $IsDev ? 'dev' : 'production',
			email: $UserEmail
		});
	let showCustomContexts;
	let selectedCategory;
	let selectedSubcategory;
	let customContexts;
	$: customContexts = $UserInfo.customContexts ||
		customContexts || [
			{
				main: ['Work', 'Focus Work', 'Writing & Notes'],
				categories: ['Writing & Notes', 'Reference & Learning'],
				editingCategories: false
			},
			{
				main: ['Work', 'Focus Work', 'Marketing'],
				categories: ['Marketing', 'Social Media'],
				editingCategories: false
			},
			{
				main: ['Work', 'Communication', 'Voice Chat'],
				categories: ['Communication', 'Social Media'],
				editingCategories: false
			}
		];
	let newContext;
	$: last7days =
		datesToAnalyse.length > 1
			? datesToAnalyse
			: Array(7)
					.fill()
					.map((_, i) =>
						dayjs()
							.subtract($DateRange.daysInPast + 6 - i, 'day')
							.subtract(4, 'hour')
							.format('YYYY-MM-DD')
					);
	$: sessionLengthGraphData =
		(datesScreenTime?.length > 1
			? datesScreenTime
			: $Data[`["timeseries","time_series/activitywatch","noCache"]`] &&
			  (
					last7days ||
					Array(7)
						.fill()
						.map((_, i) =>
							dayjs()
								.subtract(6 - i, 'day')
								.subtract(4, 'hour')
								.format('YYYY-MM-DD')
						)
			  )?.map((date, i) => $Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[date])
		)?.map(
			(screenTime) =>
				screenTime && Math?.round(screenTime?.productivityMetrics?.sessionLength / 6) / 10
		) || [];
	//
	let recategoriseToShow = {};
</script>

<Modal bind:show={showCustomContexts} options={{ topLevel: true, cssText: `min-height:85vh;` }}>
	{#each [...(customContexts || []), ...(newContext ? [newContext] : [])] as customContext, i}
		<div class="bg-gray-800 rounded-2xl p-4 my-4">
			<div>
				<div class="uppercase text-xs font-semibold text-gray-300">Main category</div>
				<div
					style="color:{customContext?.main?.some((c) => c === 'Work' || c === 'Focus Work')
						? '#00ff33'
						: '#ff5050'}"
				>
					{customContext?.main?.filter((c) => c !== 'Work' && c !== 'Focus Work')[0] ||
						'Add a main category for this context'}
				</div>
			</div>
			<div>
				<div class="uppercase text-xs font-semibold text-gray-300">Categories included</div>
				<div class="flex flex-wrap gap-2 mt-1 text-xs">
					{#each customContext?.categories || [] as category}
						<div
							class="relative rounded-full uppercase font-semibold text-black px-2 py-0.5"
							style="background-color: #00ff33"
						>
							{category}
							<div
								on:click={() => {
									if (confirm('Are you sure?')) {
										customContext.categories = customContext.categories?.filter(
											(c) => c !== category
										);
										customContext.main = screenTimeCategories.find(([category, regex]) =>
											category?.includes(customContext?.categories?.[0])
										)?.[0];
										$UserInfo.customContexts = customContexts;
										postUserInfo();
									}
								}}
								class="absolute cursor-pointer hidden bg-red-600 rounded-full p-0.5 centreInContext top-0 left-full"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-3 w-3"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fill-rule="evenodd"
										d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
						</div>
					{/each}
				</div>
				{#if customContext?.editingCategories}
					<div class="flex text-sm justify-between mt-4">
						<select class="block" bind:value={selectedCategory}>
							{#each Array.from(new Set(screenTimeCategories.map(([category, regex]) => category.filter((c) => c !== 'Work' && c !== 'Focus Work')[0]))) as category}
								<option value={category}>{category}</option>
							{/each}
						</select>
						<button
							on:click={() => {
								if (selectedCategory) {
									customContext.categories.push(selectedCategory);
									customContext.main = screenTimeCategories.find(([category, regex]) =>
										category.includes(customContext.categories?.[0])
									)[0];
									if (newContext && i === customContexts?.length) {
										$UserInfo.customContexts = [...customContexts, { ...newContext }];
										newContext = undefined;
									} else $UserInfo.customContexts = customContexts;
									selectedCategory = '';
									postUserInfo();
								}
							}}>Add to context</button
						>
					</div>
				{/if}
				<div class="flex text-sm justify-between mt-4">
					<button
						on:click={() => {
							if (customContext) customContext.editingCategories = !customContext.editingCategories;
						}}
						class="py-0.5 px-2 capitalize"
					>
						{!customContext?.editingCategories ? 'edit' : 'finished adding'}
					</button>
					{#if !customContext?.editingCategories}
						<button
							on:click={() => {
								if (!customContext?.main?.length) {
									newContext = false;
									$UserInfo.customContexts = $UserInfo.customContexts.filter(
										(c) => c?.main?.length
									);
								}
								if (confirm('Are you sure?')) {
									$UserInfo.customContexts = customContexts.filter(
										(c) => customContext?.main && c?.main !== customContext?.main
									);
									postUserInfo();
								}
								console.log($UserInfo.customContexts);
							}}
							class="py-0.5 px-2"
						>
							Delete
						</button>
					{/if}
				</div>
			</div>
		</div>
	{/each}
	<button
		on:click={() => {
			newContext = { main: [], categories: [], editingCategories: true };
		}}>+ Add new custom context</button
	>
</Modal>

<div class="pb-8">
	{#if showDetailsFor === 'time working'}
		<TimeTracked divName={'TimeWorkingDetails'} {since} show={['activities', 'time', 'bar']} />
	{/if}
	{#if showDetailsFor === 'session length'}
		<div
			class="text-left mb-4 font-medium top-full rounded-lg p-2"
			style="background:linear-gradient(153.72deg, #070822 9.02%, #080F34 49.16%, #0A196A 88.26%);"
		>
			Session length is the average length of your active sessions using your computer. We track
			this because short sessions give you a lot less chance to get into flow and do deep work!
			Longer sessions give you more time to build up momentum.
		</div>
		<MetricGraphs divName={divName + 'Details'} show={[showDetailsFor]} />
		<div class="font-bold text-4xl text-center">
			{makeDecimalIntoTime(productivityMetrics?.sessionLength / 3600, 'durationNoZero')}
		</div>
		<div class="font-semibold text-xl text-center mb-4">Session Length</div>
		<div class="text-sm text-center font-medium">
			Longer sessions make it easier to get into flow.
		</div>
		<div class="text-sm text-center font-medium">
			Most of your time was in sessions longer than <span class="font-bold">
				{makeDecimalIntoTime(productivityMetrics?.sessionLength / 3600, 'durationNoZero')}.
			</span>
		</div>

		<div class="flex w-full relative mt-8 mb-6">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-5 w-5 absolute centreInContext -top-3"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
					clip-rule="evenodd"
				/>
			</svg>
			{#each [...sessions].sort((a, b) => b.duration - a.duration) as activity}
				<div
					class="reinvert h-6 flex justify-center items-center font-semibold text-sm rounded-full"
					style="background:hsla({17 * activity.duration ** 0.2} 100% 38% / 100%);width: {(100 *
						activity.duration) /
						productivityMetrics?.totalSessionTime}%"
				>
					<div>
						{(100 * activity.duration) / productivityMetrics?.totalSessionTime > 15
							? makeDecimalIntoTime(activity.duration / 3600, 'durationNoZeroWithSeconds')
							: ''}
					</div>
				</div>
			{/each}
		</div>

		<div class="text-sm font-semibold text-center">
			Total time: {makeDecimalIntoTime(
				productivityMetrics?.totalSessionTime / 3600,
				'durationNoZero'
			)}
		</div>
		<div class="text-sm font-semibold text-center">
			Number of sessions: {sessions?.length}
		</div>
		<div class="h-28 w-full my-4">
			<BarLineCombo
				divName={'SessionLengthWeekly'}
				minHeight={'112px'}
				valueFormatter={(value) => makeDecimalIntoTime(value / 60, 'durationNoZero')}
				data={{
					axis: last7days.map((date) => dayjs(date).format('ddd DD')),
					line: {
						name: 'Session Length (mins)',
						data: sessionLengthGraphData
					}
				}}
				colors={['#55ff33', '#666666']}
			/>
		</div>
		<div class="font-semibold text-left">All Sessions</div>
		{#each [...sessions].sort((a, b) => dayjs(typeof b.timestamp == 'string' ? b.timestamp.replace('Z', '') : b.timestamp) - dayjs(typeof a.timestamp == 'string' ? a.timestamp.replace('Z', '') : a.timestamp)) as activity}
			<div
				class="reinvert px-4 py-1 my-2 rounded-lg cursor-pointer"
				style="background:hsla({17 * activity.duration ** 0.2} 100% 38% / 100%);"
				on:click={() => {
					summary = true;
					showActivity = activity;
				}}
			>
				<div class="flex gap-2 font-semibold text-sm items-center justify-between">
					<div class="flex gap-2 items-center">
						<Icon
							forceUppercase={false}
							attribute={'work'}
							color={'white'}
							includeText={true}
							textOverride={makeDecimalIntoTime(
								activity.duration / 3600,
								'durationNoZeroWithSeconds'
							)}
						/>
					</div>
					<div class="w-32">
						Traction: {Math.round(
							(activity?.contexts?.reduce((a, b) => a + b?.duration, 0) *
								activity.productivityScore) /
								36
						) / 10 || 0}
					</div>
					<div>
						{timeRangeForActivity(activity)}
					</div>
				</div>
			</div>
		{/each}
	{/if}
	{#if showDetailsFor === 'productivity'}
		<div
			class="text-left font-medium top-full rounded-lg p-2"
			style="background:linear-gradient(153.72deg, #070822 9.02%, #080F34 49.16%, #0A196A 88.26%);"
		>
			Your productivity score is calculated from three things:
			<ol class="text-sm list-decimal ml-5" style="max-width: calc(100% - 1.25rem)">
				<li>
					The percentage time you spend in a productive vs unproductive context (e.g. work vs social
					media)
				</li>
				<li>How often you context-switch</li>
				<li>How long your sessions are</li>
			</ol>
		</div>
		{#if datesScreenTime?.length > 1}
			<div class="h-28 w-full mt-8">
				<BarLineCombo
					divName={'ProductivityWeekly'}
					minHeight={'112px'}
					data={{
						axis: last7days.map((date) => dayjs(date).format('ddd DD')),
						line: {
							name: 'Productivity',
							data: (datesScreenTime?.length > 1
								? datesScreenTime
								: $Data[`["timeseries","time_series/activitywatch","noCache"]`] &&
								  (
										last7days ||
										Array(7)
											.fill()
											.map((_, i) =>
												dayjs()
													.subtract(6 - i, 'day')
													.subtract(4, 'hour')
													.format('YYYY-MM-DD')
											)
								  )?.map(
										(date, i) =>
											$Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[date]
								  )
							)?.map(
								(screenTime) =>
									screenTime &&
									Math?.round(screenTime?.productivityMetrics?.productivityScore * 100)
							)
						}
					}}
					colors={['#aaee33', '#666666']}
				/>
			</div>
			<div
				class="bg-gray-900 bg-opacity-60 border-2 border-gray-800 border-opacity-70 rounded-2xl shadow-2xl p-2 pb-0 my-4 gap-2 items-center relative"
			>
				<div class="font-semibold text-sm text-center">
					{datesScreenTime?.length > 1 ? 'Average' : 'Today'}
				</div>
				<ProductivityMetrics {small} showImpacts={true} {divName} {productivityMetrics} />
			</div>
		{:else}
			<div class=" font-semibold text-sm text-center -mb-6 mt-4">The day so far</div>

			<ComparisonGraph
				details={['productivity', 'productivityScore', 100, '#00ff33']}
				divName={divName + 'Details'}
				{productivityMetrics}
			/>
			<div class="grid-cols-2 grid gap-4">
				<div
					id="timeDetailsproductivity"
					class="bg-gray-900 bg-opacity-60 border-2 border-gray-800 border-opacity-70 rounded-2xl shadow-2xl p-2 pb-0 my-4 gap-2 items-center relative"
				>
					<div class=" font-semibold text-sm text-center">Current session</div>
					<ProductivityMetrics
						{small}
						showImpacts={true}
						{divName}
						productivityMetrics={productivityLastHour}
					/>
				</div>
				<div
					class="bg-gray-900 bg-opacity-60 border-2 border-gray-800 border-opacity-70 rounded-2xl shadow-2xl p-2 pb-0 my-4 gap-2 items-center relative"
				>
					<div class="font-semibold text-sm text-center">
						{datesScreenTime?.length > 1 ? 'Average' : 'Today'}
					</div>
					<ProductivityMetrics {small} showImpacts={true} {divName} {productivityMetrics} />
				</div>
			</div>
		{/if}
		<!-- <div class=" font-semibold text-sm text-center my-4">Impacts</div> -->
	{/if}
	{#if workTypes?.includes(showDetailsFor)}
		<div class="h-40 z-0 my-4">
			<CategoryPie
				divName={'ScreenTimeDetailsCategoryPie'}
				data={aggregateAndSort(
					($TodaysData?.eventsByType || {})?.[showDetailsFor] || [],
					'byCategory'
				)
					?.map(([cat, data]) => ({
						name: cat?.split('|')[0] || 'Uncategorized',
						value: Math?.floor(data?.duration / 36) / 100
					}))
					?.slice(0, 6)}
			/>
		</div>
		<div class="h-28 w-full my-4">
			<BarLineCombo
				divName={'WorkWeekly'}
				minHeight={'112px'}
				valueFormatter={(value) => makeDecimalIntoTime(value, 'durationNoZero')}
				data={{
					axis: last7days.map((date) => dayjs(date).format('ddd')),
					line: {
						name: showDetailsFor + ' (hrs)',
						data: (datesScreenTime?.length > 1
							? datesScreenTime
							: $Data[`["timeseries","time_series/activitywatch","noCache"]`] &&
							  (
									last7days ||
									Array(7)
										.fill()
										.map((_, i) =>
											dayjs()
												.subtract(6 - i, 'day')
												.subtract(4, 'hour')
												.format('YYYY-MM-DD')
										)
							  )?.map(
									(date, i) => $Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[date]
							  )
						)?.map(
							(screenTime) =>
								screenTime &&
								Math.round(
									screenTime?.productivityMetrics?.contexts
										?.filter(
											(a, i) =>
												(showDetailsFor === 'deep' ? true : a.work) &&
												a.focus &&
												(a.duration > 300 || !i)
										)
										?.map((a) => a.duration)
										.reduce((a, b) => a + b, 0) / 36
								) / 100
						)
					}
				}}
				colors={['#55ff33', '#666666']}
			/>
		</div>
	{/if}

	{#if showDetailsFor === 'context switches'}
		<div
			class="text-left mb-4 font-medium top-full rounded-lg p-2"
			style="background:linear-gradient(153.72deg, #070822 9.02%, #080F34 49.16%, #0A196A 88.26%);"
		>
			A context switch is when you switch from one context to another for enough time that you lose
			the context you built up while working. We have this set to 30 seconds, so when you switch
			from e.g. Writing to Coding for more than 30 seconds, we log a context switch.
			<!-- <ol class="text-sm">
			<li>
				The percentage time you spend in a productive vs unproductive context (e.g. work vs social
				media)
			</li>
			<li>How often you context-switch</li>
			<li>How long your sessions are</li>
		</ol> -->
		</div>
		<div class="mb-2 pt-4 rounded-2xl">
			<div
				class="grid items-end mb-8 pt-1 bg-gray-900 relative rounded-full"
				style="grid-template-columns: 1fr 1.2fr 1fr !important;"
			>
				<div
					class="cursor-pointer flex flex-col mx-2 pl-3 pb-2 items-center gap-0 text-xs text-left font-medium"
				>
					<div class="text-base font-bold">
						{(
							(productivityMetrics || $TodaysData?.activitywatch?.productivityMetrics || {})
								?.contextSwitchRate || 0
						).toFixed(1) || 0}
					</div>
					<div>switches/hr</div>
				</div>
				<div />
				<div
					class="absolute centreInContext top-4 bg-gray-900 rounded-full px-8 py-1 flex flex-col mx-2 items-center gap-0 text-xs font-medium"
				>
					<div class="text-center text-4xl font-bold -mb-1">
						{Math?.round(
							(productivityMetrics || $TodaysData?.activitywatch?.productivityMetrics || {})
								?.contextSwitches
						) || 0}
					</div>
					<div>today</div>
				</div>
				<div
					class="absolute centreInContext top-full ml-1.5 bg-black rounded-full px-1 text-left uppercase text-xs"
				>
					<div class="reinvert flex items-center pb-px gap-1 w-fit">
						<Icon size={[1.3, 1.1]} attribute={'context switches'} includeText={true} />
					</div>
					<!-- textOverride={title === 'traction' ? 'total productivity' : undefined} -->
				</div>
				<div class="flex flex-col mx-2 pl-3 pb-2 items-center gap-0 text-xs text-left font-medium">
					<div class="text-xs font-semibold uppercase flex flex-col items-center">
						{#each [switchesSorted?.[0] || ['None']] as [contextSwitch, number]}
							{#each contextSwitch.split(',') as context, i}
								{#if i && number}
									<div class="flex items-center text-sm -my-1">
										<span>{number}</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="h-4 w-4"
											viewBox="0 0 20 20"
											fill="currentColor"
										>
											<path
												d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"
											/>
										</svg>
									</div>
								{/if}
								<div>{context.replace('Web ', '')}</div>
							{/each}
						{/each}
					</div>
					<!-- <div>top switch</div> -->
				</div>
			</div>
		</div>
		<div class="h-28 w-full">
			<BarLineCombo
				divName={'ContextSwitchWeekly'}
				minHeight={'112px'}
				data={{
					axis: last7days.map((date) => dayjs(date).format('ddd DD')),
					line: {
						name: 'Context Switches',
						data: (datesScreenTime?.length > 1
							? datesScreenTime
							: $Data[`["timeseries","time_series/activitywatch","noCache"]`] &&
							  (
									last7days ||
									Array(7)
										.fill()
										.map((_, i) =>
											dayjs()
												.subtract(6 - i, 'day')
												.subtract(4, 'hour')
												.format('YYYY-MM-DD')
										)
							  )?.map(
									(date, i) => $Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[date]
							  )
						)?.map(
							(screenTime) =>
								screenTime &&
								Math?.round(screenTime?.productivityMetrics?.contextSwitchRate * 10) / 10
						)
					}
				}}
				colors={['#aaee33', '#666666']}
			/>
		</div>
		<button class="w-full my-4" on:click={() => (showCustomContexts = true)}>
			Edit Custom Contexts
		</button>
		<div
			class="mb-4 border-gray-900 border-opacity-30 border border-solid rounded-2xl p-4"
			style="background: radial-gradient(at 10% 0%, rgba(0, 255, 51, 0.1), transparent 33%), radial-gradient(at 50% -10%, rgba(204, 221, 85, 0.15), transparent 40%), radial-gradient(at 90% 0%, rgba(221, 0, 51, 0.15), transparent 33%), linear-gradient(0deg, rgba(51, 51, 68, 0.35), transparent)"
		>
			<div class="uppercase mb-4 text-sm text-gray-200 text-center font-semibold">Top switches</div>
			{#if switchesSorted.length}
				{#each switchesSorted.slice(0, 5) as [contextSwitch, number]}
					<div
						class="grid uppercase items-center my-1 pb-1 border-b border-solid border-gray-700 text-xs font-semibold justify-between w-full"
						style="grid-template-columns: 1fr auto 1fr !important;"
					>
						{#each contextSwitch.split(',') as context, i}
							{#if i}
								<div class="flex items-center text-sm">
									<span>{number}</span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										class="h-5 w-5"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"
										/>
									</svg>
								</div>
							{/if}
							<div class={i ? 'text-right' : ''}>{context}</div>
						{/each}
					</div>
				{/each}
			{:else}
				No switches detected! Awesome work!
			{/if}
		</div>

		<div class="uppercase mb-4 text-sm text-gray-200 text-center font-semibold">All contexts</div>
	{/if}
	{#if showDetailsFor === 'context switches' || workTypes?.includes(showDetailsFor)}
		<div class="flex gap-4 w-full px-12">
			<div class="w-full">
				{#each (showDetailsFor === 'context switches' ? productivityMetrics?.contexts || [] : [...($TodaysData?.eventsByType?.[showDetailsFor] || [])])?.sort((a, b) => dayjs(b.endDate) - dayjs(a.endDate)) || [] as context, i}
					<div
						class="grid text-xs text-left mx-auto gap-2 relative"
						style="grid-template-columns: 2.25rem 8.75rem 16rem 4rem 1fr !important;color:{context?.work
							? `${context?.focus ? '#00ff33' : '#ccdd55'}`
							: '#ff5050'}"
						on:mouseover={delay(
							() => {
								if (
									recategoriseToShow.details ===
									JSON.stringify(
										context?.app + context?.title + context?.url + context?.categories?.[0] + i
									)
								)
									context.hovering = true;
								recategoriseToShow.details = JSON.stringify(
									context?.app + context?.title + context?.url + context?.categories?.[0] + i
								);
							},
							recategoriseToShow.details ? 0 : 173
						)}
						on:mouseleave={() => {
							context.hovering = false;
							setTimeout(() => {
								if (
									!context.hovering &&
									recategoriseToShow.details ===
										JSON.stringify(
											context?.app + context?.title + context?.url + context?.categories?.[0] + i
										)
								)
									recategoriseToShow.details = false;
							}, 250);
						}}
						on:focus={delay(
							() => {
								if (
									recategoriseToShow.details ===
									JSON.stringify(
										context?.app + context?.title + context?.url + context?.categories?.[0] + i
									)
								)
									context.hovering = true;
								recategoriseToShow.details = JSON.stringify(
									context?.app + context?.title + context?.url + context?.categories?.[0] + i
								);
							},
							recategoriseToShow.details ? 0 : 173
						)}
						on:blur={() => {
							context.hovering = false;
							setTimeout(() => {
								if (
									!context.hovering &&
									recategoriseToShow.details ===
										JSON.stringify(
											context?.app + context?.title + context?.url + context?.categories?.[0] + i
										)
								)
									recategoriseToShow.details = false;
							}, 250);
						}}
						on:click={() =>
							(activityDetails = {
								type: context?.url
									? 'Site: ' +
									  context?.url
											?.split('/')
											[/(http|file)(s)*:\/\//.test(context.url) ? 2 : 0]?.replace('www.', '')
									: 'App: ' + context?.app,
								data: context,
								work: context?.work
							})}
					>
						<div>{dayjs(context?.timestamp).format('HH:mm')}</div>
						<div>
							{context?.categories?.[0]}:
						</div>
						<div>
							{(browsers?.some(
								(browser) =>
									(context?.data || context).app?.toLowerCase().includes(browser?.toLowerCase()) &&
									((context?.data || context)?.url || (context?.data || context)?.title)
							)
								? context?.url
										?.split('/')
										[/(http|file)(s)*:\/\//.test(context.url) ? 2 : 0]?.replace('www.', '') ||
								  context?.title
								: context?.app
							)?.slice(0, 21) || context?.app}
						</div>
						<div style="width:max-content;">
							{(context &&
								context?.duration &&
								makeDecimalIntoTime(
									context?.duration < 0 ? 0 : context?.duration / 3600,
									'durationNoZeroWithSeconds'
								)) ||
								'1s'}
						</div>
						{#if !$IsNative && recategoriseToShow.details == JSON.stringify(context?.app + context?.title + context?.url + context?.categories?.[0] + i)}
							<QuickRecategorize
								parentShow={'details'}
								{i}
								bind:recategoriseToShow
								{categories}
								{divName}
								{...{
									type: context?.url
										? 'Site: ' +
										  context?.url
												?.split('/')
												[/(http|file)(s)*:\/\//.test(context.url) ? 2 : 0]?.replace('www.', '')
										: 'App: ' + context?.app,
									data: context,
									work: context?.work
								}}
							/>
						{/if}
						<div class="w-full px-4 py-0.5">
							<div
								style="width:{context.duration / 36}%;background:{context?.work
									? `${context?.focus ? '#00ff33' : '#ccdd55'}`
									: '#ff5050'};border-radius: 2px 0.25rem 0.25rem 2px;"
								class="h-full"
							/>
						</div>
					</div>
				{/each}
			</div>
			<!-- {#if showDetailsFor === 'context switches' && productivityMetrics?.contexts?.length > 2}
				<div
					style="height: {17.5 *
						productivityMetrics?.contexts?.filter((c) => c?.duration).length}px;width: 50%;"
				>
					<Sankey
						vertical={true}
						divName={'contextFlow'}
						small={true}
						data={{
							data: productivityMetrics?.contexts
								?.filter((c) => c?.duration)
								.map((c, i) => ({
									name: '' + i + ': ' + c?.categories?.[0],
									value: makeDecimalIntoTime((1 / 3600) * c.duration, 'durationNoZeroWithSeconds'),
									itemStyle: {
										color: c.focus && c.duration > 300 ? '#0066dd' : c.work ? '#dddd33' : '#dd0053',
										borderRadius: 100,
										borderCap: 'round',
										lineCap: 'round',
										borderJoin: 'round',
										borderWidth: 4,
										borderType: 'solid',
										borderColor: c.focus ? '#0066dd' : c.work ? '#dddd33' : '#dd0053',
										borderRadius: 10
									}
								})) || [
								{
									name: 'Total time',
									itemStyle: {
										color: '#023499',
										borderRadius: 100,
										borderCap: 'round',
										lineCap: 'round',
										borderJoin: 'round',
										borderWidth: 4,
										borderType: 'solid',
										borderColor: '#023499',
										borderRadius: 10
									}
								},
								{ name: 'Productive', itemStyle: { color: '#00ff8C', borderColor: '#00ff8C' } },
								{
									name: 'Unproductive',
									itemStyle: {
										color: '#dd0053',
										borderRadius: 100,
										borderCap: 'round',
										lineCap: 'round',
										borderJoin: 'round',
										borderWidth: 4,
										borderType: 'solid',
										borderColor: '#dd0053'
									}
								},
								{
									name: 'Deep',
									itemStyle: {
										color: '#0066dd',
										borderRadius: 100,
										borderCap: 'round',
										lineCap: 'round',
										borderJoin: 'round',
										borderWidth: 4,
										borderType: 'solid',
										borderColor: '#0066dd'
									}
								},
								{
									name: 'Shallow',
									itemStyle: {
										color: '#dddd33',
										borderRadius: 100,
										borderCap: 'round',
										lineCap: 'round',
										borderJoin: 'round',
										borderWidth: 4,
										borderType: 'solid',
										borderColor: '#dddd33'
									}
								}
							],
							links: productivityMetrics?.contexts
								?.filter((c) => c?.duration)
								?.map((c, i, a) => ({
									source: '' + i + ': ' + c?.categories?.[0],
									target: '' + (i + 1) + ': ' + a[i + 1]?.categories?.[0],
									value: c.duration / 3600
								})) || [
								{ source: 'Total time', target: 'Productive', value: total_time?.productive || 2 },

								{ source: 'Productive', target: 'Deep', value: 1 },
								{ source: 'Productive', target: 'Shallow', value: 1 },
								{ source: 'Total time', target: 'Unproductive', value: 1 }
							]
						}}
					/>
				</div>
			{/if} -->
		</div>
	{/if}
</div>

<style>
	.relative:hover .hidden {
		display: block;
	}
</style>
