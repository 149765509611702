<script>
	import dayjs from 'dayjs';
	import { makeDecimalIntoTime } from '$lib/connectUtils.js';
	import { bucketEventsByTimePeriod } from '$lib/dataProcessing.js';
	import { TodaysData, Data, Version } from '$lib/store.js';
	import AreaLineGraph from './chartFunctions/AreaLineGraph.svelte';
	export let divName,
		dayInHours,
		datesScreenTime,
		datesToAnalyse,
		total_time,
		todaysDayjs,
		categories,
		activities,
		events,
		since,
		nowInMinutes,
		dataIndex,
		todayInHours,
		numberOfMinutes;
</script>

<div class="text-center text-xs uppercase font-medium absolute centreInContext top-3">
	{datesScreenTime
		? dayjs(datesToAnalyse[0]).format('ddd DD MMM') +
		  ' - ' +
		  dayjs(datesToAnalyse[datesToAnalyse.length - 1]).format('ddd DD MMM')
		: todaysDayjs?.format('ddd DD MMM')}
</div>
<div class="text-center text-xs font-medium absolute centreInContext top-[96.7%]">
	{$Version.app === 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com'}
</div>
<div class="grid grid-cols-2 grid-rows-2 gap-6 relative text-left">
	<div class=" relative flex items-stretch p-3 pr-0">
		<div
			class="absolute w-full h-full left-0 top-0 rounded-xl bg-gray-400 bg-opacity-20 mix-blend-color-burn"
		/>
		<div class="flex-grow">
			<div class="font-black text-2xl tracking-tight">
				{#each makeDecimalIntoTime((total_time || $TodaysData.total_time)?.deep / 3600) as t, i}
					<span class={i ? 'text-5xl' : 'text-6xl'}> {t}</span><span
						class={i ? 'text-3xl' : 'text-4xl'}>{i ? 'm' : 'h '}</span
					>
				{/each}
			</div>
			<div class="text-xl font-semibold ">
				Deep Work

				<div class="font-black flex gap-1 text-sm">
					<span class="font-semibold h-4">Total:</span>
					<div class="h-0">
						{makeDecimalIntoTime(
							((total_time || $TodaysData.total_time || {}).productive +
								(total_time || $TodaysData.total_time || {}).unproductive) /
								3600,
							'durationNoZero'
						)}
					</div>
				</div>
			</div>
		</div>
		<!-- <div>
            <div class="text-sm font-semibold">Total Time</div>
            <div class="text-sm font-semibold">
                {Math.round(
                    100 *
                        ((total_time || $TodaysData.total_time).deep /
                            ((total_time || $TodaysData.total_time).productive +
                                (total_time || $TodaysData.total_time).unproductive))
                )}% Deep
            </div>
        </div> -->
	</div>
	<div class="text-lg font-black relative p-3">
		<div
			class="absolute w-full h-full left-0 top-0 rounded-xl bg-gray-400 bg-opacity-20 mix-blend-color-burn"
		/>
		{#each categories?.slice(0, 2) || [] as [category, data]}
			<div>
				{category}
				{makeDecimalIntoTime(data.duration / 3600, 'durationNoZero')}
				<div class="text-xs font-semibold">
					{activities
						?.filter(([type, data]) => data.categories?.[0] === category)
						.map(
							(a) => a[0].split(': ')[1].slice(0, 12)
							// +
							// ' ' +
							// makeDecimalIntoTime(a[1].duration / 3600, 'durationNoZero')
						)
						.slice(0, 2)
						.join(', ')}
				</div>
			</div>
		{/each}
	</div>
	<div class="col-span-2 relative overflow-hidden rounded-xl">
		<div
			class="absolute w-full h-full left-0 top-0 rounded-xl bg-gray-400 bg-opacity-20 mix-blend-color-burn"
		/>
		<div
			class="brightness-125 {!datesToAnalyse
				? 'absolute w-full pb-1 pl-2 h-full transform scale-x-105'
				: 'h-5/6'}"
		>
			<AreaLineGraph
				options={{
					timePeriod: since ? 'minute' : datesScreenTime ? 'day' : 'hour',
					type: 'time',
					area: true,
					divName: 'timeSparkLineShare' + divName,
					colors: ['#00ff33', '#dd0033', '#7176ff'],
					showAxis: !datesToAnalyse,
					data: [
						bucketEventsByTimePeriod(
							(since
								? $Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[
										since?.subtract(4, 'hour').format('YYYY-MM-DD')
								  ]?.window?.events
								: events || $TodaysData?.productiveEvents
							)
								?.filter((a) => a.work)
								?.filter(
									(event) =>
										!since ||
										(dayjs(event?.endDate) > since &&
											dayjs(event?.timestamp) < since?.add(numberOfMinutes, 'minute'))
								),
							{
								timePeriod: datesScreenTime ? 'hour' : 'minute',
								quantityOfPeriod: since ? 1 : datesScreenTime ? 2 : 60,
								splitSpanningEvents: true
							},
							since
						) || {},
						bucketEventsByTimePeriod(
							(since
								? $Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[
										since?.subtract(4, 'hour').format('YYYY-MM-DD')
								  ]?.window?.events
								: events || $TodaysData?.unproductiveEvents
							)
								?.filter((a) => !a.work)
								?.filter(
									(event) =>
										!since ||
										(dayjs(event?.endDate) > since &&
											dayjs(event?.timestamp) < since?.add(numberOfMinutes, 'minute'))
								),
							{
								timePeriod: datesScreenTime ? 'hour' : 'minute',
								quantityOfPeriod: since ? 1 : datesScreenTime ? 2 : 60,
								splitSpanningEvents: true
							},
							since
						) || {}
					],
					category: since
						? nowInMinutes
						: datesScreenTime
						? datesToAnalyse
								.map((date) => dayInHours(dayjs().startOf('day').diff(dayjs(date), 'day')))
								.flat()
						: todayInHours,
					name: 'TimeSparkLine',
					minHeight: '100%',
					xAxisInGraph: 30,
					markArea: false,
					flipIndexes: [1],
					lineOnlyIndexes: [2],
					minMax: {
						min: since ? -60 : datesScreenTime ? -5600 : -3600,
						max: since ? 120 : datesScreenTime ? 7200 : 3600
					}
				}}
				{dataIndex}
			/>
		</div>
		{#if datesToAnalyse}
			<div class="flex justify-around">
				{#each datesToAnalyse as date}
					<div>
						<div class="text-xs text-center">{dayjs(date).format('ddd')}</div>
					</div>
				{/each}
			</div>
		{/if}
	</div>
</div>
