<script>
	import dayjs from 'dayjs';
	import { screenTimeCategories } from '$lib/screenTimeCategories.js';
	import { makeDecimalIntoTime, query } from '$lib/connectUtils.js';

	import {
		IsElectron,
		UserEmail,
		UserInfo,
		Data,
		TodaysData,
		DateRange,
		IsDev
	} from '$lib/store.js';
	import { categoryTask } from '$lib/connectUtils.js';
	import { postUserInfo, sleep } from '$lib/utils.js';
	import { onDestroy } from 'svelte';
	import Modal from './Modal.svelte';

	export let type,
		typeToShow,
		data,
		title,
		categories,
		position,
		i,
		xShift = 0;
	let interacted;
	let editingCategories = true;
	// $: console.log({ editingCategories });
	[typeToShow, title] = type?.split(': ') || ['App', data?.app];
	// title = title || data.domain || data.url.split('/')[2] || data.app;
	let originalCategory = data?.categories?.[0];
	let originalSubcategory = data?.categories[1];
	let selectedType = data?.focus ? 'Focus Work' : `${data?.work ? 'Work' : 'Not work'}`;
	let selectedCategory;
	export let parentShow;
	export let recategoriseToShow;
	$: possibleSubcategories =
		Array.from(
			new Set(
				screenTimeCategories
					?.filter(
						([cat, regex]) =>
							cat.filter((c) => c !== 'Focus Work' && c !== 'Work')[0] ===
							(selectedCategory || originalCategory)
					)
					.map(([cat, regex]) => cat.filter((c) => c !== 'Focus Work' && c !== 'Work')[1])
			)
		) || [];
	let selectedSubcategory;
	$: if (!selectedSubcategory || !possibleSubcategories.includes(selectedSubcategory))
		selectedSubcategory = selectedCategory && possibleSubcategories?.[0];
	let reasons = (data?.reason || [])[0];
	let existingMatchReason = (reasons || [[], []])[1]?.map(
		(r) => r && r[1] && r[1]?.[0] && r[1]?.[0]
	)[0];
	let domain = data?.url
		?.split('/')
		[/(http|file)(s)*:\/\//.test(data.url) ? 2 : 0]?.replace('www.', '');
	let newStrings =
		(data?.categories?.includes('Web Browsing') ||
		(domain && !existingMatchReason?.includes(domain))
			? domain
			: !data?.categories?.includes('Untracked') && existingMatchReason) ||
		data?.title ||
		data?.app;

	let existingMatchReasonStrength = existingMatchReason?.length;
	let newMatchReasonStrength, patternMatches, patternMatchIsStronger;
	$: try {
		newMatchReasonStrength =
			(newStrings?.length > (data?.url ? 4 : 0) &&
				Math.max(
					...['title', 'url', 'app', 'domain', 'path', 'bundleId']
						.filter((string) => new RegExp(newStrings, 'i').test(data[string] || ''))
						.map(
							(string) => ((data[string] || '').match(new RegExp(newStrings, 'i'))[0] || '').length
						)
				)) ||
			0;
		patternMatches = !!newMatchReasonStrength;
		patternMatchIsStronger = newMatchReasonStrength > existingMatchReasonStrength;
	} catch {}

	// $: console.log({
	// 	activityDetails,
	// 	newMatchReasonStrength,
	// 	existingMatchReasonStrength,
	// 	newStrings
	// });

	let showFullEvent;
	let showAllCategories;
	let filter = '';
	let focus = (node) => node.focus();
	$: categoriesToShow = showAllCategories
		? Array.from(
				new Set(
					screenTimeCategories.map(
						([cat, regex]) => cat.filter((c) => c !== 'Focus Work' && c !== 'Work')[0]
					)
				)
		  )
				.filter((c) => !filter || c.toLowerCase().startsWith(filter.toLowerCase()))
				.sort()
		: categories?.slice(0, 5).map((c) => c?.[0]) || [];
	let error;
	let submit = () => {
		error = false;
		if (!patternMatches) {
			error = true;
			console.log('no pattern matches');
			return;
		}
		let newCategory = screenTimeCategories.find(
			([cat, regex]) =>
				cat.includes(selectedCategory) &&
				(!selectedSubcategory || cat.includes(selectedSubcategory))
		)?.[0] || [selectedCategory];
		if (!newCategory) {
			error = true;
			console.log('no new category');
			return;
		}
		console.log([[newCategory, newStrings?.trim()]]);
		// $UserInfo.personalCategories = [];
		if (
			data.categories?.includes('Web Browsing') ||
			!data?.categories?.[0] ||
			patternMatchIsStronger
		) {
			categoryTask(
				'updateCategories',
				String.fromCharCode(...$UserEmail?.split('').map((l) => l.charCodeAt(0) + 2)),
				[[newCategory, newStrings?.trim()]]
			);
		}
		$UserInfo.personalCategories = [
			...($UserInfo.personalCategories || []),
			[newCategory, newStrings?.trim()]
		];
		postUserInfo();

		data = {
			...(data || []),
			categories: newCategory?.filter((cat) => cat !== 'Work' && cat !== 'Focus Work') || [
				'No category'
			],
			reason: [[newCategory, [[data.url ? 'url' : 'app', [newStrings, null]]]]],
			work: newCategory.includes('Work'),
			focus: newCategory.includes('Focus Work')
		};
		data.work = newCategory.includes('Work');
		data.focus = newCategory.includes('Focus Work');

		updateLiveData(newCategory, newStrings);
		setTimeout(() => query('screenTime')(0, true, true), 2000);
		$DateRange.daysInPast = $DateRange.daysInPast;

		editingCategories = false;
		setTimeout(() => {
			selectedCategory = undefined;
			editingCategories = true;
			recategoriseToShow[parentShow] = false;
		}, 2000);
	};

	async function updateLiveData(newCategory, newStrings) {
		let regex;
		try {
			regex = new RegExp(newStrings, 'i');
		} catch (e) {
			console.error(e);
			if (newStrings.startsWith('*') || newStrings.startsWith('+')) {
				try {
					regex = new RegExp(newStrings.replace(/(\*|\+)/, '.$1'));
				} catch (e) {
					regex = new RegExp(newStrings.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
					console.error(e);
				}
			} else regex = new RegExp(newStrings.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
		}
		let dates = Object.keys($Data[`["timeseries","time_series/activitywatch","noCache"]`])
			.sort()
			.reverse();
		for (let todaysDate of dates) {
			let events =
				$Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[todaysDate]?.window?.events;
			if (events?.length) {
				// raw events
				console.log('updating activitywatch from live data qr1');

				$Data[`["timeseries","time_series/activitywatch","noCache"]`][todaysDate].window.events =
					events?.map((e) => {
						if (['title', 'url', 'app', 'domain'].some((string) => regex.test(e[string] || '')))
							e = {
								...(e || []),
								categories: newCategory?.filter(
									(cat) => cat !== 'Work' && cat !== 'Focus Work'
								) || ['No category'],
								reason: [[newCategory, [[e.url ? 'url' : 'app', [newStrings, null]]]]],
								work: newCategory.includes('Work'),
								focus: newCategory.includes('Focus Work')
							};
						return e;
					});
				// contexts
				await sleep(500);
				console.log('updating activitywatch from live data qr2');

				$Data[`["timeseries","time_series/activitywatch","noCache"]`][todaysDate].window.sessions =
					$Data[`["timeseries","time_series/activitywatch","noCache"]`][
						todaysDate
					].window.sessions?.map((e) => {
						e.contexts = e.contexts?.map((e) => {
							if (['title', 'url', 'app', 'domain'].some((string) => regex.test(e[string] || '')))
								e = {
									...(e || []),
									categories: newCategory?.filter(
										(cat) => cat !== 'Work' && cat !== 'Focus Work'
									) || ['No category'],
									reason: [[newCategory, [[e.url ? 'url' : 'app', [newStrings, null]]]]],
									work: newCategory.includes('Work'),
									focus: newCategory.includes('Focus Work')
								};
							return e;
						});
						return e;
					});
				if (
					todaysDate ===
					dayjs().subtract(4, 'hour').subtract($DateRange.daysInPast, 'day').format('YYYY-MM-DD')
				) {
					$TodaysData.productiveEvents = $Data[
						`["timeseries","time_series/activitywatch","noCache"]`
					][todaysDate].window.events.filter((e) => e?.work);
					$TodaysData.unproductiveEvents = $Data[
						`["timeseries","time_series/activitywatch","noCache"]`
					][todaysDate].window.events.filter((e) => !e?.work);
				}
			}
			await sleep(1000);
		}
	}
	let categorySwitcher;
	let customposition;
	let positionerNode;
	export let divName;
	const getPosition = (node) => {
		if (!node?.parentNode) {
			setTimeout(getPosition, 10);
			// if (divName === 'Sidebar') {
			// 	console.log(divName, positionerNode, categorySwitcher, positionerNode?.parentNode);
			// }
			return;
		}
		let rect = node?.parentNode.getBoundingClientRect();
		customposition = `left:${rect.x + 90 + (xShift || 0)}px;bottom:${Math.max(
			780 - (rect.y + 20),
			0
		)}px;`;
		// console.log(customposition);
		// if (divName === 'Sidebar')
		// 	console.log(divName, customposition, positionerNode, rect, categorySwitcher);
		// console.log(categorySwitcher);
	};
	const appendChild = (node) => {
		document.body.appendChild(node);
		// if (divName === 'Sidebar')
		// 	console.log(divName, customposition, positionerNode, rect, categorySwitcher);
		// console.log(categorySwitcher);
	};
	$: if (positionerNode) {
		let rect = positionerNode?.parentNode.getBoundingClientRect();
		customposition = `left:${rect.x + 90 + xShift}px;bottom:${Math.max(780 - (rect.y + 20), 0)}px;`;
	}
	let rand = Math.round(Math.random() * 100000);
	onDestroy(() => {
		setTimeout(() => {
			if (document.getElementById(divName + rand + 'categorySwitcher'))
				document.getElementById(divName + rand + 'categorySwitcher')?.remove();
		}, 1200);
		customposition = false;
	});
</script>

<span class="absolute" use:getPosition bind:this={positionerNode} />
<div
	bind:this={categorySwitcher}
	id={divName + rand + 'categorySwitcher'}
	on:mouseover={() => {
		data.hovering = true;
		recategoriseToShow[parentShow] = JSON.stringify(
			data?.app + data?.title + data?.url + data?.categories?.[0] + i
		);
	}}
	on:mouseleave={() => {
		data.hovering = false;
		setTimeout(
			() => {
				if (
					!data.hovering &&
					recategoriseToShow[parentShow] ==
						JSON.stringify(data?.app + data?.title + data?.url + data?.categories?.[0] + i)
				)
					recategoriseToShow[parentShow] = false;
			},
			interacted ? 1000 : 250
		);
	}}
	on:focus={() => {
		data.hovering = true;
		recategoriseToShow[parentShow] = JSON.stringify(
			data?.app + data?.title + data?.url + data?.categories?.[0] + i
		);
	}}
	on:blur={() => {
		data.hovering = false;
		setTimeout(
			() => {
				if (
					!data.hovering &&
					recategoriseToShow[parentShow] ==
						JSON.stringify(data?.app + data?.title + data?.url + data?.categories?.[0] + i)
				)
					recategoriseToShow[parentShow] = false;
			},
			interacted ? 1000 : 250
		);
	}}
	use:appendChild
	class="{customposition ? 'sm:block' : 'hidden'} categorySwitcher gradient-med {position ||
		'right-full -bottom-2'}  w-80 z-[100] text-white font-bold fixed text-sm transform p-3 rounded-xl shadow-lg"
	style="transform:translateY({showAllCategories
		? '100px'
		: '-1px'});background:linear-gradient(153.72deg, #070822 9.02%, #0A196A 85.76%, #091042 100%);{customposition}"
	on:click|stopPropagation
>
	{#if !editingCategories}
		<div>Done!</div>
	{:else if !selectedCategory}
		<div class="flex justify-between text-sm font-medium">
			{type?.slice(0, 25)}
			<div
				class="reinvert capitalize inline font-semibold text-xs w-max rounded-full "
				style="padding:0.2rem 0.3rem;background:{data?.work
					? `${data?.focus ? '#00ff33cc' : '#ccdd55cc'}`
					: '#ff5050aa'}"
			>
				{data?.categories?.[0] || 'Uncategorised'}
			</div>
		</div>
		<div class="text-xs font-medium">
			{data?.title?.slice(0, 42) || 'No title'}
		</div>
		<div class="text-xs font-semibold mt-4">Quick-Recategorise</div>
		<div class="flex flex-wrap justify-items-stretch gap-2 mt-2">
			{#each categoriesToShow as category, i}
				<button
					on:click={() => {
						interacted = true;
						reasons = (data?.reason || [])[0];
						existingMatchReason = (reasons || [[], []])[1]?.map(
							(r) => r && r[1] && r[1]?.[0] && r[1]?.[0]
						)[0];
						domain = data?.url
							?.split('/')
							[/(http|file)(s)*:\/\//.test(data.url) ? 2 : 0]?.replace('www.', '');
						newStrings =
							(data?.categories?.includes('Web Browsing') ||
							(domain && !existingMatchReason?.includes(domain))
								? domain
								: !data?.categories?.includes('Untracked') && existingMatchReason) ||
							data?.title ||
							data?.app;
						selectedCategory = category;
					}}
					class="capitalize font-semibold text-xs rounded-full px-4 py-1 "
					style="background:{filter && !i ? '#2555f9' : '#1545e9aa'};{filter && !i
						? 'box-shadow: 0 0 10px 2px #cde'
						: ''}"
				>
					{category}
				</button>
			{/each}
			<button
				on:click={() => {
					interacted = true;
					showAllCategories = !showAllCategories;
				}}
				class="capitalize font-semibold text-xs rounded-full px-4 py-1 "
			>
				{showAllCategories ? 'Show less' : 'Show all'}
			</button>
		</div>
		{#if showAllCategories}
			<input
				on:keypress={(event) => {
					// If the user presses the "Enter" key on the keyboard
					if (event.key === 'Enter') {
						// Cancel the default action, if needed
						event.preventDefault();
						// Trigger the button element with a click
						if (categoriesToShow?.[0]) selectedCategory = categoriesToShow[0];
					}
				}}
				use:focus
				class="w-full p-2 block mt-4"
				bind:value={filter}
			/>
		{/if}

		<!-- <div>
	<div class="uppercase text-xs font-semibold text-gray-300">New Category</div>
	<div class="flex flex-wrap  mt-1 gap-2 text-xs uppercase">
		{#each Array.from(new Set(screenTimeCategories
					.filter(([cat, regex]) => cat.includes(selectedType) || (selectedType === 'Not work' && !cat.includes('Work')))
					.map(([cat, regex]) => cat.filter((c) => c !== 'Focus Work' && c !== 'Work')[0]))) as category}
			<button
				on:click={() => {
					selectedCategory = category;
					selectedSubcategory = possibleSubcategories?.[0];
				}}
				class="px-2 py-0.5 uppercase {category === (selectedCategory || originalCategory)
					? 'bg-white text-black'
					: ''}">{category}</button
			>
		{/each}
	</div>
</div> -->
	{:else}
		<div class="mt-4">
			<div class="uppercase text-xs font-semibold text-gray-300">New Subcategory</div>
			<div class="flex flex-wrap  mt-1 gap-2 text-xs uppercase">
				{#each possibleSubcategories as category}
					<button
						on:click={() => {
							selectedSubcategory = category;
						}}
						class="px-2 py-0.5 uppercase {category === (selectedSubcategory || originalSubcategory)
							? 'bg-white text-black'
							: ''}">{category || 'General'}</button
					>
				{/each}
			</div>
		</div>
		<input
			class="my-2 p-2 w-full"
			bind:value={newStrings}
			use:focus
			on:keypress={(event) => {
				// If the user presses the "Enter" key on the keyboard
				if (event.key === 'Enter') {
					// Cancel the default action, if needed
					event.preventDefault();
					// Trigger the button element with a click
					submit();
				}
			}}
			placeholder={'e.g. ' + [...(data.url ? [domain] : []), data.title, data.app].join(', or ')}
		/>
		<div class="flex justify-between">
			<button
				class="bg-white text-black w-1/3"
				on:click|stopPropagation={() => {
					selectedCategory = undefined;
					error = undefined;
				}}>{'cancel'}</button
			>
			<button class="bg-white text-black w-1/3" on:click|stopPropagation={submit}>{'Done'}</button>
		</div>
		{#if error}
			<div>Hmm, that pattern doesn't seem to match that event.</div>
		{/if}
	{/if}
</div>
