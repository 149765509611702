<script>
	import Icon from '../Icon.svelte';
	import AreaLineGraph from './chartFunctions/AreaLineGraph.svelte';
	import ScatterGraph from './chartFunctions/ScatterGraph.svelte';
	import BarLineCombo from './chartFunctions/BarLineCombo.svelte';
	import { TodaysData, DateRange } from '$lib/store.js';
	import { makeDecimalIntoTime } from '$lib/connectUtils.js';

	import { fly } from 'svelte/transition';

	import dayjs from 'dayjs';

	export let small,
		showImpacts = false,
		since;
	$: if (small) showImpacts = false;
	export let metricsToShow;
	export let divName;
	export let showDetailsFor;
	export let productivityMetrics;
	export let total_time = $TodaysData?.total_time;
	export let show = [];
	export let slot;

	// $: console.log({ total_time });

	$: productivityMetrics = productivityMetrics || $TodaysData?.activitywatch?.productivityMetrics;
	$: productivityLastHour = $TodaysData?.activitywatch?.productivityLastHour;
	$: factors = [
		0.1 + (productivityMetrics?.contextSwitchFactor - 1) * productivityMetrics?.ratio,
		0.1 + productivityMetrics?.ratio - 1,
		0.1 +
			(productivityMetrics?.sessionLengthFactor - 1) *
				productivityMetrics?.contextSwitchFactor *
				productivityMetrics?.ratio
	];
	$: dayMetricsToShow = [
		[
			'context switches',
			Math.round(productivityMetrics?.contextSwitches),
			[
				{
					title: 'in flow',
					condition: () => productivityMetrics?.contextSwitchRate <= 2,
					readableCondition: '< 2'
				},
				{
					title: 'on it',
					condition: () => productivityMetrics?.contextSwitchRate < 5,
					readableCondition: '3 - 5'
				},
				{
					title: 'OK',
					condition: () => productivityMetrics?.contextSwitchRate < 12.5,
					readableCondition: '5 - 12'
				},
				{
					title: 'twitchy',
					condition: () => productivityMetrics?.contextSwitchRate < 30,
					readableCondition: '13 - 30'
				},
				{
					title: 'scattered',
					condition: () => productivityMetrics?.contextSwitchRate >= 30,
					readableCondition: '30+'
				}
			],
			(productivityMetrics) => productivityMetrics?.contextSwitches
		],
		...(slot ? [['placeholder', 'a', []]] : []),
		[
			'deep work',
			makeDecimalIntoTime(total_time?.deep / 3600),
			[
				{
					title:
						Math.min(
							100,
							Math.round(
								100 *
									(total_time?.deep /
										(since
											? productivityMetrics?.sessionLength
											: (total_time?.unproductive || 0) + (total_time?.productive || 0))) || 0
							)
						) + '%',
					condition: () =>
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: (total_time?.unproductive || 0) + (total_time?.productive || 0)) >
						0.8,
					readableCondition: '80 - 100%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: (total_time?.unproductive || 0) + (total_time?.productive || 0))) || 0
						) + '%',
					condition: () =>
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: (total_time?.unproductive || 0) + (total_time?.productive || 0)) >
						0.6,
					readableCondition: '60 - 80%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: (total_time?.unproductive || 0) + (total_time?.productive || 0))) || 0
						) + '%',
					condition: () =>
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: (total_time?.unproductive || 0) + (total_time?.productive || 0)) >
						0.3,
					readableCondition: '30 - 60%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: (total_time?.unproductive || 0) + (total_time?.productive || 0))) || 0
						) + '%',
					condition: () =>
						(total_time?.deep || (total_time?.unproductive || 0) + (total_time?.productive || 0)) &&
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: (total_time?.unproductive || 0) + (total_time?.productive || 0)) <=
							0.3,
					readableCondition: '1 - 30%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: (total_time?.unproductive || 0) + (total_time?.productive || 0))) || 0
						) + '%',
					condition: () =>
						!(total_time?.deep || (total_time?.unproductive || 0) + (total_time?.productive || 0)),
					readableCondition: '0%'
				}
			],
			(productivityMetrics) =>
				productivityMetrics?.contexts
					?.filter((a, i) => a.focus && (a.duration > 300 || !i))
					.reduce((a, b) => a + b.duration, 0) / 60
		]
	];
	$: metricsToShow = [
		[
			'productivity',
			Math.round(productivityMetrics?.productivityScore * 100),
			[
				{
					title: 'in flow',
					condition: () => productivityMetrics?.productivityScore >= 0.9,
					readableCondition: '90 - 100%'
				},
				{
					title: 'working',
					condition: () => productivityMetrics?.productivityScore >= 0.7,
					readableCondition: '70 - 90%'
				},
				{
					title: 'unfocused',
					condition: () => productivityMetrics?.productivityScore > 0.5,
					readableCondition: '50 - 70%'
				},
				{
					title: 'distracted',
					condition: () => productivityMetrics?.productivityScore <= 0.5,
					readableCondition: '0 - 50%'
				},
				{
					title: 'break',
					condition: () => !productivityMetrics?.productivityScore,
					readableCondition: '0%'
				}
			],
			(productivityMetrics) => productivityMetrics?.productivityScore
		],
		...(slot ? [['placeholder', 'a', []]] : []),
		[
			'deep work',
			makeDecimalIntoTime(total_time?.deep / 3600),
			[
				{
					title:
						Math.min(
							100,
							Math.round(
								100 *
									(total_time?.deep /
										(since
											? productivityMetrics?.sessionLength ||
											  productivityMetrics?.duration ||
											  productivityMetrics?.totalSessionTime ||
											  1
											: (total_time?.unproductive || 0) + (total_time?.productive || 0))) || 1
							)
						) + '%',
					condition: () =>
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: (total_time?.unproductive || 0) + (total_time?.productive || 0)) >
						0.8,
					readableCondition: '80 - 100%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: (total_time?.unproductive || 0) + (total_time?.productive || 0))) || 0
						) + '%',
					condition: () =>
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: (total_time?.unproductive || 0) + (total_time?.productive || 0)) >
						0.6,
					readableCondition: '60 - 80%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: (total_time?.unproductive || 0) + (total_time?.productive || 0))) || 0
						) + '%',
					condition: () =>
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: (total_time?.unproductive || 0) + (total_time?.productive || 0)) >
						0.3,
					readableCondition: '30 - 60%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: (total_time?.unproductive || 0) + (total_time?.productive || 0))) || 0
						) + '%',
					condition: () =>
						(total_time?.deep || (total_time?.unproductive || 0) + (total_time?.productive || 0)) &&
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: (total_time?.unproductive || 0) + (total_time?.productive || 0)) <=
							0.3,
					readableCondition: '1 - 30%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: (total_time?.unproductive || 0) + (total_time?.productive || 0))) || 0
						) + '%',
					condition: () =>
						!(total_time?.deep || (total_time?.unproductive || 0) + (total_time?.productive || 0)),
					readableCondition: '0%'
				}
			],
			(productivityMetrics) =>
				productivityMetrics?.contexts
					?.filter((a, i) => a.focus && (a.duration > 300 || !i))
					.reduce((a, b) => a + b.duration, 0) / 60
		],
		[
			'context switches',
			Math.round(productivityMetrics?.contextSwitches),
			[
				{
					title: 'in flow',
					condition: () => productivityMetrics?.contextSwitchRate <= 2,
					readableCondition: '< 2'
				},
				{
					title: 'on it',
					condition: () => productivityMetrics?.contextSwitchRate < 5,
					readableCondition: '3 - 5'
				},
				{
					title: 'OK',
					condition: () => productivityMetrics?.contextSwitchRate < 12.5,
					readableCondition: '5 - 12'
				},
				{
					title: 'twitchy',
					condition: () => productivityMetrics?.contextSwitchRate < 30,
					readableCondition: '13 - 30'
				},
				{
					title: 'scattered',
					condition: () => productivityMetrics?.contextSwitchRate >= 30,
					readableCondition: '30+'
				}
			],
			(productivityMetrics) => productivityMetrics?.contextSwitches
		],
		[
			'session length',
			makeDecimalIntoTime(productivityMetrics?.sessionLength / 3600, 'durationNoZero'),
			[
				{
					title: 'in flow',
					condition: () => productivityMetrics?.sessionLength > 2700,
					readableCondition: '> 45m'
				},
				{
					title: 'on it',
					condition: () => productivityMetrics?.sessionLength > 1800,
					readableCondition: '30 - 45m'
				},
				{
					title: 'OK',
					condition: () => productivityMetrics?.sessionLength > 900,
					readableCondition: '15 - 30m'
				},
				{
					title: 'twitchy',
					condition: () => productivityMetrics?.sessionLength > 300,
					readableCondition: '5 - 15m'
				},
				{
					title: 'scattered',
					condition: () => productivityMetrics?.sessionLength <= 300,
					readableCondition: '< 5m'
				}
			],
			(productivityMetrics) => productivityMetrics?.sessionLength / 60
		]
	];
	let offset = 2;

	$: average = $TodaysData?.average;
	// $: console.log({ average });

	// We'll make a tooltip for each of the metrics to show, so that on:hover users get an accurate explanation of what the metric is
	let tooltips = {
		productivity:
			'Your productivity score is calculated from three things: the % of time you spent working, how often you are context-switching, and how long your sessions are.',
		'deep work':
			'Your activity counts as deep work when you spend more than 5 minutes in a "focused" context.',
		'context switches':
			'A context switch is defined as when you switch between major categories of work, e.g. between Coding and Communication.\n\nSome activities are considered "Utilities", like Google, or Finder, which means they never count as a context switch. So feel free to google your coding error and visit Stack Overflow, your "Coding" context will stay intact! To avoid overcounting, we ignore switches less than 15 seconds (e.g. when you switch windows).',
		'session length':
			'The length of your active sessions at your computer. Longer sessions give you more time to get into flow!'
	};
	let tooltip;
	$: console.log({ tooltip });
</script>

<div
	id="topLevel"
	class="grid gap-x-1 gap-y-3 text-center items-center relative justify-items-center"
>
	{#each (small ? dayMetricsToShow : metricsToShow).filter(([title]) => !show?.length || show.includes(title)) as [title, metric, judgement, metricGetter], i}
		<div
			id="topLevel{title.replace(' ', '')}"
			on:click|stopPropagation={() => {
				if (!divName.startsWith('Activity')) showDetailsFor = title.replace('deep work', 'deep');
			}}
			on:mouseover={() => (tooltip = title)}
			on:mouseleave={() => (tooltip = null)}
			class="{!divName.startsWith('Activity')
				? 'cursor-pointer hover:brightness-125 hover:z-20 h-min'
				: ''} w-full flex gap-2 items-center relative"
			style="border-radius: 1.3rem;box-shadow: inset 0px 0px 100px 1px rgba(0, 0, 0, 0.1);background: linear-gradient(153.72deg, rgba(7, 7, 10, 0.1) 34.22%, rgba(7, 8, 18, 0.1) 39.02%, rgba(8, 15, 52, 0.1) 49.16%, rgba(10, 25, 106, 0.1) 58.26%, rgba(21, 53, 201, 0.1) 73%, rgba(49, 111, 226, 0.1) 92.24%, rgba(56, 127, 225, 0.1) 95.76%);"
		>
			{#if tooltip === title}
				<div
					in:fly={{ duration: 500, delay: 500, y: -10, opacity: 0 }}
					out:fly={{ duration: 200, delay: 0, y: -10, opacity: 0 }}
					class=" z-50 pointer-events-none absolute w-full left-0 font-medium top-full rounded-lg"
					style="background:linear-gradient(153.72deg, #070822 9.02%, #080F34 49.16%, #0A196A 88.26%)
				;"
				>
					<div class="px-2 py-1 text-blue-200 text-xs text-left" style="white-space: break-spaces;">
						{tooltips[title]}
					</div>
				</div>
			{/if}
			<div
				id="topLevel{title.replace(' ', '')}Metric"
				class="{!divName.startsWith('Activity')
					? 'cursor-pointer hover:bg-[#151828] h-min hover:bg-opacity-50'
					: ''} transition-opacity overflow-hidden z-10 w-32 shadow-2xl p-0 pt-2 pb-2.5 flex gap-2 items-center pointer-events-none"
				style="border-radius: 1.3rem;box-shadow: 0px 0px 3px 1px #2C65FA{i +
					2}3 inset;background-color:rgb(18,20,{35 + i * 7};"
			>
				<div class="flex flex-col items-start w-full flex-grow">
					<div class="flex gap-1 w-full items-center justify-center">
						<div class="border border-gray-800 border-opacity-60 p-1 rounded-lg">
							<Icon attribute={title} />
						</div>
						<div class="text-xl font-bold w-max text-left">
							{#if title === 'deep work'}
								{#each metric as time, i}
									{#if time || i}
										{time || 0}<span class="text-base">{i ? 'm' : 'h '}</span>
									{/if}
								{/each}
							{:else}
								{metric || 0}
							{/if}
							<div
								class="absolute z-10 top-2 right-1.5 px-2 font-semibold uppercase text-xs w-20 text-center rounded"
								style="background:hsla({120 -
									(judgement?.findIndex((j) => j.condition()) * 25 || 20)} 100% 37.5% / 80%);"
							>
								<div class="z-10">
									{judgement?.find((j) => j.condition())?.title || '-'}
								</div>
							</div>
						</div>
					</div>
					<div class="text-xs w-full capitalize text-gray-400">{title}</div>
				</div>
				<div
					class="absolute w-max hidden -bottom-16 font-semibold
					 z-20 bg-gray-900 border-2 border-gray-800 p-2 rounded-xl 
				{(!slot && !(i % 2)) || (slot && !(i % 3)) ? 'left-full' : 'right-full'} "
				>
					{#each judgement as j, i}
						<div class="flex gap-2 capitalize text-left my-1 justify-between">
							<div
								class="h-4 w-4 rounded"
								style="background:hsla({120 - (i * 25 || 20)} 100% 37.5% / 100%);"
							/>
							{#if title !== 'deep work'}
								<div class="text-xs w-max text-left mr-auto text-gray-100">{j.title}</div>
							{/if}
							<div class="text-xs text-gray-100">{j.readableCondition}</div>
						</div>
					{/each}
				</div>
			</div>
			<div class="h-full flex-grow" />
			<div
				class="h-full absolute w-full z-0 bottom-0 left-0 overflow-hidden"
				style="border-radius:1rem 1rem 35rem 100rem;"
			>
				{#if title === 'productivity'}
					<AreaLineGraph
						options={{
							names: ['Average', 'Today'],
							divName: 'metricGraph' + title,
							xAxisInGraph: true,
							showAxis: false,
							tooltip: true,
							hideLegend: true,
							data: [
								Object.fromEntries(
									$TodaysData?.accumulated?.productivityScore?.average?.map(([date, val]) => [
										date,
										Math.round(val * 100)
									]) || []
								),
								Object.fromEntries(
									$TodaysData?.activitywatch?.window?.sessions
										?.filter((i) => i?.duration > 180)
										.map((productivityMetrics, i) => {
											// console.log(title, productivityMetrics);
											return [
												[
													dayjs(productivityMetrics?.timestamp).format(),
													dayjs() > dayjs(productivityMetrics?.timestamp)
														? Math.round(metricGetter(productivityMetrics) * 100)
														: false
												],
												[
													dayjs(productivityMetrics?.endDate).format(),
													dayjs() > dayjs(productivityMetrics?.timestamp)
														? Math.round(metricGetter(productivityMetrics) * 100)
														: false
												],
												[dayjs(productivityMetrics?.endDate).add(1, 'minute').format(), false]
											];
										})
										.flat()
										.sort((a, b) => dayjs(a[0]) - dayjs(b[0])) || []
								) || {}
							],
							category: [
								...Array(25)
									.fill()
									.map((a, i) =>
										!$DateRange.daysInPast && (24 + dayjs().hour() - 4) % 24 === i - 1
											? dayjs()
											: dayjs()
													.subtract(4, 'hour')
													.subtract($DateRange.daysInPast, 'day')
													.startOf('day')
													.hour(4)
													.startOf('hour')
													.add(i, 'hour')
									),
								...($DateRange.daysInPast
									? []
									: $TodaysData?.activitywatch?.window?.sessions
											?.filter((i) => i?.duration > 180)
											?.map((productivityMetrics, i) => {
												return dayjs(
													i ? productivityMetrics?.endDate : productivityMetrics?.timestamp
												);
											}) || [])
							].sort((a, b) => a - b),
							minHeight: '100%',
							colors: [
								'#22ff6622',
								'#22ff66',
								'#00D5ff',
								'#1455ff',
								'#7176ff',
								'#1188ff',
								'#00cc4C'
							]
						}}
					/>
				{:else if title === 'session length'}
					<ScatterGraph
						options={{
							divName: 'metricGraph' + title,
							minHeight: '100%',
							min: dayjs()
								.subtract(4, 'hour')
								.subtract($DateRange.daysInPast, 'day')
								.startOf('day')
								.hour(4)
								.startOf('hour')
								.format(),
							max: dayjs()
								.subtract(4, 'hour')
								.subtract($DateRange.daysInPast, 'day')
								.endOf('day')
								.add(4, 'hour')
								.hour(4)
								.startOf('hour')
								.format(),
							data:
								$TodaysData?.activitywatch?.window?.sessions?.map((s) => [
									s.timestamp,
									s.productivityScore,
									s.duration
								]) || []
						}}
					/>
					<!-- {#each $TodaysData?.activitywatch?.window?.sessions || [] as session}
						<div
							class="rounded-full absolute -translate-y-1/2"
							style="top:{100 - session.productivityScore * 100}%;width:{session.duration /
								(36 * 3)}px;height:{session.duration / (36 * 3)}px;left:{dayjs(
								session.timestamp
							).diff(dayjs().startOf('day').hour(4).startOf('hour'), 'second') /
								(36 * 24)}%;background: rgba(53, 158, 255, 0.9);
box-shadow: inset 0px 0px 10px 2px rgba(20, 85, 255, 0.9);"
						/>
					{/each} -->
				{:else}
					<BarLineCombo
						options={{ hideLegend: true }}
						type={'time'}
						valueFormatter={(value) => {
							return (Math.round(value) || 0) + (title === 'deep work' ? ' min' : '');
						}}
						divName={'metricGraph' + title}
						showXAxis={false}
						colors={title === 'context switches'
							? ['#ffe599', '#ffcc00']
							: title === 'productivity'
							? ['#22ee66', '#33ff88']
							: ['#2266ee', '#3388ff']}
						grid={{
							left: 0,
							top: 10,
							right: 0,
							bottom: 10
						}}
						minHeight={'100%'}
						data={{
							...(title === 'productivity'
								? {
										line2: {
											name: 'Today',
											symbol: false,
											data:
												$TodaysData?.activitywatch?.window?.sessions
													?.map((productivityMetrics, i) => {
														// console.log(title, productivityMetrics);
														return [
															[
																dayjs(productivityMetrics?.timestamp).format(),
																dayjs() > dayjs(productivityMetrics?.timestamp)
																	? Math.round(metricGetter(productivityMetrics) * 100)
																	: false
															],
															[
																dayjs(productivityMetrics?.endDate).add(1, 'minute').format(),
																undefined
															]
														];
													})
													.flat()
													.sort((a, b) => dayjs(a[0]) - dayjs(b[0])) ||
												[] ||
												$TodaysData?.activitywatch?.productivityByHour?.map(
													(productivityMetrics) =>
														metricGetter(productivityMetrics) ||
														(dayjs() < dayjs(productivityMetrics?.start) ? undefined : false)
												) ||
												[]
										}
								  }
								: {
										bar: {
											name: 'Today',
											data:
												$TodaysData?.activitywatch?.productivityByHour?.map(
													(productivityMetrics) =>
														metricGetter(productivityMetrics) ||
														(dayjs() > dayjs(productivityMetrics?.start) ? undefined : false)
												) || []
										}
								  }),
							line: {
								name: 'Average',
								opacity: 0.05,
								data: average?.map(
									(productivityMetrics) =>
										metricGetter(productivityMetrics) / productivityMetrics.length || false
								)
							},
							axis: Array(25)
								.fill()
								.map((a, i) =>
									!$DateRange.daysInPast && (24 + dayjs().hour() - 4) % 24 === i - 1
										? dayjs()
										: dayjs()
												.subtract(4, 'hour')
												.subtract($DateRange.daysInPast, 'day')
												.startOf('day')
												.hour(4)
												.startOf('hour')
												.add(i, 'hour')
								)
						}}
					/>
				{/if}
			</div>
		</div>
	{/each}
	{#if showImpacts}
		<div
			class="relative text-sm w-3/4 mx-auto mt-2 col-span-3 rounded-2xl bg-gray-800 bg-opacity-60 border-2 border-gray-700 border-opacity-80 shadow-2xl p-3"
		>
			<div
				class="absolute w-3/4 text-xs text-center centreInContext top-0 bg-gray-800 rounded-full p-0.5 px-2 uppercase  border-2 border-gray-700 border-opacity-80"
			>
				Productivity Impacts
			</div>
			{#each [...(!small ? [['context switch rate', 'contextSwitchRate', 1, '/ hour']] : []), ['% time working', 'ratio', 100, '/ 100'], ...(!small ? [['session length', 'sessionLength', 1 / 60, 'minutes']] : [])] as [title, metric, multiplier, unit], i}
				<div class="flex justify-between items-center">
					<div class="capitalize text-gray-300 font-medium">
						{title === 'productivity' ? '% work' : title}
					</div>
					<div style="color:{factors[i] >= 0 ? '#00ff66' : '#ee3344'}">
						{(factors[i] > 0 ? '+' : '') + Math.round((factors[i] || 0) * 1000) / 10}
					</div>
				</div>
			{/each}
		</div>
	{/if}
</div>

<style>
	/* div:hover > .opacity-0 {
		opacity: 1;
	} */
</style>
