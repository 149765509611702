<script>
	import Echart from './Echart.svelte';
	import { makeDecimalIntoTime } from '$lib/connectUtils.js';
	export let divName = '1';
	export let total_time = {
		total: 6780,
		productive: 6100,
		unproductive: 680,
		deep: 5100,
		shallow: 1000
	};
	export let vertical;
	export let small;
	export let data = {
		data: [
			{
				name: 'Total time',
				itemStyle: {
					color: '#a0e0ff'
				},
				value: makeDecimalIntoTime((1 / 3600) * 6780, 'durationNoZero')
			},
			{
				name: 'Productive',
				itemStyle: { color: '#00ff8C' },
				value: makeDecimalIntoTime((1 / 3600) * 6100, 'durationNoZero')
			},
			{
				name: 'Unproductive',
				itemStyle: { color: '#dd0053' },
				value: makeDecimalIntoTime((1 / 3600) * 680, 'durationNoZero')
			},
			{
				name: 'Deep',
				itemStyle: { color: '#0066dd' },
				value: makeDecimalIntoTime((1 / 3600) * 5100, 'durationNoZero')
			},
			{
				name: 'Shallow',
				itemStyle: { color: '#dddd33' },
				value: makeDecimalIntoTime((1 / 3600) * 1000, 'durationNoZero')
			},

			{
				itemStyle: { color: `#00${88 + 0 * 6}ee` },
				name: 'Coding' + ' (Deep)',
				duration: 3900,
				value: makeDecimalIntoTime(Math?.floor(3900 / 36) / 100, 'durationNoZero')
			},
			{
				itemStyle: { color: `#00${88 + 1 * 6}ee` },
				name: 'Design' + ' (Deep)',
				duration: 1200,
				value: makeDecimalIntoTime(Math?.floor(1200 / 36) / 100, 'durationNoZero')
			},
			{
				itemStyle: { color: `#dddd${33 + 0 * 6}` },
				name: 'Communication' + ' (Shallow)',
				duration: 1000,
				value: makeDecimalIntoTime(Math?.floor(1000 / 36) / 100, 'durationNoZero')
			},

			{
				itemStyle: { color: `#dd00${53 + 0 * 6}` },
				name: 'Entertainment',
				value: makeDecimalIntoTime(Math?.floor(680 / 36) / 100, 'durationNoZero')
			}
		],
		links: [
			{
				source: 'Total time',
				target: 'Productive',
				value: (1 / 3600) * Math.max(total_time?.productive || 0, total_time?.deep || 0)
			},
			{
				source: 'Productive',
				target: 'Deep',
				value:
					(1 / 3600) *
					Math.max(
						Math.max(total_time?.deep || 0, total_time?.deep || 0) || 0,
						total_time?.deep || 0
					)
			},
			{
				source: 'Productive',
				target: 'Shallow',
				value:
					(1 / 3600) *
						Math.max(
							Math.max(total_time?.shallow || 0, total_time?.shallow || 0) || 0,
							total_time?.shallow || 0
						) || 0
			},
			{
				source: 'Total time',
				target: 'Unproductive',
				value:
					(1 / 3600) *
						Math.max(
							Math.max(total_time?.unproductive || 0, total_time?.unproductive || 0) || 0,
							total_time?.unproductive || 0
						) || 0
			},
			...[
				{
					source: 'Deep',
					target: 'Coding' + ' (Deep)',
					value: (1 / 3600) * 3900
				},
				{
					source: 'Deep',
					target: 'Design' + ' (Deep)',
					value: (1 / 3600) * 1200
				},
				{
					source: 'Shallow',
					target: 'Communication' + ' (Shallow)',

					value: (1 / 3600) * 1000
				},
				{
					source: 'Unproductive',
					target: 'Entertainment',

					value: (1 / 3600) * 680
				}
			].sort((a, b) => b.value - a.value)
		]
	};
	$: data.data = data.data.map((d, i) => {
		d.itemStyle = {
			color: d?.itemStyle?.color,
			borderRadius: 100,
			borderCap: 'round',
			lineCap: 'round',
			borderJoin: 'round',
			borderWidth: small ? 1 : 4,
			borderType: 'solid',
			borderColor: d?.itemStyle?.color,
			borderRadius: 10
		};
		return d;
	});
	$: option = {
		tooltip: {
			trigger: 'item',
			triggerOn: 'mousemove',
			backgroundColor: '#112244',
			borderColor: '#223366',
			textStyle: {
				color: '#efefff'
			},
			valueFormatter: (d) => Math.floor(d) + 'h' + ' ' + Math.floor((d % 1) * 60) + 'm'
		},
		color: [
			'#00D5ff',
			'#cc00f3',
			'#1455ff',
			'#7176ff',
			'#1188ff',
			'#ff44f3',
			'#00cc4C',
			'#ff0073',
			'#1188ff',
			'#ff44f3',
			'#00cc4C',
			'#ff0073'
		],
		animation: true,
		animate: true,
		series: [
			{
				// animation: true,
				// animate: true,
				// animationThreshold: false,
				type: 'sankey',
				bottom: '10%',
				...{
					top: vertical ? (small ? '0.5%' : '4%') : '10%'
				},
				left: '5%',
				right: '10%',
				emphasis: {
					focus: 'adjacency'
				},
				draggable: false,
				nodeGap: small ? 5 : 50,
				nodeWidth: small ? 5 : 20,
				data: data.data,
				links: data.links,
				nodeAlign: 'left',
				orient: vertical ? 'vertical' : 'horizontal',
				label:
					(!small && {
						position: vertical ? 'inside' : 'top',
						// distance: vertical ? 100 : undefined,
						formatter: vertical
							? ['{x|{c}}', '{y|{b}}'].reverse().join(' ')
							: ['{x|{c}}', '{y|{b}}'].join('\n'),
						align: 'center', //vertical ? 'left' :
						rich: {
							x: {
								fontSize: 16,
								color: 'inherit',
								padding: 6,
								fontWeight: 700,
								lineHeight: 18,
								fontFamily: 'Exo 2'
							},
							y: vertical
								? {
										width: 220,
										align: 'left',
										fontSize: 14,
										color: 'inherit',
										padding: 6,
										fontWeight: 600,
										lineHeight: 18,
										fontFamily: 'Exo 2'
								  }
								: {
										fontSize: 12,
										lineHeight: 8,
										color: 'inherit',
										fontWeight: 500,
										fontFamily: 'Exo 2'
								  }
						}
					}) ||
					false,
				lineStyle: {
					color: 'gradient',
					curveness: 0.5,
					opacity: 0.9
				}
			}
		]
	};
	// let horizontal = {
	// 	tooltip: {
	// 		trigger: 'item',
	// 		triggerOn: 'mousemove'
	// 	},
	// 	series: [
	// 		{
	// 			type: 'sankey',
	// 			data: data.nodes,
	// 			links: data.links,
	// 			emphasis: {
	// 				focus: 'adjacency'
	// 			},
	// 			lineStyle: {
	// 				color: 'gradient',
	// 				curveness: 0.5
	// 			},
	// 			label: {
	// 				color: 'white'
	// 			}
	// 		}
	// 	]
	// };
</script>

<Echart renderer={'svg'} {option} divName={'sankey' + divName} minHeight="200px" />
