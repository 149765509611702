<script>
	import { makeDecimalIntoTime } from '$lib/connectUtils.js';
	import { TodaysData } from '$lib/store.js';
	import Icon from '../Icon.svelte';
	export let workTypes;
	export let showDetailsFor;
	export let total_time;
	// $: console.log({ total_time });
</script>

<div
	class="my-2 border-gray-900 border-opacity-30 border border-solid rounded-lg py-4"
	style="background: radial-gradient(at 10% 0%, rgba(0, 255, 51, 0.1), transparent 33%), radial-gradient(at 50% -10%, rgba(204, 221, 85, 0.15), transparent 40%), radial-gradient(at 90% 0%, rgba(221, 0, 51, 0.15), transparent 33%), linear-gradient(0deg, rgba(51, 51, 68, 0.35), transparent)"
>
	<div class="p-2 text-center text-sm">
		You spent
		<span class="font-semibold">
			{makeDecimalIntoTime(
				total_time
					? (total_time['productive'] + total_time['unproductive']) / 3600
					: ($TodaysData?.total_time?.['productive'] + $TodaysData?.total_time?.['unproductive']) /
							3600 || 0,
				'durationNoZeroWithSeconds'
			) || '0m 0s'}
		</span>
		on your computer.
	</div>

	<div class="flex justify-around w-full p-0.5 items-center">
		{#each workTypes as category}
			<button
				on:click={() => {
					showDetailsFor = category;
				}}
				class="p-0 shadow-none bg-transparent text-white w-28 flex flex-col items-center"
			>
				<div class="text-xl font-bold">
					{makeDecimalIntoTime(
						total_time
							? total_time[category] / 3600
							: ($TodaysData?.total_time && $TodaysData?.total_time[category] / 3600) || 0,
						'durationNoZeroWithSeconds'
					) || '0m 0s'}
				</div>
				<div class="flex items-center " style="white-space: nowrap;">
					<Icon
						attribute={category}
						includeText={true}
						textOverride={category + ' work'}
						size={[1.2, 0]}
					/>
				</div>
			</button>
		{/each}
	</div>
</div>
