<script>
	import { TodaysData } from '$lib/store.js';
	export let small,
		productivityMetrics,
		divName,
		showImpacts = true;
	$: if (small) showImpacts = false;

	$: productivityMetrics = productivityMetrics || $TodaysData?.activitywatch?.productivityMetrics;
	$: factors = [
		0.1 + (productivityMetrics?.contextSwitchFactor - 1) * productivityMetrics?.ratio,
		0.1 + productivityMetrics?.ratio - 1,
		0.1 +
			(productivityMetrics?.sessionLengthFactor - 1) *
				productivityMetrics?.contextSwitchFactor *
				productivityMetrics?.ratio
	];
</script>

<div
	class="grid w-full {!small
		? 'gap-3 text-sm p-4 py-6'
		: 'gap-1 text-xs px-2 -mb-1.5'} items-end text-center font-semibold  "
	style={!small ? 'grid-template-columns: 1fr 1fr 1fr !important;' : ''}
>
	{#each [...(!small ? [['context switches', 'contextSwitchRate', 1, '/ hour']] : []), ['productivity', 'productivityScore', 100, '/ 100'], ...(!small ? [['session length', 'sessionLength', 1 / 60, 'minutes']] : [])] as [title, metric, multiplier, unit], i}
		<div
			class="flex flex-col {!small
				? 'gap-3'
				: ''} justify-between items-stretch text-center w-full "
		>
			<div
				style="{title !== 'productivity'
					? 'font-size: 1rem;'
					: `font-size:${small ? 1 : 2.5}rem;${small ? 'line-height:1.5rem;' : ''}`}color:{title ===
				'productivity'
					? `${
							(productivityMetrics || {})[metric] > 0.66
								? '#00ff33'
								: `${(productivityMetrics || {})[metric] > 0.33 ? '#ccdd55' : '#ff5050'}`
					  }`
					: ''}"
				class="relative {'text-lg'} -mb-1"
			>
				{(productivityMetrics || {})[metric]
					? Math.round((productivityMetrics || {})[metric] * multiplier)
					: '0'}
				{#if title !== 'productivity'}
					<div
						class="text-xs {divName === 'Activity' ? '' : 'text-gray-400'} absolute {small
							? 'right-0 bottom-0 w-fit'
							: '-bottom-2 w-full'} text-center "
					>
						{unit || ''}
					</div>
				{/if}
			</div>
			<div
				class="uppercase text-gray-100  text-xs {i === 1 ? 'tracking-tight' : 'tracking-tighter'}"
			>
				{title}
			</div>
		</div>
	{/each}
	{#if showImpacts}
		<div
			class="relative w-3/4 mx-auto mt-8 col-span-3 rounded-2xl bg-gray-800 bg-opacity-60 border-2 border-gray-700 border-opacity-80 shadow-2xl p-3"
		>
			<div
				class="absolute w-3/4 text-xs text-center centreInContext top-0 bg-gray-800 rounded-full p-0.5 px-2 uppercase  border-2 border-gray-700 border-opacity-80"
			>
				Productivity Impacts
			</div>
			{#each [...(!small ? [['context switch rate', 'contextSwitchRate', 1, '/ hour']] : []), ['% time working', 'productivityScore', 100, '/ 100'], ...(!small ? [['session length', 'sessionLength', 1 / 60, 'minutes']] : [])] as [title, metric, multiplier, unit], i}
				<div class="flex justify-between items-center">
					<div class="capitalize text-gray-300 font-medium">
						{title === 'productivity' ? '% work' : title}
					</div>
					<div style="color:{factors[i] >= 0 ? '#00ff66' : '#ee3344'}">
						{(factors[i] > 0 ? '+' : '') + Math.round((factors[i] || 0) * 1000) / 10}
					</div>
				</div>
			{/each}
		</div>
	{/if}
</div>
