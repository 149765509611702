<script>
	import dayjs from 'dayjs';
	import Echart from './Echart.svelte';

	export let dataIndex = 0;
	export let powerPeaks = false;
	export let powerDips = false;

	export let options = {};
	let todayInHours = Array(25)
		.fill()
		.map((a, i) =>
			(24 + dayjs().hour() - 4) % 24 === i - 1
				? dayjs()
				: dayjs().subtract(4, 'hour').startOf('day').hour(4).startOf('hour').add(i, 'hour')
		);
	function hslToHex(h, s, l) {
		l /= 100;
		const a = (s * Math.min(l, 1 - l)) / 100;
		const f = (n) => {
			const k = (n + h / 30) % 12;
			const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
			return Math.round(255 * color)
				.toString(16)
				.padStart(2, '0'); // convert to Hex and prefix "0" if needed
		};
		return `#${f(0)}${f(8)}${f(4)}`;
	}
	let defaultOptions = {
		name: 'AreaLine',
		timePeriod: 'hour',
		type: 'time',
		area: false,
		dataForEach: 'point',
		fullScreen: false,
		divName: 'AreaLineMain',
		colors: Array(36)
			.fill(0)
			.map((_, i) => hslToHex(160 + i * 33, 100, 60)) || [
			'#5ff453',
			'#ff3253',
			'#00D5ff',
			'#1455ff',
			'#7176ff',
			'#1188ff',
			'#00cc4C'
		],
		data: [
			Array(24)
				.fill()
				.map((a, i) => i ** 2),
			Array(24)
				.fill()
				.map((a, i) => (i * 2) % 24)
		],
		// [
		// 	Object.fromEntries(

		// 	)
		// ]
		category: todayInHours,
		xAxisInGraph: false,
		showAxis: true,
		flipAxes: false,
		markArea: false,
		highlightAll: false,
		flipIndexes: [],
		lineOnlyIndexes: [],
		minMax: false,
		cutSeriesTo: false
	};
	// if (options.divName?.toLowerCase().includes('timesparkline')) {
	// 	console.log(options.data, options.category, options.divName, options.dataForEach);
	// }
	options = { ...(defaultOptions || []), ...(options || []) };
	$: options = { ...(defaultOptions || []), ...(options || []) };
	let longDay19Hours = 60 * 19; // 19 hours in minutes
	$: if (options.cutSeriesTo)
		options.category = options.category?.slice(
			0,
			dataIndex < 1100 ? longDay19Hours : options.data?.[0]?.length
		);

	let action = {
		type: 'highlight',
		seriesIndex: options.highlightAll ? Object.keys(options.data) : 0,
		dataIndex
	};

	// $: console.log({ d: options.data });
	$: action = {
		type: 'highlight',

		// Find  by index or id or name.
		// Can be an array to find multiple components.
		seriesIndex: options.highlightAll ? Object.keys(options.data) : 0,
		dataIndex
	};
	// if (options.timePeriod === 'day') console.log({ d: options.data });
	$: xAxis = {
		type: options.type,
		inverse: options.flipAxes,
		show: options.showAxis,
		minInterval:
			options.timePeriod === 'hour' ? 2 * 3600 : options.timePeriod === 'day' ? 6 * 3600 : 5 * 60,
		splitNumber: options.timePeriod === 'hour' ? 10 : options.timePeriod === 'day' ? 7 : 5,

		// if type is time, use category to set the min and max times of the axis
		...(options.type === 'time' && options.category?.length
			? {
					min: options.category?.[0]?.format(),
					max: options.category[options.category.length - 1]?.format()
			  }
			: {}),
		splitLine: {
			show: true,
			interval: options.timePeriod === 'hour' ? 2 * 3600 : 5 * 60,
			lineStyle: {
				color: '#aaaaaa18'
			}
		},
		axisLine: {
			lineStyle: {
				color: '#aaaaaa18'
			}
		},
		axisLabel: options.hideAxisLabels
			? { show: false }
			: {
					color: '#aaaaaa88',
					formatter: (value) =>
						options.type === 'category'
							? value
							: dayjs(value).format(
									options.formatString ||
										(options.timePeriod === 'hour'
											? 'HH'
											: options.timePeriod === 'day'
											? 'ddd'
											: 'mm')
							  ),
					interval:
						options.timePeriod === 'hour'
							? 2 * 3600
							: options.timePeriod === 'day'
							? 24 * 3600
							: 5 * 60,
					align: 'left',
					verticalAlign: options.fullScreen ? 'middle' : 'bottom',
					padding: [
						0,
						0,
						options.xAxisInGraph && !options.fullScreen ? 8 : 0,
						options.fullScreen ? -20 : 5
					],
					margin: options.xAxisInGraph ? 8 : 16,
					fontSize: 40
			  }
	};
	$: yAxis = {
		show: options.showAxis && !options.flipAxes,
		min: options.minMax.min,
		max: options.minMax.max,
		splitLine: { show: false },
		axisLine: {
			lineStyle: {
				color: '#ccc'
			}
		}
	};

	$: option = {
		animation: true,
		grid:
			options.grid || options.fullScreen
				? {
						left: 36,
						top: options.names ? 20 + Math.floor(options.names?.length / 4) * 25 : 4,
						right: 0,
						bottom: 0
				  }
				: {
						left: options.showAxis && options.flipAxes ? 25 : 0,
						top: options.names ? 20 + Math.floor(options.names?.length / 4) * 25 : 5,
						right: 5,
						bottom: options.showAxis && !options.xAxisInGraph ? 25 : 0
				  },
		tooltip: options.tooltip
			? {
					trigger: 'axis',
					appendToBody: true,
					valueFormatter: options.valueFormatter,
					axisPointer: {
						type: 'shadow'
					},
					backgroundColor: '#112244',
					borderColor: '#223366',
					textStyle: {
						color: '#efefff'
					}
			  }
			: false,
		legend: options.hideLegend
			? false
			: {
					data: options.names || [],
					textStyle: {
						color: '#999'
					}
			  },
		xAxis: options.flipAxes ? yAxis : xAxis,
		yAxis: options.flipAxes ? xAxis : yAxis,
		series: [
			...options.data.map((lineData, lineDataIndex) => {
				let color = options.colors[lineDataIndex] || hslToHex(160 + lineDataIndex * 33, 100, 60);
				return {
					name: (options.names && options.names[lineDataIndex]) || options.name,
					type: 'line',
					smooth: true,
					smoothMonotone: options.flipAxes ? 'y' : 'x',
					showSymbol: false,
					symbol: 'circle',
					//         symbolSize: 15,
					data: (options.dataForEach === 'point' ? Object.keys(lineData) : options.category)
						.sort((a, b) => dayjs(a) - dayjs(b))
						.map((dateString, i) => {
							let time = dayjs(dateString);
							let dataPoint;
							let modifier = !options.flipIndexes.includes(lineDataIndex) ? 1 : -1;
							if (lineData?.length) {
								dataPoint = lineData[i] * modifier;
								return options.flipAxes ? [dataPoint, time.format()] : [time.format(), dataPoint];
							}
							let dataBucketed = lineData[time.format()];

							if (dataBucketed === false)
								return options.flipAxes ? [undefined, time.format()] : [time.format(), undefined];
							if (typeof dataBucketed === 'number') {
								dataPoint = dataBucketed * modifier;
							} else {
								dataPoint = [...(dataBucketed || [])]
									.map((e, i) => (e.duration || e.value || 0) * modifier)
									.reduce((a, b) => a + b, 0);
								// 	if (options.timePeriod === 'day' && dataPoint)
								// 		console.log({ dataPoint, dataBucketed, time: time.format() });
							}
							// if (options.divName.includes('timeSparkLineMain'))
							// 	console.log(time.format(), { dataBucketed, dataPoint });
							return options.flipAxes ? [dataPoint, time.format()] : [time.format(), dataPoint];
						}),
					itemStyle: {
						color:
							options.area && !options.lineOnlyIndexes.includes(lineDataIndex)
								? 'transparent'
								: color
					},
					areaStyle:
						options.area && !options.lineOnlyIndexes.includes(lineDataIndex)
							? {
									opacity: 1,
									color: {
										type: 'linear',
										x: !(options.flipAxes && !options.flipIndexes.includes(lineDataIndex)) ? 0 : 1,
										y: !options.flipIndexes.includes(lineDataIndex) || options.flipAxes ? 0 : 1,
										x2: !(options.flipAxes && options.flipIndexes.includes(lineDataIndex)) ? 0 : 1,
										y2: options.flipIndexes.includes(lineDataIndex) || options.flipAxes ? 0 : 1,
										colorStops: [
											{
												offset: 0,
												color:
													options.flipAxes || !options.flipIndexes.includes(lineDataIndex)
														? color
														: color?.slice(0, 7) + 66 // color at 0%
											},
											{
												offset: 1,
												color:
													options.flipAxes || !options.flipIndexes.includes(lineDataIndex)
														? color?.slice(0, 7) + 'cc'
														: color?.slice(0, 7) + 99 // color at 100%
											}
										],
										global: false // default is false
									},
									// `url(#zr2323-gradient-${
									// 	color?.slice(1) + options.divName + lineDataIndex
									// })`,
									emphasis: {
										color: {
											type: 'linear',
											x: !(options.flipAxes && !options.flipIndexes.includes(lineDataIndex))
												? 0
												: 1,
											y: !options.flipIndexes.includes(lineDataIndex) || options.flipAxes ? 0 : 1,
											x2: !(options.flipAxes && options.flipIndexes.includes(lineDataIndex))
												? 0
												: 1,
											y2: options.flipIndexes.includes(lineDataIndex) || options.flipAxes ? 0 : 1,
											colorStops: [
												{
													offset: 0,
													color:
														options.flipAxes || !options.flipIndexes.includes(lineDataIndex)
															? color
															: color?.slice(0, 7) + 66 // color at 0%
												},
												{
													offset: 1,
													color:
														options.flipAxes || !options.flipIndexes.includes(lineDataIndex)
															? color?.slice(0, 7) + 'cc'
															: color?.slice(0, 7) + 99 // color at 100%
												}
											],
											global: false // default is false
										}
										// `url(#zr2323-gradient-${
										// 	color?.slice(1) + options.divName + lineDataIndex
										// })`
									}
							  }
							: {
									opacity: 0.1,
									color: {
										type: 'linear',
										x: options.flipIndexes.includes(lineDataIndex) || !options.flipAxes ? 0 : 1,
										y: 0,
										x2: !(options.flipIndexes.includes(lineDataIndex) || !options.flipAxes) ? 0 : 1,
										y2: options.flipAxes ? 0 : 1,
										colorStops: [
											{
												offset: 0,
												color:
													options.flipAxes || !options.flipIndexes.includes(lineDataIndex)
														? color
														: color?.slice(0, 7) + 66 // color at 0%
											},
											{
												offset: 1,
												color:
													options.flipAxes || !options.flipIndexes.includes(lineDataIndex)
														? color?.slice(0, 7) + 66
														: color?.slice(0, 7) + 99 // color at 100%
											}
										],
										global: false // default is false
									},
									// `url(#zr2323-gradient-${
									// 	color?.slice(1) + options.divName + lineDataIndex
									// })`,
									emphasis: {
										color: {
											type: 'linear',
											x: options.flipIndexes.includes(lineDataIndex) || !options.flipAxes ? 0 : 1,
											y: 0,
											x2: !(options.flipIndexes.includes(lineDataIndex) || !options.flipAxes)
												? 0
												: 1,
											y2: options.flipAxes ? 0 : 1,
											colorStops: [
												{
													offset: 0,
													color:
														options.flipAxes || !options.flipIndexes.includes(lineDataIndex)
															? color
															: color?.slice(0, 7) + 66 // color at 0%
												},
												{
													offset: 1,
													color:
														options.flipAxes || !options.flipIndexes.includes(lineDataIndex)
															? color?.slice(0, 7) + 66
															: color?.slice(0, 7) + 99 // color at 100%
												}
											],
											global: false // default is false
										}
										// `url(#zr2323-gradient-${
										// 	color?.slice(1) + options.divName + lineDataIndex
										// })`
									}
							  },
					markArea:
						!lineDataIndex && options.markArea
							? {
									silent: true,
									animation: true,
									zLevel: 20,
									label: {
										show: true,
										// formatter: "a {a}, b {b}, c {c}, value {value}",
										position: 'insideTop'
									},
									itemStyle: {
										opacity: 0.5,
										color: {
											type: 'linear',
											x: options.flipIndexes.includes(lineDataIndex) || !options.flipAxes ? 0 : 1,
											y: 0,
											x2: !(options.flipIndexes.includes(lineDataIndex) || !options.flipAxes)
												? 0
												: 1,
											y2: options.flipAxes ? 0 : 1,
											colorStops: [
												{
													offset: 0.4,
													color:
														options.flipAxes || !options.flipIndexes.includes(lineDataIndex)
															? color
															: color?.slice(0, 7) + 66 // color at 0%
												},
												{
													offset: 1,
													color:
														options.flipAxes || !options.flipIndexes.includes(lineDataIndex)
															? color?.slice(0, 7) + 66
															: color?.slice(0, 7) + 99 // color at 100%
												}
											],
											global: false // default is false
										},
										// `url(#zr2323-gradient-${
										// 	color?.slice(1) + options.divName + lineDataIndex
										// }2)`,
										borderWidth: 2,
										borderColor: {
											type: 'linear',
											x: options.flipIndexes.includes(lineDataIndex) || !options.flipAxes ? 0 : 1,
											y: 0,
											x2: !(options.flipIndexes.includes(lineDataIndex) || !options.flipAxes)
												? 0
												: 1,
											y2: options.flipAxes ? 0 : 1,
											colorStops: [
												{
													offset: 0.4,
													color:
														options.flipAxes || !options.flipIndexes.includes(lineDataIndex)
															? color
															: color?.slice(0, 7) + 66 // color at 0%
												},
												{
													offset: 1,
													color:
														options.flipAxes || !options.flipIndexes.includes(lineDataIndex)
															? color?.slice(0, 7) + 66
															: color?.slice(0, 7) + 99 // color at 100%
												}
											],
											global: false // default is false
										},
										// `url(#zr2323-gradient-${
										// 	color?.slice(1) + options.divName + lineDataIndex
										// }2)`,
										borderType: 'solid',
										borderJoin: 'round'
									},
									data: [
										...(powerPeaks || [])
											.filter((peak) => peak.position + 90 < lineData?.length)
											.map((peak) => {
												return [
													{
														xAxis: dayjs(peak.timestamp).subtract(90, 'minutes').format()
													},
													{
														xAxis: dayjs(peak.timestamp).add(90, 'minutes').format()
													}
												];
											}),
										...(powerDips || [])
											.filter((peak) => peak.position + 90 < lineData?.length)
											.map((peak) => {
												return [
													{
														xAxis: dayjs(peak.timestamp).subtract(90, 'minutes').format(),
														itemStyle: {
															opacity: 0.5,
															color: {
																type: 'linear',
																x:
																	options.flipIndexes.includes(lineDataIndex) || !options.flipAxes
																		? 0
																		: 1,
																y: 0,
																x2: !(
																	options.flipIndexes.includes(lineDataIndex) || !options.flipAxes
																)
																	? 0
																	: 1,
																y2: options.flipAxes ? 0 : 1,
																colorStops: [
																	{
																		offset: 0.4,
																		color:
																			options.flipAxes ||
																			!options.flipIndexes.includes(lineDataIndex)
																				? color
																				: color?.slice(0, 7) + 66 // color at 0%
																	},
																	{
																		offset: 1,
																		color:
																			options.flipAxes ||
																			!options.flipIndexes.includes(lineDataIndex)
																				? color?.slice(0, 7) + 66
																				: color?.slice(0, 7) + 99 // color at 100%
																	}
																],
																global: false // default is false
															},
															// `url(#zr2323-gradient-${
															// 	options.colors?.[0]?.slice(1) + options.divName + lineDataIndex
															// }2)`,
															borderWidth: 2,
															borderColor: {
																type: 'linear',
																x:
																	options.flipIndexes.includes(lineDataIndex) || !options.flipAxes
																		? 0
																		: 1,
																y: 0,
																x2: !(
																	options.flipIndexes.includes(lineDataIndex) || !options.flipAxes
																)
																	? 0
																	: 1,
																y2: options.flipAxes ? 0 : 1,
																colorStops: [
																	{
																		offset: 0.4,
																		color:
																			options.flipAxes ||
																			!options.flipIndexes.includes(lineDataIndex)
																				? color
																				: color?.slice(0, 7) + 66 // color at 0%
																	},
																	{
																		offset: 1,
																		color:
																			options.flipAxes ||
																			!options.flipIndexes.includes(lineDataIndex)
																				? color?.slice(0, 7) + 66
																				: color?.slice(0, 7) + 99 // color at 100%
																	}
																],
																global: false // default is false
															},
															// `url(#zr2323-gradient-${
															// 	options.colors?.[0]?.slice(1) + options.divName + lineDataIndex
															// }2)`,
															borderType: 'solid',
															borderJoin: 'round'
														}
													},
													{
														xAxis: dayjs(peak.timestamp).add(90, 'minutes').format()
													}
												];
											})
									]
							  }
							: false
				};
			})
		]
	};
</script>

<Echart
	{option}
	{action}
	divName={options.divName + options.name}
	minHeight={options.minHeight || (options.showAxis ? '6rem' : '4rem')}
/>
<svg style="position:fixed;height:0;width:0;">
	<defs>
		{#each options.colors as color, i}
			<linearGradient
				id="zr2323-gradient-{color?.slice(1) + options.divName + i}"
				x1={options.flipIndexes.includes(i) || !options.flipAxes ? '0' : '1'}
				y1="0"
				x2={!(options.flipIndexes.includes(i) || !options.flipAxes) ? '0' : '1'}
				y2={options.flipAxes ? '0' : '1'}
				gradientUnits="objectBoundingBox"
			>
				<stop
					offset="0%"
					stop-color={options.flipAxes || !options.flipIndexes.includes(i)
						? color
						: color?.slice(0, 7) + 66}
				/>
				<stop
					offset="100%"
					stop-color={options.flipAxes || !options.flipIndexes.includes(i)
						? color?.slice(0, 7) + 66
						: color?.slice(0, 7) + 99}
				/>
			</linearGradient>
			<linearGradient
				id="zr2323-gradient-{color?.slice(1) + options.divName + i}2"
				x1={options.flipIndexes.includes(i) || !options.flipAxes ? '0' : '1'}
				y1="0"
				x2={!(options.flipIndexes.includes(i) || !options.flipAxes) ? '0' : '1'}
				y2={options.flipAxes ? '0' : '1'}
				gradientUnits="objectBoundingBox"
			>
				<stop
					offset="40%"
					stop-color={options.flipAxes || !options.flipIndexes.includes(i)
						? color
						: color?.slice(0, 7) + 66}
				/>
				<stop
					offset="100%"
					stop-color={options.flipAxes || !options.flipIndexes.includes(i)
						? color?.slice(0, 7) + 66
						: color?.slice(0, 7) + 99}
				/>
			</linearGradient>
		{/each}
	</defs>
</svg>
