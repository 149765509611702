<script>
	import dayjs from 'dayjs';
	import Echart from './Echart.svelte';

	export let divName = 'horizontalBar';
	export let colors = ['#64b0ff', '#666666'];
	// console.log({ colors });
	export let data = [[Math.round(10 * (Math.random() - 0.5) * -8) / 10]];
	export let minMax = {
		min: -10,
		// function (value) {
		// 	return Math.floor(value.min / 10 - 1.5) * 10;
		// },
		max: 10
	};
	// $: action = {
	// 	type: 'highlight',
	// 	// Find  by index or id or name.
	// 	// Can be an array to find multiple components.
	// 	seriesIndex: 0,
	// 	dataIndex
	// };
	export let attribute = 'sleep duration';
	export let showAxis = undefined;
	$: option = {
		animation: true,
		grid: {
			left: 0,
			top: 0,
			right: 0,
			bottom: 0
		},
		tooltip: {
			trigger: 'axis',
			appendToBody: true,
			backgroundColor: '#112244',
			borderColor: '#223366',
			textStyle: {
				color: '#efefff'
			},
			axisPointer: {
				type: 'shadow'
			}
		},
		legend: {
			data: [],
			textStyle: {
				color: '#ccc'
			}
		},
		xAxis: {
			type: 'value',

			show: true,
			axisLine: {
				lineStyle: {
					color: colors?.[0] + '44'
				}
			},
			splitLine: { show: false },
			axisTicks: false,
			...(minMax || {}),
			axisLabel: {
				formatter: (value) => dayjs(value).format('HH:mm'),
				interval: (index, value) => {
					let [hours, minutes] = dayjs(value).format('HH:mm').split(':');
					return (
						Number(hours) % (lineData?.length <= longDay19Hours ? 3 : 6) === 0 && minutes === '00'
					);
				}
			}
		},
		yAxis: {
			type: 'category',
			data: [attribute],
			show: false,

			splitLine: { show: false }
			// axisLine: {
			// 	lineStyle: {
			// 		color: '#ccc'
			// 	}
			// }
		},
		series: [
			...data.map((data) => ({
				name: attribute,
				type: 'bar',
				barWidth: 7,
				itemStyle: {
					barBorderRadius: 5,
					color: colors?.[0] //`url(#${divName}23-gradient-0)`
				},
				emphasis: { itemStyle: { color: colors?.[0] } },
				data
			}))
		]
	};
</script>

<Echart {option} {divName} minHeight={showAxis ? '5rem' : '1rem'} />
<svg style="position:fixed;height:0;width:0;">
	<defs>
		<linearGradient
			id="zr2323-gradient-{colors?.[0].slice(1) + divName}0"
			x1="0"
			y1="0"
			x2="0"
			y2="1"
			gradientUnits="objectBoundingBox"
		>
			<stop offset="0%" stop-color={colors?.[0] + '66'} />
			<stop offset="100%" stop-color={colors?.[0] + '00'} />
		</linearGradient>
		<linearGradient
			id="zr2323-gradient-{colors?.[0].slice(1) + divName}2"
			x1="0"
			y1="0"
			x2="0"
			y2="1"
			gradientUnits="objectBoundingBox"
		>
			<stop offset="0%" stop-color={colors?.[0] + '00'} />
			<stop offset="100%" stop-color={colors?.[0] + '66'} />
		</linearGradient>
		<linearGradient
			id="zr2323-gradient-{colors[1].slice(1) + divName}2"
			x1="0"
			y1="0"
			x2="0"
			y2="1"
			gradientUnits="objectBoundingBox"
		>
			<stop offset="40%" stop-color={colors[1] + '00'} />
			<stop offset="100%" stop-color={colors[1] + '66'} />
		</linearGradient>
	</defs>
</svg>
