<script>
	import dayjs from 'dayjs';
	import Echart from './Echart.svelte';

	export let options = {};
	let todayInHours = Array(25)
		.fill()
		.map((a, i) =>
			(24 + dayjs().hour() - 4) % 24 === i - 1
				? dayjs()
				: dayjs().subtract(4, 'hour').startOf('day').hour(4).startOf('hour').add(i, 'hour')
		);

	let defaultOptions = {
		name: 'AreaLine',
		timePeriod: 'hour',
		type: 'time',
		area: false,
		dataForEach: 'point',
		fullScreen: false,
		divName: 'AreaLineMain',
		colors: ['#5ff453', '#ff3253', '#00D5ff', '#1455ff', '#7176ff', '#1188ff', '#00cc4C'],
		data: [
			Array(24)
				.fill()
				.map((a, i) => i),
			Array(24)
				.fill()
				.map((a, i) => (i * 2) % 24)
		],
		// [
		// 	Object.fromEntries(

		// 	)
		// ]
		category: todayInHours,
		xAxisInGraph: false,
		showAxis: true,
		flipAxes: false,
		markArea: false,
		highlightAll: false,
		flipIndexes: [],
		lineOnlyIndexes: [],
		minMax: false,
		cutSeriesTo: false
	};
	// if (options.divName?.includes('2022')) {
	// 	console.log(options.data, options.category, options.divName, options.dataForEach);
	// }
	options = { ...(defaultOptions || []), ...(options || []) };
	$: options = { ...(defaultOptions || []), ...(options || []) };

	// $: console.log({ options });

	$: xAxis = {
		type: options.type,
		inverse: options.flipAxes,
		show: options.showAxis,
		minInterval: options.timePeriod === 'hour' ? 2 * 3600 : 5 * 60,
		splitNumber: options.timePeriod === 'hour' ? 10 : 11,

		// if type is time, use category to set the min and max times of the axis
		...(options.type === 'time' && options.category?.length
			? {
					min: options.category?.[0]?.format(),
					max: options.category[options.category.length - 1]?.format()
			  }
			: {}),
		splitLine: {
			show: true,
			interval: options.timePeriod === 'hour' ? 2 * 3600 : 5 * 60,
			lineStyle: {
				color: '#aaaaaa22'
			}
		},
		axisLine: {
			lineStyle: {
				color: '#aaaaaa22'
			}
		},
		axisLabel: {
			formatter: (value) =>
				options.type === 'category'
					? value
					: dayjs(value).format(
							options.formatString || (options.timePeriod === 'hour' ? 'HH' : 'mm')
					  ),
			interval: options.timePeriod === 'hour' ? 2 * 3600 : 5 * 60,
			align: 'left',
			verticalAlign: options.fullScreen ? 'middle' : 'bottom',
			padding: [
				0,
				0,
				options.xAxisInGraph && !options.fullScreen ? 8 : 0,
				options.fullScreen ? -20 : 5
			],
			margin: options.xAxisInGraph ? 8 : 16,
			fontSize: 40
		}
	};
	$: yAxis = {
		show: options.showAxis && !options.flipAxes,
		min: options.minMax.min,
		max: options.minMax.max,
		splitLine: { show: false },
		axisLine: {
			lineStyle: {
				color: '#ccc'
			}
		}
	};

	$: option = {
		xAxis: {
			show: false,
			type: 'time',
			min:
				options.min || dayjs().subtract(4, 'hour').startOf('day').hour(4).startOf('hour').format(),
			max:
				options.max ||
				dayjs().subtract(4, 'hour').endOf('day').add(4, 'hour').hour(4).startOf('hour').format(),
			axisPointer: {
				type: 'shadow',
				lineStyle: {
					color: 'transparent'
				},
				shadowStyle: {
					color: 'transparent'
				}
			}
		},
		yAxis: {
			show: false,
			min: 0,
			max: 1.2
		},
		grid: {
			left: 0,
			right: 0,
			top: 0,
			bottom: 0
		},
		tooltip: {
			trigger: 'axis',
			appendToBody: true,
			valueFormatter: (value) => {
				// console.log(value);
				return 'Productivity: ' + Math.round(value * 100) + '%';
			},
			backgroundColor: '#112244',
			borderColor: '#223366',
			textStyle: {
				color: '#efefff'
			},
			axisPointer: {
				type: 'shadow',
				lineStyle: {
					color: 'transparent'
				},
				shadowStyle: {
					color: 'transparent'
				}
			}
		},
		emphasis: {
			focus: 'series',
			label: {
				show: true,
				formatter: function (param) {
					// offset = Math.round(param.data[2] / (18 * 3));
					// console.log(offset);
					// console.log(param);
					return Math.round(param.data[2] / 60) + ' min';
				},
				color: 'white',
				position: 'bottom'
			}
		},
		series: [
			{
				symbolSize: (s) => s[2] / (36 * 3),
				symbolOffset: ['50%', 0],
				itemStyle: {
					shadowBlur: 10,
					shadowColor: 'rgba(20, 36, 50, 0.5)',
					shadowOffsetY: 5,
					color: {
						colorStops: [
							{
								offset: 0,
								color: 'rgb(73, 178, 255)'
							},
							{
								offset: 1,
								color: 'rgb(20, 85, 255)'
							}
						],
						x: 0.4,
						y: 0.3,
						r: 1,
						type: 'radial',
						global: false
					}
				},
				data:
					options.data ||
					[{ timestamp: dayjs().format(), productivityScore: 0.8, duration: 2930 }]?.map((s) => [
						s.timestamp,
						s.productivityScore,
						s.duration
					]) ||
					[],
				type: 'scatter'
			}
		]
	};
</script>

<Echart
	{option}
	divName={options.divName + options.name}
	minHeight={options.minHeight || (options.showAxis ? '6rem' : '4rem')}
/>
<svg style="position:fixed;height:0;width:0;">
	<defs>
		{#each options.colors as color, i}
			<linearGradient
				id="zr2323-gradient-{color.slice(1) + options.divName + i}"
				x1={options.flipIndexes.includes(i) || !options.flipAxes ? '0' : '1'}
				y1="0"
				x2={!(options.flipIndexes.includes(i) || !options.flipAxes) ? '0' : '1'}
				y2={options.flipAxes ? '0' : '1'}
				gradientUnits="objectBoundingBox"
			>
				<stop
					offset="0%"
					stop-color={options.flipAxes || !options.flipIndexes.includes(i)
						? color
						: color.slice(0, 7) + 66}
				/>
				<stop
					offset="100%"
					stop-color={options.flipAxes || !options.flipIndexes.includes(i)
						? color.slice(0, 7) + 66
						: color.slice(0, 7) + 99}
				/>
			</linearGradient>
			<linearGradient
				id="zr2323-gradient-{color.slice(1) + options.divName + i}2"
				x1={options.flipIndexes.includes(i) || !options.flipAxes ? '0' : '1'}
				y1="0"
				x2={!(options.flipIndexes.includes(i) || !options.flipAxes) ? '0' : '1'}
				y2={options.flipAxes ? '0' : '1'}
				gradientUnits="objectBoundingBox"
			>
				<stop
					offset="40%"
					stop-color={options.flipAxes || !options.flipIndexes.includes(i)
						? color
						: color.slice(0, 7) + 66}
				/>
				<stop
					offset="100%"
					stop-color={options.flipAxes || !options.flipIndexes.includes(i)
						? color.slice(0, 7) + 66
						: color.slice(0, 7) + 99}
				/>
			</linearGradient>
		{/each}
	</defs>
</svg>
