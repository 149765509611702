<script>
	import dayjs from 'dayjs';
	import { screenTimeCategories } from '$lib/screenTimeCategories.js';
	import { makeDecimalIntoTime, mergeEventsCloserThan } from '$lib/connectUtils.js';
	import LoadingOrb from './LoadingOrb.svelte';
	import {
		IsElectron,
		UserEmail,
		UserInfo,
		Data,
		TodaysData,
		DateRange,
		IsDev
	} from '$lib/store.js';
	import { categoryTask } from '$lib/connectUtils.js';
	import { aggregateAndSort, sleep } from '$lib/utils.js';
	import Modal from './Modal.svelte';
	export let activityDetails = undefined;
	export let divName = '';
	// $: console.log({ editingCategories });
	let [type, title] = activityDetails?.type?.split(': ') || ['App', activityDetails?.data?.app];
	let originalCategory = activityDetails?.data?.categories?.[0];
	let originalSubcategory = activityDetails?.data?.categories?.[1];
	let selectedType = activityDetails?.data?.focus
		? 'Focus Work'
		: `${activityDetails?.data?.work ? 'Work' : 'Not work'}`;
	let selectedActivities = [];
	export let events;
	let activities = [];
	$: if (!activities?.length)
		activities = aggregateAndSort(
			events ||
				Object.values($Data['["timeseries","time_series/activitywatch","noCache"]'] || {})
					.map((d) => d?.window?.events || [])
					.flat()
		);
	$: console.log({ activities });
	$: categories = Array.from(new Set(activities.map((a) => a[1]?.categories?.[0])))
		.map((cat) => {
			let activitiesInCategory = activities.filter((a) => a[1]?.categories?.[0] === cat);
			return [
				activitiesInCategory?.[0]?.[1]?.categories,
				activitiesInCategory.reduce((a, b) => a + b[1].duration || 0, 0),
				activitiesInCategory
			];
		})
		.sort((a, b) => b[1] - a[1]);
	$: console.log(selectedActivities);
	let loading;
	function moveTo(category) {
		if (!selectedActivities.length) return;
		let selectedActivityData = selectedActivities.map((a) =>
			activities.find(([type, data]) => type === a)
		);
		let newCategory = screenTimeCategories.find(([cat, regex]) => cat.includes(category[0]))[0];
		console.log({ selectedActivityData, newCategory });
		selectedActivityData = selectedActivityData.filter((a) => a);
		if (!selectedActivityData.length) return;

		loading = true;
		let newStrings = selectedActivityData
			.map(([type, data]) => {
				let newStrings = type?.split(': ')?.[1]?.trim() || data?.url || data?.bundleId;
				return newStrings;
			})
			.join('|')
			.trim();
		if (!(newCategory?.length && newStrings.length)) return;
		console.log({ newCategory, newStrings });
		// categoryTask(
		// 	'updateCategories',
		// 	String.fromCharCode(...$UserEmail?.split('').map((l) => l.charCodeAt(0) + 2)),
		// 	[[newCategory, newStrings?.trim()]]
		// );

		$UserInfo.personalCategories = [
			...($UserInfo.personalCategories || []),
			[newCategory, newStrings?.trim()]
		];
		postUserInfo();
		selectedActivityData.forEach((activity) => {
			activity[1] = {
				...(activity[1] || []),
				categories: newCategory?.filter((cat) => cat !== 'Work' && cat !== 'Focus Work') || [
					'No category'
				],
				reason: [[newCategory, [[activity[1].url ? 'url' : 'app', [newStrings, null]]]]],
				work: newCategory.includes('Work'),
				focus: newCategory.includes('Focus Work')
			};
			activity[1].work = newCategory.includes('Work');
			activity[1].focus = newCategory.includes('Focus Work');
		});
		updateLiveData(newCategory, newStrings);
		selectedActivities = [];
		setTimeout(() => query('screenTime')(0, true, true), 2000);
		$DateRange.daysInPast = $DateRange.daysInPast;
		loading = false;
	}
	async function updateLiveData(newCategory, newStrings) {
		let regex;
		try {
			regex = new RegExp(newStrings, 'i');
		} catch (e) {
			console.error(e);
			if (newStrings.startsWith('*') || newStrings.startsWith('+')) {
				try {
					regex = new RegExp(newStrings.replace(/(\*|\+)/, '.$1'));
				} catch (e) {
					regex = new RegExp(newStrings.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
					console.error(e);
				}
			} else regex = new RegExp(newStrings.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
		}
		let dates = Object.keys($Data[`["timeseries","time_series/activitywatch","noCache"]`])
			.sort()
			.reverse();
		for (let todaysDate of dates) {
			let events =
				$Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[todaysDate]?.window?.events;
			if (events?.length) {
				// raw events
				console.log('updating activitywatch from live data rc1');

				$Data[`["timeseries","time_series/activitywatch","noCache"]`][todaysDate].window.events =
					events?.map((e) => {
						if (['title', 'url', 'app', 'domain'].some((string) => regex.test(e[string] || '')))
							e = {
								...(e || []),
								categories: newCategory?.filter(
									(cat) => cat !== 'Work' && cat !== 'Focus Work'
								) || ['No category'],
								reason: [[newCategory, [[e.url ? 'url' : 'app', [newStrings, null]]]]],
								work: newCategory.includes('Work'),
								focus: newCategory.includes('Focus Work')
							};
						return e;
					});
				// contexts
				await sleep(500);
				console.log('updating activitywatch from live data rc2');

				$Data[`["timeseries","time_series/activitywatch","noCache"]`][todaysDate].window.sessions =
					$Data[`["timeseries","time_series/activitywatch","noCache"]`][
						todaysDate
					].window.sessions?.map((e) => {
						e.contexts = e.contexts?.map((e) => {
							if (['title', 'url', 'app', 'domain'].some((string) => regex.test(e[string] || '')))
								e = {
									...(e || []),
									categories: newCategory?.filter(
										(cat) => cat !== 'Work' && cat !== 'Focus Work'
									) || ['No category'],
									reason: [[newCategory, [[e.url ? 'url' : 'app', [newStrings, null]]]]],
									work: newCategory.includes('Work'),
									focus: newCategory.includes('Focus Work')
								};
							return e;
						});
						return e;
					});
				if (
					todaysDate ===
					dayjs().subtract(4, 'hour').subtract($DateRange.daysInPast, 'day').format('YYYY-MM-DD')
				) {
					$TodaysData.productiveEvents = $Data[
						`["timeseries","time_series/activitywatch","noCache"]`
					][todaysDate].window.events.filter((e) => e?.work);
					$TodaysData.unproductiveEvents = $Data[
						`["timeseries","time_series/activitywatch","noCache"]`
					][todaysDate].window.events.filter((e) => !e?.work);
				}
			}
			await sleep(1000);
		}
	}
</script>

{#if loading}
	<LoadingOrb />
{/if}
<h2>{divName}</h2>
{#each [['Uncategorized activities', (categories || []).filter((c) => !c[0]?.[0] || c[0]?.[0] === 'Web Browsing')], ['Categorized activities', (categories || []).filter((c) => c[0]?.[0] && c[0]?.[0] !== 'Web Browsing')]] as [title, list]}
	<div class="font-bold my-2">{title}</div>
	{#if !list.length}
		<div class="mb-16">Nothing here! All activities are categorized.</div>
	{:else}
		<div class="grid reinvert text-black pb-8 grid-cols-5 gap-4">
			{#each list as [category, duration, activityList]}
				<div
					class="bg-gray-800 rounded-2xl overflow-hidden bg-opacity-10"
					style="background:{activityList[0]?.[1]?.work
						? activityList[0]?.[1]?.focus
							? '#00ff3311'
							: '#ccdd5511'
						: '#ff505011'}"
				>
					<div
						class="flex relative justify-between  font-bold text-sm mb-2 px-4 py-2 bg-opacity-80"
						style="background:{activityList[0]?.[1]?.work
							? activityList[0]?.[1]?.focus
								? '#00ff33'
								: '#ccdd55'
							: '#ff5050'}"
					>
						<div class="capitalize">{category?.[0]}</div>
						{#if selectedActivities.length}
							<button
								on:click={() => moveTo(category)}
								class="absolute right-2 top-1/2 -translate-y-1/2 px-2 py-1 font-semibold bg-gray-100"
							>
								Move here
							</button>
						{/if}
						<div>{makeDecimalIntoTime(duration / 3600, 'durationNoZeroWithSeconds')}</div>
					</div>
					<!-- Select all component -->
					<div
						on:click={() => {
							if (
								activityList.every(([activity, activityData]) =>
									selectedActivities.includes(activity)
								)
							)
								selectedActivities = selectedActivities.filter(
									(activity) =>
										!activityList.map(([activity, activityData]) => activity).includes(activity)
								);
							else
								selectedActivities = selectedActivities.length
									? Array.from(
											new Set([
												...selectedActivities,
												...activityList.map(([activity, activityData]) => activity)
											])
									  )
									: activityList.map(([activity, activityData]) => activity);
						}}
						class="flex items-center text-xs font-medium px-4 py-2 gap-2"
					>
						<div
							class="rounded p-0.5 h-4 w-4"
							style="background:{activityList[0]?.[1]?.work
								? activityList[0]?.[1]?.focus
									? '#00ff33'
									: '#ccdd55'
								: '#ff5050'}"
						>
							{#if activityList.every( ([activity, activityData]) => selectedActivities.includes(activity) )}
								<div class="rounded-sm h-3 w-3 bg-white" />
							{/if}
						</div>
						<div class="flex-grow font-semibold text-sm">Select all</div>
					</div>
					<div class="px-4 py-2">
						{#each activityList as [activity, activityData]}
							<div
								on:click={() =>
									selectedActivities.includes(activity)
										? (selectedActivities = selectedActivities.filter((a) => a !== activity))
										: (selectedActivities = [...selectedActivities, activity])}
								class="flex justify-between items-center text-xs gap-2 mb-1"
							>
								<div
									class="rounded p-0.5 h-4 w-4"
									style="background:{activityList[0]?.[1]?.work
										? activityList[0]?.[1]?.focus
											? '#00ff33'
											: '#ccdd55'
										: '#ff5050'}"
								>
									{#if selectedActivities.includes(activity)}
										<div class="rounded-sm h-3 w-3 bg-white" />
									{/if}
								</div>
								<div class="flex-grow">{activity?.split(': ')[1]?.slice(0, 20)}</div>
								<div>
									{makeDecimalIntoTime(activityData?.duration / 3600, 'durationNoZeroWithSeconds')}
								</div>
							</div>
						{/each}
					</div>
				</div>
			{/each}
		</div>
	{/if}
{/each}
