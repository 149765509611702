<script>
	import { tweened } from 'svelte/motion';
	import { cubicOut } from 'svelte/easing';
	export let value = 0;
	export let small = false;
	export let medium = false;
	let flowing = false;
	const progress = tweened(value, {
		duration: (from, to) => (to < from ? 0 : 1250),
		easing: cubicOut
	});
	$: progress.set(typeof value === 'number' ? value : 0);
	let flowLevels = [
		['Magic', 0.9],
		['Smooth', 0.8],
		['Good', 0.7],
		['Okay', 0.5],
		['Low', 0.3],
		['Break', 0]
	];
	$: currentLevel =
		flowLevels.find((level) => level[1] <= value) || flowLevels[flowLevels?.length - 1];
</script>

<div
	class="wrapper {!small && !medium ? 'm-4' : ''}"
	style="border-radius: 10000px;
	position: relative;{small
		? 'height: 42px;width: 42px;'
		: medium
		? 'height: 72px;width: 72px;margin-top:-8px;'
		: 'height: 108px;width: 108px;'}filter:hue-rotate({-25 + $progress * 100}deg) saturate({1 +
		$progress * 0.5});"
>
	<div class="wrapper1 reinvert" style="filter: blur({small ? 3 : medium ? 4 : 8}px);" />
	<div
		class="wrapper2"
		style="height: 100%;
	width: 100%;"
	>
		<div
			class="ocean reinvert"
			style="height: calc(100% - {small ? 4 : medium ? 6 : 8}px);
	width: calc(100% - {small ? 4 : medium ? 6 : 8}px);"
		>
			{#each [1, 2, 3, 4] as i}
				<div
					class="wave"
					style="height:{$progress * (small ? 110 : 105)}%;{flowing ? '' : 'animation:none;'}"
				/>
			{/each}
			<div
				class={small
					? 'text-base font-bold'
					: medium
					? 'text-2xl font-semibold'
					: 'text-4xl font-semibold'}
				style="position:absolute;z-index:50;left:50%;top:{small
					? 50
					: 43}%;transform: translate(-50%, -50%);color:white;"
			>
				{Math.round($progress * 100)}
			</div>
			{#if false}
				<div
					class="{'text-xs'} w-full text-center uppercase"
					style="position:absolute;z-index:50;left:50%;top:69%;transform: translate(-50%, -50%);font-weight:600;color:white;"
				>
					{currentLevel?.[0]}
					{small || medium || currentLevel?.[0] === 'Break' ? '' : 'flow'}
				</div>
			{/if}
		</div>
	</div>
</div>

<style>
	.wrapper1 {
		border-radius: 10000px;
		position: relative;
		height: 100%;
		width: 100%;
		background: linear-gradient(180deg, #f1d900 0%, #dc4040 100%),
			linear-gradient(
				215.57deg,
				rgba(69, 69, 69, 0.308) 15.89%,
				rgba(255, 255, 255, 0.35) 42.05%,
				rgba(58, 58, 58, 0.096) 84.85%
			);
	}
	.wrapper2 {
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		top: 50%;
		border-radius: 10000px;

		background: #191e34;
	}

	.ocean {
		height: calc(100% - 4px);
		width: calc(100% - 4px);
		position: absolute;
		overflow: hidden;
		border-radius: 10000px;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		top: 50%;
		background: transparent;
	}

	.wave {
		background: url(/wave.svg) repeat-x;
		position: absolute;
		background-size: contain;
		bottom: -3px;
		width: 6400px;
		filter: hue-rotate(0deg);
		/* opacity: 0.9; */
		animation: wave 17s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
		transform: translate3d(0, 0, 0);
	}

	.wave:nth-of-type(2) {
		animation: wave 17s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
			swell 7s ease -1.5s infinite;
	}
	.wave:nth-of-type(3) {
		animation: wave 17s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.25s infinite,
			swell 7s ease -1.5s infinite;
	}
	.wave:nth-of-type(3) {
		animation: wave 17s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.375s infinite,
			swell 7s ease -1.5s infinite;
	}

	@keyframes wave {
		0% {
			margin-left: 0;
		}
		100% {
			margin-left: -400px;
		}
	}

	@keyframes swell {
		0%,
		100% {
			transform: translate3d(0, -5px, 0);
		}
		50% {
			transform: translate3d(0, 15px, 0);
		}
	}
</style>
