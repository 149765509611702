<script>
	import { sleep, storeLocal, uploadTimeSeries, getTimeSeries } from '$lib/utils.js';
	import { query, makeDecimalIntoTime } from '$lib/connectUtils.js';
	import { insightAttributes } from '$lib/attributes.js';
	import { timeRangeForActivity } from '$lib/dataProcessing.js';

	import dayjs from 'dayjs';
	import {
		IsNative,
		IsElectron,
		IsDev,
		UserEmail,
		CurrentActivity,
		Data,
		TodaysData,
		DateRange,
		Platform
	} from '$lib/store.js';
	import { KeepAwake } from '@capacitor-community/keep-awake';

	import Modal from './Modal.svelte';
	import TimeTracked from './charts/TimeTracked.svelte';
	import Icon from './Icon.svelte';
	let attributes = insightAttributes;
	let small = $CurrentActivity.small;

	export let increase;
	// export let color;
	export let showActivity = false;
	export let todaysDate;
	export let summary = false;
	increase.attributes = increase.attributes || ['work'];
	increase.name = increase.name || 'Work';
	let changeTime;
	let finished;
	let startDate = dayjs().subtract(10, 'minute');
	$: if (showActivity && !$CurrentActivity.operator) startDate = dayjs().subtract(10, 'minute');
	export let latestIsWork = true;
	let duration = 0;
	$: if (!increase && showActivity) {
		increase = { ...showActivity, attributes: ['work'] };
	}
	$: console.log(showActivity);
	$: if ($CurrentActivity.duration && $CurrentActivity.operator === increase.operator && !summary) {
		console.log({ increase, showActivity }, 1);
		duration = $CurrentActivity.duration;
		startDate = $CurrentActivity.startDate;
	} else if (increase?.duration || showActivity?.duration) {
		duration = increase.duration || showActivity?.duration;
		console.log({ increase, showActivity }, 2, duration);
		startDate = dayjs(increase.startDate || increase.timestamp);
		increase.idealDuration = duration;
	} else {
		console.log({ increase, showActivity }, 3);
		duration = 0;
		startDate = dayjs().subtract(10, 'minute');
	}
	$: startOfLastSession = dayjs($TodaysData?.activitywatch?.productivityLastHour?.timestamp);
	let durationOfLastSession;
	setInterval(() => (durationOfLastSession = dayjs().diff(startOfLastSession, 'second')), 1000);
	// $: if (summary) console.log({ increase });
	if (small) showActivity = true;
	$: durationForShowing =
		$CurrentActivity.stopWatch || summary
			? increase.idealDuration - duration <= 0
				? duration
				: increase.idealDuration - duration
			: duration || 0;
	function makeTimeToShow(durationForShowing) {
		return `${makeDecimalIntoTime(durationForShowing / 3600, 'durationNoZero')}`;
	}

	$: timeToShow = makeTimeToShow(durationForShowing);
</script>

{#if !showActivity}
	<button
		class="absolute top-1.5 right-2 p-1 px-3 rounded-full text-xs z-20"
		style="background: #103082;color: white;"
		on:click|stopPropagation={() => {
			// showActivity = increase;
			if (!$CurrentActivity.operator) {
				$CurrentActivity = { ...(increase || []), duration: 1, startDate: dayjs().format() };
			}
			console.log({ $CurrentActivity, increase });

			if ($CurrentActivity.operator && $CurrentActivity.operator !== increase.operator) {
				if (confirm('Starting this activity will end the current activity. Proceed?')) {
					clearInterval($CurrentActivity.stopWatch);
					$CurrentActivity = { ...(increase || []), duration: 1, startDate: dayjs().format() };
				} else return;
			}
			if ($CurrentActivity.stopWatch) {
				$CurrentActivity.stopWatch = clearInterval($CurrentActivity.stopWatch);
				// if ($IsNative) KeepAwake.allowSleep();
			}
			if ($IsNative) KeepAwake.keepAwake();
			startDate = dayjs();
			$DateRange.daysInPast = 0;
			$IsElectron
				? query('screentime')($DateRange.daysInPast).then((a) => ($DateRange.daysInPast = 0))
				: getTimeSeries('activitywatch', { daysInPast: $DateRange.daysInPast });
			if (!$IsDev)
				window.analytics?.track?.('Activity Started', {
					platform: $Platform,
					environment: $IsDev ? 'dev' : 'production',
					email: $UserEmail
				});
			$CurrentActivity.stopWatch = setInterval(() => {
				$CurrentActivity.duration = dayjs().diff($CurrentActivity.startDate, 'second');
				if ($CurrentActivity.duration >= increase?.idealDuration) {
					$CurrentActivity.idealDuration = $CurrentActivity.duration;
					// color = 'limegreen';
					// change buttons to reflect activity done
					// if work, go to break
					// if ($IsNative) KeepAwake.allowSleep();
				}
			}, 1000);
			console.log({ $CurrentActivity });
			if ($IsElectron && $CurrentActivity.attributes?.[0] === 'work') {
				// small = true;
				// $CurrentActivity.small = true;
				window.api?.call('focusSession');
			}
		}}
	>
		Focus
	</button>
{/if}

<Modal
	bind:show={showActivity}
	on:close={() => {
		if ($IsNative) KeepAwake.allowSleep();
		summary = false;
	}}
	allowClose={!small}
	options={{
		name: 'Activity' + (summary ? 'Summary' : ''),
		topLevel: true,
		fullScreen: true,
		cssText: `z-index:52;background:${
			latestIsWork || summary ? '#0a1632' : '#e4004e'
		};justify-content:space-around;align-items:center;gap:1rem;${
			small
				? 'gap:0.25rem;padding:0.25rem;width:100vw;height:100%;font-size:0.9rem;-webkit-app-region: drag;cursor:move;'
				: 'gap:0.5rem;font-size: 2rem;'
		}font-weight:600;`
	}}
>
	<div
		class="flex gap-2 {small
			? 'brightness-80'
			: 'flex-col'} w-full h-full items-center justify-around"
	>
		{#if !summary && $IsElectron}
			<button
				class="{!small
					? 'absolute'
					: 'opacity-40 hover:opacity-100'} flex items-center gap-1 text-xs left-1.5 top-4 p-1 shadow-none text-white text-opacity-60 bg-transparent hover:text-opacity-100 cursor-pointer"
				on:click={async () => {
					if ($CurrentActivity.small) {
						await window.api?.call('sessionFullSize');
						// $CurrentActivity.small = false;
						// small = false;
					} else {
						// $CurrentActivity.small = true;
						// small = true;
						window.api?.call('focusSession');
					}
				}}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="h-5 w-5"
					viewBox="0 0 20 20"
					fill="currentColor"
				>
					{#if $CurrentActivity.small}
						<path
							fill-rule="evenodd"
							d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 01-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 110-2h4a1 1 0 011 1v4a1 1 0 11-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 112 0v1.586l2.293-2.293a1 1 0 011.414 1.414L6.414 15H8a1 1 0 110 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 110-2h1.586l-2.293-2.293a1 1 0 011.414-1.414L15 13.586V12a1 1 0 011-1z"
							clip-rule="evenodd"
						/>
					{:else}
						<path
							d="M9.707 7.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L13 8.586V5h3a2 2 0 012 2v5a2 2 0 01-2 2H8a2 2 0 01-2-2V7a2 2 0 012-2h3v3.586L9.707 7.293zM11 3a1 1 0 112 0v2h-2V3z"
						/>
						<path d="M4 9a2 2 0 00-2 2v5a2 2 0 002 2h8a2 2 0 002-2H4V9z" />
					{/if}
				</svg>
				{#if !$CurrentActivity.small}
					Widget
				{/if}
			</button>
		{/if}
		<div class="flex justify-center {$CurrentActivity.small ? ' gap-0 ' : '  gap-2 '} items-center">
			<Icon
				size={$CurrentActivity.small ? [1.5, 1] : [3, 2.5]}
				includeText={true}
				forceUppercase={!$CurrentActivity.small}
				color={'white'}
				textOverride={$CurrentActivity.small ? timeToShow : undefined}
				attribute={(increase.attributes && increase.attributes?.[0]) || 'focus'}
			/>
		</div>
		{#if !$CurrentActivity.small}
			{#if $CurrentActivity.stopWatch || $CurrentActivity.duration || (summary && showActivity?.duration) || $TodaysData?.activitywatch?.productivityLastHour?.duration}
				<div class="uppercase text-center relative">
					{timeToShow}
					<!-- {#if summary} -->
					<div class="uppercase text-center text-xs absolute top-full w-max centreInContext">
						{timeRangeForActivity(
							(showActivity?.timestamp && showActivity) ||
								(($CurrentActivity?.timestamp ||
									$CurrentActivity?.startedAt ||
									$CurrentActivity?.startDate) &&
									CurrentActivity) ||
								$TodaysData?.activitywatch?.productivityLastHour
						)}
					</div>
					<!-- {/if} -->
				</div>
			{:else}
				<div class="flex gap-2 w-full justify-around">
					{#each [25 * 60, 55 * 60, 90 * 60] as idealDuration}
						<button
							class="text-white bg-gray-900 text-lg {increase.idealDuration !== idealDuration
								? 'bg-opacity-10'
								: 'bg-opacity-60'}"
							on:click={() => {
								increase.idealDuration = idealDuration;
							}}
						>
							{makeTimeToShow(idealDuration)}
						</button>
					{/each}
				</div>
			{/if}
		{/if}
		{#if increase.name === 'Workout'}
			<div
				class="grid text-base uppercase items-center justify-items-center w-full"
				style="grid-template-columns: 1fr 1fr !important"
			>
				{#each [['totalDistance', 'distance', 'km', 0.001], ['totalEnergyBurned', 'calories', undefined, 1]] as [metric, title, unit, multiplier]}
					<div class="flex flex-col items-center">
						<div class="text-2xl relative">
							{Math.round(increase[metric] * 10 * multiplier) / 10}
							{#if unit}
								<span class="text-xs text-gray-500">{unit}</span>
							{/if}
						</div>
						<div>
							{title}
						</div>
					</div>
				{/each}
			</div>
		{/if}
		{#if !small && ['focus', 'work', 'screentime'].includes(increase.attributes && increase.attributes?.[0])}
			<div class="h-full w-full flex {small ? '' : 'flex-col'} items-center justify-between">
				<TimeTracked
					{latestIsWork}
					since={$CurrentActivity.stopWatch || summary
						? (dayjs(increase?.timestamp || increase?.startDate || startDate) || dayjs()).subtract(
								1,
								'minute'
						  )
						: startOfLastSession}
					{summary}
					small={$CurrentActivity.small}
					numberOfMinutes={Math.ceil(
						$CurrentActivity.stopWatch || summary
							? Math.round(
									(summary ? increase?.duration : ($CurrentActivity || increase)?.idealDuration) /
										60
							  ) || 30
							: dayjs().diff(startOfLastSession, 'minute') + 5
					) || 25}
					show={['time', 'activities', 'flow']}
					divName={'Activity' + (summary ? 'Summary' : '')}
				/>
			</div>
		{:else}
			<div
				class="rounded-full w-6 h-6 flex items-center justify-center"
				style="background:hsla({10 +
					($TodaysData?.activitywatch?.productivityLastHour?.productivityScore || 0) ** 2 *
						130} 100% 40% / 100%)"
			>
				{Math.round(100 * $TodaysData?.activitywatch?.productivityLastHour?.productivityScore)}
			</div>
		{/if}
		<div class="flex max-w-md {small ? '' : 'flex-col'} gap-2 {!small ? 'text-xl w-full' : ''}">
			{#if !summary && !$CurrentActivity.stopWatch}
				<button
					style="background:{latestIsWork || summary
						? '#0a1632'
						: '#e4004e'};{$CurrentActivity.small ? 'padding:0.15rem;border:none;' : ''}"
					on:click={() => {
						if (!$CurrentActivity.operator) {
							$CurrentActivity = { ...(increase || []), duration: 1, startDate: dayjs().format() };
						}
						if ($CurrentActivity.operator && $CurrentActivity.operator !== increase.operator) {
							if (confirm('Starting this activity will end the current activity. Proceed?')) {
								clearInterval($CurrentActivity.stopWatch);
								$CurrentActivity = {
									...(increase || []),
									duration: 1,
									startDate: dayjs().format()
								};
							} else return;
						}
						if ($CurrentActivity.stopWatch) {
							$CurrentActivity.stopWatch = clearInterval($CurrentActivity.stopWatch);
							if ($IsNative) KeepAwake.allowSleep();
						} else {
							if ($IsNative) KeepAwake.keepAwake();
							startDate = dayjs();
							$DateRange.daysInPast = 0;
							$IsElectron
								? query('screentime')($DateRange.daysInPast).then(
										(a) => ($DateRange.daysInPast = 0)
								  )
								: getTimeSeries('activitywatch', { daysInPast: $DateRange.daysInPast });
							if (!$IsDev)
								window.analytics?.track?.('Activity Started', {
									platform: $Platform,
									environment: $IsDev ? 'dev' : 'production',
									email: $UserEmail
								});
							$CurrentActivity.stopWatch = setInterval(() => {
								$CurrentActivity.duration = dayjs().diff($CurrentActivity.startDate, 'second');
								if ($CurrentActivity.duration >= increase?.idealDuration) {
									$CurrentActivity.idealDuration = $CurrentActivity.duration;
									// color = 'limegreen';
									// change buttons to reflect activity done
									// if work, go to break
									// if ($IsNative) KeepAwake.allowSleep();
								}
							}, 1000);
							if ($IsElectron && $CurrentActivity.attributes?.[0] === 'work') {
								// $CurrentActivity.small = true;
								// small = true;
								window.api?.call('focusSession');
							}
						}
					}}>{!$CurrentActivity.stopWatch ? 'Start focus timer' : 'Pause'}</button
				>
				{#if !small}
					<button
						style="background:{latestIsWork || summary ? '#0a1632' : '#e4004e'};"
						on:click={() => (showActivity = undefined)}>Open Magicflow</button
					>
				{/if}
			{:else if summary}
				<button
					class="rounded-2xl w-full text-white block"
					style="background:{latestIsWork || summary
						? '#0a1632'
						: '#e4004e'};{$CurrentActivity.small ? 'padding:0.15rem;' : ''}"
					on:click={() => {
						$CurrentActivity = {};
						showActivity = false;
						finished = false;
						summary = false;
						startDate = dayjs().subtract(2, 'minute');
					}}
				>
					Done
				</button>
			{:else}
				<button
					style="background:{latestIsWork || summary
						? '#0a1632'
						: '#e4004e'};{$CurrentActivity.small || small ? 'padding:0.15rem 0.45rem;' : ''}"
					on:click={() => {
						if ($CurrentActivity.stopWatch) {
							$CurrentActivity.stopWatch = clearInterval($CurrentActivity.stopWatch);
							if ($IsNative) KeepAwake.allowSleep();
						}
						if (finished || $CurrentActivity.duration < 300) {
							showActivity = false;
							$CurrentActivity = {};
							finished = false;
							summary = false;
							startDate = dayjs().subtract(2, 'minute');
						}
						if ($CurrentActivity.duration > 300) {
							finished = true;
							if (!$Data['["timeseries","time_series/ethi","noCache"]'])
								$Data['["timeseries","time_series/ethi","noCache"]'] = {};
							$Data['["timeseries","time_series/ethi","noCache"]'][todaysDate] = [
								...($Data['["timeseries","time_series/ethi","noCache"]'][todaysDate] || []),
								{ ...$CurrentActivity }
							];
							storeLocal(
								'["timeseries","time_series/ethi","noCache"]',
								$Data['["timeseries","time_series/ethi","noCache"]']
							);
							uploadTimeSeries(
								$Data['["timeseries","time_series/ethi","noCache"]'][todaysDate],
								'ethi'
							);
							summary = true;
							increase.duration = $CurrentActivity.duration;
						}
						// increase.idealDuration = $CurrentActivity.duration;
						$CurrentActivity.small = false;
						small = false;
						if ($IsElectron) window.api?.call('sessionFinish');
					}}
					class="rounded-2xl w-full text-white block"
					>{$CurrentActivity.duration > 300 ? 'Finish' : 'Cancel'}</button
				>
			{/if}
		</div>
	</div>
</Modal>

<style>
	button {
		-webkit-app-region: no-drag;
	}
</style>
