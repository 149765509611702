<script>
	import Icon from '../Icon.svelte';
	import { TodaysData, IsDev, DateRange } from '$lib/store.js';
	import { makeDecimalIntoTime } from '$lib/connectUtils.js';
	import { accumulateObjects } from '$lib/dataProcessing.js';
	import AreaLineGraph from './chartFunctions/AreaLineGraph.svelte';
	import { fly } from 'svelte/transition';
	import dayjs from 'dayjs';
	export let details;

	export let divName;
	export let productivityMetrics;
	export let datesScreenTime = undefined;
	export let datesToAnalyse = undefined;
	$: sessions =
		datesToAnalyse?.length > 1
			? datesScreenTime?.map((d) => ({
					...[...d.window.sessions.map((s) => ({ ...s }))].reduce(accumulateObjects, {}),
					timestamp: d.start,
					endDate: d.end
			  }))
			: $TodaysData?.activitywatch?.window?.sessions;
	// export let productivityByHour;
	let [title, metric, multiplier, color] = details;
	$: dataIndex = (24 + dayjs().hour() - 4) % 24 || productivityMetrics;
	$: category =
		(datesToAnalyse?.length > 1
			? datesToAnalyse
					.map((h, i) => [
						dayjs(typeof h == 'string' ? h.replace('Z', '') : h).add(4, 'hour'),
						...(i === datesToAnalyse?.length - 1
							? [
									dayjs(typeof h == 'string' ? h.replace('Z', '') : h)
										.endOf('day')
										.add(4, 'hour')
							  ]
							: [])
					])
					.flat()
			: $TodaysData?.accumulated?.traction?.today?.map(([start, _]) => dayjs(start))) || [];

	$: if (
		title === 'productivity' &&
		$TodaysData?.accumulated?.[metric]?.['today']?.[dataIndex]?.[1]
	) {
		$TodaysData.accumulated[metric]['today'][dataIndex][1] =
			($TodaysData?.activitywatch?.productivityLastHour &&
				$TodaysData?.activitywatch?.productivityLastHour[metric]) ||
			false;
		$TodaysData.accumulated[metric]['today'][dataIndex + 1][1] =
			($TodaysData?.activitywatch?.productivityLastHour &&
				$TodaysData?.activitywatch?.productivityLastHour[metric]) ||
			false;
	}
	// $: if (
	// 	title === 'time worked' &&
	// 	// $TodaysData?.accumulated?.[title]?.['today']?.[dataIndex]?.[1] &&
	// 	$TodaysData?.accumulated?.[metric]?.['today'][dataIndex][1] !==
	// 		$TodaysData?.activitywatch?.productivityLastHour[metric]
	// ) {
	// 	$TodaysData.accumulated[metric]['today'][dataIndex][1] =
	// 		$TodaysData?.activitywatch?.productivityLastHour &&
	// 		$TodaysData?.activitywatch?.productivityLastHour[metric];
	// 	$TodaysData.accumulated[metric]['today'][dataIndex + 1][1] =
	// 		$TodaysData?.activitywatch?.productivityLastHour &&
	// 		$TodaysData?.activitywatch?.productivityLastHour[metric];
	// }

	$: earliestSessionStart = dayjs(
		sessions?.[0]?.timestamp || sessions?.[0]?.options?.start || dayjs()
	);
	$: latestSessionEnd = dayjs(
		sessions?.slice(-1)[0]?.endDate || sessions?.[0]?.options?.end || dayjs()
	);
	$: sessionsAccumulated =
		sessions
			?.sort(
				(a, b) => dayjs(a.timestamp || a.options?.start) - dayjs(b.timestamp || b.options?.start)
			)
			?.map((s, i, a) => {
				let initialDuration =
					a
						?.slice(0, i)
						.map((s) => s?.contexts)
						.flat()
						.filter((a) => a?.[title === 'time worked' ? 'duration' : 'deepWork'])
						.reduce(
							(a, b) => a + (b?.[title === 'time worked' ? 'duration' : 'deepWork'] || 0),
							0
						) || 0;
				return [
					[dayjs(s.timestamp || s.options?.start).format(), initialDuration],
					[
						dayjs(s.endDate || s.options?.end).format(),
						initialDuration +
							(s?.contexts
								?.filter((a) => a?.[title === 'time worked' ? 'duration' : 'deepWork'])
								.reduce((a, b) => a + b?.[title === 'time worked' ? 'duration' : 'deepWork'], 0) ||
								0)
					]
				];
			})
			.flat() || [];
	$: graphEntries = [
		...((!datesScreenTime?.length > 1 &&
			$TodaysData?.accumulated?.[metric]?.today.filter(
				([key]) => dayjs(key) < earliestSessionStart.startOf('hour')
			)) ||
			[]),
		...sessionsAccumulated,
		...($DateRange.daysInPast
			? (!datesScreenTime?.length > 1 &&
					$TodaysData?.accumulated?.[metric]?.today
						.filter(([key, v]) => dayjs(key) > latestSessionEnd.endOf('hour'))
						.map(([k, v]) => [k, sessionsAccumulated?.slice(-1)[0]?.[1]])) ||
			  []
			: [])
	];
	// $: console.log({ sessions, graphEntries, latestSessionEnd, sessionsAccumulated });
	$: todayGraphData = Object.fromEntries(graphEntries) || {};
	$: metrics = {
		today:
			title === 'time worked'
				? Object.values(todayGraphData || {})?.slice(-1)[0] || 0
				: $TodaysData?.accumulated &&
				  $TodaysData?.accumulated[metric] &&
				  $TodaysData?.accumulated[metric]['today'] &&
				  $TodaysData?.accumulated[metric]['today'][dataIndex] &&
				  typeof $TodaysData?.accumulated[metric]['today'][dataIndex][1] === 'number' &&
				  $TodaysData?.accumulated[metric]['today'][dataIndex][1],
		average:
			$TodaysData?.accumulated &&
			$TodaysData?.accumulated[metric] &&
			$TodaysData?.accumulated[metric]['average'] &&
			(!$DateRange.daysInPast
				? $TodaysData?.accumulated[metric]['average'][dataIndex] &&
				  typeof $TodaysData?.accumulated[metric]['average'][dataIndex][1] === 'number' &&
				  $TodaysData?.accumulated[metric]['average'][dataIndex][1]
				: $TodaysData?.accumulated[metric]['average'].slice(-1)?.[0]?.[1] *
				  (datesToAnalyse?.length || 1))
	};
	// $: console.log(
	// 	{ todayGraphData, latestSessionEnd, sessionsAccumulated },
	// 	sessions
	// );
	// $: console.log({ metrics }, 10 * metrics['today'] * multiplier);
	// $: console.log($TodaysData?.accumulated?.[metric]);
	// $: console.log({ dataIndex, divName, metrics });
	let tooltips = {
		traction: `Traction is a score we calculate to estimate how much productive work you've done. You can get up to 10 traction points per hour of work, based on your productivity score for that hour.`,
		productivity:
			'Your productivity score is calculated from three things: the % of time you spent working, how often you are context-switching, and how long your sessions are.'
	};
	let tooltip;
	$: data = [
		datesToAnalyse?.length > 1
			? Object.fromEntries(
					datesToAnalyse
						?.map((d, i) => [
							[
								dayjs(d).add(4, 'hour').format(),
								$TodaysData?.accumulated?.[metric]?.average.slice(-1)[0]?.[1] * i
							],
							...(i === datesToAnalyse.length - 1
								? [
										[
											dayjs(d).endOf('day').add(4, 'hour').format(),
											$TodaysData?.accumulated?.[metric]?.average.slice(-1)[0]?.[1] * (i + 1)
										]
								  ]
								: [])
						])
						.flat()
			  )
			: Object.fromEntries($TodaysData?.accumulated?.[metric]?.average || []),
		title === 'time worked' || title === 'deep work'
			? todayGraphData
			: Object.fromEntries($TodaysData?.accumulated?.[metric]?.today || [])
	];
	// $: console.log({ data });
</script>

<!-- //   //				style="grid-template-columns: calc(50% - 0.4rem) calc(50% - 0.4rem) !important;" -->
<div
	id="comparisonGraph{title}"
	on:mouseover={() => (tooltip = title)}
	on:mouseleave={() => (tooltip = null)}
	style="min-height: 25%;height:{datesScreenTime?.length > 1
		? '100%'
		: '220px'};background: linear-gradient(153.72deg, rgba(7, 7, 10, 0.1) 34.22%, rgba(7, 8, 18, 0.1) 39.02%, {color +
		'08'} 58.26%, {color + '12'} 73%, {color + '16'} 92.24%, {color + '20'} 95.76%);"
	class="flex gap-2 pt-1 pb-0 text-left bg-opacity-10 relative w-full flex-col items-stretch max-w-full mx-auto rounded-2xl"
>
	<!-- <div class="px-4 text-left uppercase text-xs flex gap-1 w-fit">
		<Icon size={[1.3, 1.1]} attribute={title} includeText={true} {color} />

	</div> -->
	{#if tooltip === title && tooltips[title]}
		<div
			in:fly={{ duration: 500, delay: 500, y: -10, opacity: 0 }}
			out:fly={{ duration: 200, delay: 0, y: -10, opacity: 0 }}
			class=" z-50 pointer-events-none absolute w-full left-0 font-medium top-full rounded-lg"
			style="background:linear-gradient(153.72deg, #070822 9.02%, #080F34 49.16%, #0A196A 88.26%)
	;"
		>
			<div class="px-2 py-1 text-blue-200 text-xs text-left" style="white-space: break-spaces;">
				{tooltips[title]}
			</div>
		</div>
	{/if}
	<div class="px-4 flex relative gap-4 -mb-8">
		{#key $TodaysData?.accumulated}
			{#each ['today', 'average'] as period, i}
				<div
					class="flex mt-3 {i ? 'flex-row-reverse justify-start' : ''} gap-2 w-1/2"
					style={period === 'today' ? `` : 'filter:grayscale(0.7);color:#ccc'}
				>
					<div
						class="flex justify-center items-center gap-2 rounded-full py-0.5 px-2"
						style="height:2.5rem;width:6rem;background: {color + '11'};
						box-shadow: inset 0px 0px 15px 3px {color + '22'};
						border-radius: 0.75rem;"
					>
						<div class="font-bold text-lg opacity-95">
							{#if metric === 'deepWork' || metric === 'totalSessionTime'}
								{#each makeDecimalIntoTime((metrics[period] || 0) / 3600) || ['--', '--'] as time, i}
									{time || 0}<span class="text-base">{i ? 'm' : 'h '}</span>
								{/each}
							{:else}
								{typeof metrics[period] === 'number'
									? (metrics[period] * multiplier).toFixed(title === 'productivity' ? 0 : 1)
									: '--'}
							{/if}
						</div>
					</div>
					<div
						class="flex {i ? 'flex-col-reverse' : 'flex-col'}  justify-center items-stretch"
						style="color:{!i
							? color ||
							  `${
									metrics[period] > 0.66
										? '#00ff33'
										: `${metrics[period] > 0.33 ? '#ccff66' : '#ff5050'}`
							  }`
							: color}"
					>
						<!-- <div class="h-2 w-2 rounded-full" style="background:currentColor" /> -->
						<div
							on:click={() => {
								[title, metric, multiplier, color] =
									title === 'time worked'
										? ['deep work', 'deepWork', 1 / 3600, '#387FFF']
										: details;
							}}
							class="flex gap-2 text-xs font-semibold items-center uppercase"
						>
							{title}
						</div>
						<div class="flex gap-2 text-xs font-semibold items-center uppercase">
							{period === 'today' && datesToAnalyse?.length > 1
								? 'this week'
								: title === 'productivity' && period === 'today'
								  ? 'now'
								  : period}
						</div>
					</div>
				</div>
			{/each}
		{/key}
		<p class="px-4 text-xs absolute centreInContext text-center font-medium mb-0 mt-2">
			Today, you're achieving {metrics.today > metrics.average ? 'more' : 'less'}
			{title} than you usually do.
		</p>
	</div>
	<div
		class="flex flex-grow relative flex-col items-stretch rounded-2xl overflow-hidden"
		style="transform: translate3d(0,0,0);"
	>
		<AreaLineGraph
			options={{
				timePeriod: 'hour',
				type: 'time',
				area: false,
				valueFormatter: (value) =>
					metric === 'deepWork' || metric === 'totalSessionTime'
						? makeDecimalIntoTime(value * multiplier, 'durationNoZero')
						: Math.round(10 * value * multiplier) / 10 || '-',
				tooltip: true,
				minHeight: '7rem',
				divName: 'timeComparisonLine' + divName,
				names: ['Average', 'Today'],
				hideLegend: true,
				colors: [
					color + '33',
					color ||
						`${
							(productivityMetrics || $TodaysData?.activitywatch?.productivityMetrics || {})[
								metric
							] > 0.66
								? '#00ff33'
								: `${
										(productivityMetrics || $TodaysData?.activitywatch?.productivityMetrics || {})[
											metric
										] > 0.33
											? '#ccff66'
											: '#ff5050'
								  }`
						}`
				],
				data,
				category,
				name: 'TimeSparkLine',
				showAxis: title === 'productivity',
				xAxisInGraph: false,
				markArea: false,
				highlightAll: true,
				minMax: { min: 0, max: undefined }
			}}
		/>
	</div>
</div>

<style>
	/* div:hover > .hidden {
		display: block;
	} */
	/* div:hover > .opacity-0 {
		opacity: 1;
	} */
</style>
