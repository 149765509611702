<script>
	import {
		DateRange,
		TodaysData,
		Data,
		UserInfo,
		IsNative,
		Promises,
		ShowDetailsFor,
		Platform,
		UserEmail,
		IsDev,
		Path,
		Version,
		RenderDelayedContent,
		LiveConnections
	} from '$lib/store.js';
	import dayjs from 'dayjs';
	import HorizontalBar from './chartFunctions/HorizontalBar.svelte';
	import Sankey from './chartFunctions/Sankey.svelte';
	import Activity from '../Activity.svelte';
	import ActivityLists from './ActivityLists.svelte';
	import TimeAxis from '../TimeAxis.svelte';
	import Modal from '../Modal.svelte';
	import SharePeriod from './SharePeriod.svelte';

	import FlowOrb from '../FlowOrb.svelte';
	import Icon from '../Icon.svelte';
	import ActivityDetails from '../ActivityDetails.svelte';
	import QuickRecategorize from '../QuickRecategorize.svelte';
	import Recategorize from '../Recategorize.svelte';
	import BreakDown from './BreakDown.svelte';
	import TopLevelMetrics from './TopLevelMetrics.svelte';
	import MetricGraphs from './MetricGraphs.svelte';

	import ComparisonGraph from './ComparisonGraph.svelte';
	import TimeDetails from './TimeDetails.svelte';
	import AreaLineGraph from './chartFunctions/AreaLineGraph.svelte';
	import BarLineCombo from './chartFunctions/BarLineCombo.svelte';
	import CategoryPie from './chartFunctions/CategoryPie.svelte';
	import {
		aggregateAndSort,
		getTimeSeries,
		uploadTimeSeries,
		checkLocal,
		postUserInfo,
		sleep,
		delay
	} from '$lib/utils.js';
	import { bucketEventsByTimePeriod, accumulateObjects, between } from '$lib/dataProcessing.js';
	import {
		dataGetters,
		makeDecimalIntoTime,
		mergeEventsCloserThan,
		query
	} from '$lib/connectUtils.js';
	import { onMount } from 'svelte';
	import { goto } from '$app/navigation';
	export let show = ['productivityMetrics', 'activities', 'sessionOrbs'];
	export let divName = 'Main';
	export let todaysDayjs = dayjs().subtract(4, 'hour');
	export let todaysDate = todaysDayjs.format('YYYY-MM-DD');
	export let small = false;
	export let showTimeSmall = false;
	export let summary = false;
	export let since = false;
	export let numberOfMinutes = 25;
	export let datesToAnalyse = undefined;
	export let showImpacts = true;

	// export let dateRange = {
	// 	start: dayjs().subtract(6, 'day').startOf('day'),
	// 	end: dayjs().endOf('day')
	// };

	let graphName = 'timeTracked';
	// export let latestIsWork = true;
	let browsers = [
		'Google Chrome',
		'Safari',
		'Brave',
		'Gener8',
		'sigma',
		'sidekick',
		'firefox',
		'opera',
		'vivaldi',
		'mighty',
		'edge',
		'internet explorer',
		'browser',
		'arc'
	];

	// $: console?.log('screenTime', screenTime?.lastUpdated);

	$: productivityByHour =
		$TodaysData?.activitywatch && $TodaysData?.activitywatch?.productivityByHour;
	let datesScreenTime, prevDatesToAnalyse;
	let contexts, sessions, deepContexts, shallowContexts, unproductiveContexts;
	export let total_time;
	let events;

	$: datesAvailable = datesToAnalyse
		?.map((date, i) => !!$Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[date])
		.filter((a) => a);
	let debounce;
	function getOrQueryScreenTime(date, i) {
		let data = $Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[date];
		if (
			((!data && !$Promises[date]) || dayjs(data?.start).format().slice(0, 10) !== date) &&
			$RenderDelayedContent &&
			dayjs() > dayjs(date)
		) {
			if ($Promises[date] || dayjs(date) > dayjs())
				return data
					? { ...data, date }
					: $Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[date];
			$Promises[date] = true;
			let random = i * 3000 + Math.random() * 1000 + 500;
			console.log(
				'will query for ',
				date,
				' after ',
				random,
				!!data,
				dayjs(data?.start).format().slice(0, 10),
				date
			);
			setTimeout(async () => {
				console.log('querying for ', date, ' after ', random);

				(await dataGetters?.['screenTime']?.(
					dayjs().subtract(4, 'hour').diff(dayjs(date), 'day'),
					false,
					true
				)) || [];
			}, random);
		}
		return $Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[date]
			? { ...$Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[date], date }
			: $Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[date];
	}
	let lastUpdated;
	let rawContexts;
	// $: console.log('dateRange', $DateRange);
	// $: console.log('dates', datesToAnalyse);
	// let data = Object.entries($Data).map(([k, v]) => [k, JSON.stringify(v)?.length]);
	// $: {
	// 	console.log(
	// 		'data',
	// 		Object.entries($Data)
	// 			.map(([k, v]) => [k, JSON.stringify(v)?.length])
	// 			.filter((a, i) => a[1])
	// 	);
	// 	data = Object.entries($Data).map(([k, v]) => [k, JSON.stringify(v)?.length]);
	// }
	$: if (
		datesToAnalyse?.length &&
		!debounce &&
		$Data[`["timeseries","time_series/activitywatch","noCache"]`]
	) {
		debounce = true;
		// 	debounce = setTimeout(
		// 		async () => {
		let extractedContexts = datesScreenTime?.[0]?.window?.sessions?.map((s) => s.contexts).flat();
		if (
			($DateRange.daysInPast === 0 &&
				dayjs(datesScreenTime?.[0]?.start || $TodaysData?.activitywatch?.start).format(
					'YYYY-MM-DD'
				) !== datesToAnalyse?.[0]) ||
			!datesScreenTime?.length ||
			prevDatesToAnalyse?.length !== datesToAnalyse?.length || // check if the number of dates has changed
			prevDatesToAnalyse?.[0] !== datesToAnalyse?.[0] || //check if the first date of dates has changed
			datesAvailable?.length > datesScreenTime?.length || // check if there are more dates available than datesScreenTime
			Date.now() - lastUpdated > 1000 * 15 || // check if it has been more than 15 seconds since last update
			(datesScreenTime?.length <= 1 &&
				(extractedContexts?.length !== rawContexts?.length ||
					extractedContexts?.[extractedContexts?.length - 1]?.duration !==
						rawContexts?.[rawContexts?.length - 1]?.duration ||
					datesScreenTime?.[0]?.window?.events?.length !== events?.length ||
					datesScreenTime?.[0]?.window?.events?.[datesScreenTime?.[0]?.window?.events?.length - 1]
						?.duration !== events?.[events?.length - 1]?.duration)) ||
			Math.round(total_time?.productive - (total_time?.deep + total_time?.shallow)) ||
			Math.round(total_time?.total - (total_time?.productive + total_time?.unproductive))
		) {
			console.log(
				"dateIsn'tRight",
				$DateRange.daysInPast === 0 &&
					dayjs(datesScreenTime?.[0]?.start || $TodaysData?.activitywatch?.start).format(
						'YYYY-MM-DD'
					) !== datesToAnalyse?.[0],
				'NoScreentime:',
				!datesScreenTime?.length,
				'newDates:',
				prevDatesToAnalyse?.length !== datesToAnalyse?.length,
				'diffDates:',
				prevDatesToAnalyse?.[0] !== datesToAnalyse?.[0],
				'newAvailable:',
				datesAvailable?.length > datesScreenTime?.length,
				'over 15s:',
				Date.now() - lastUpdated > 1000 * 15,
				'(1day && (newContexts || durationDiff || newEvents || eventDurationdiff)):',
				datesScreenTime?.length <= 1 &&
					(extractedContexts?.length !== rawContexts?.length ||
						extractedContexts?.[extractedContexts?.length - 1]?.duration !==
							rawContexts?.[rawContexts?.length - 1]?.duration ||
						datesScreenTime?.[0]?.window?.events?.length !== events?.length ||
						datesScreenTime?.[0]?.window?.events?.[datesScreenTime?.[0]?.window?.events?.length - 1]
							?.duration !== events?.[events?.length - 1]?.duration),
				'(newContexts:',
				datesScreenTime?.length <= 1 &&
					datesScreenTime?.[0]?.window?.sessions?.map((s) => s.contexts).flat()?.length !==
						rawContexts?.length,
				'contexts',
				datesScreenTime?.[0]?.window?.sessions?.map((s) => s.contexts).flat()?.length,
				'rawContexts',
				rawContexts?.length,
				'(durationDiff):',
				extractedContexts?.[extractedContexts?.length - 1]?.duration !==
					rawContexts?.[rawContexts?.length - 1]?.duration,
				extractedContexts?.[extractedContexts?.length - 1]?.duration,
				rawContexts?.[rawContexts?.length - 1]?.duration,
				'((newEvents || eventDurationdiff)):',
				datesScreenTime?.length <= 1 &&
					(datesScreenTime?.[0]?.window?.events?.length !== events?.length ||
						datesScreenTime?.[0]?.window?.events?.[datesScreenTime?.[0]?.window?.events?.length - 1]
							?.duration !== events?.[events?.length - 1]?.duration),
				'productive !== deep + shallow:',
				total_time?.productive !== total_time?.deep + total_time?.shallow,
				'total !== productive + unproductive:',
				total_time?.total !== total_time?.productive + total_time?.unproductive
			);

			if (
				(datesToAnalyse.length && prevDatesToAnalyse?.length !== datesToAnalyse?.length) ||
				prevDatesToAnalyse?.[0] !== datesToAnalyse?.[0]
			)
				console.log('now analysing ', datesToAnalyse, ' instead of ', prevDatesToAnalyse, {
					datesScreenTime
				});
			lastUpdated = Date.now();
			prevDatesToAnalyse = datesToAnalyse;
			console.log('updating', divName, datesToAnalyse);
			datesScreenTime =
				$Data[`["timeseries","time_series/activitywatch","noCache"]`] &&
				(
					datesToAnalyse ||
					Array(7)
						.fill()
						.map((_, i) =>
							dayjs()
								.subtract(6 - i, 'day')
								.subtract(4, 'hour')
								.format('YYYY-MM-DD')
						)
				)
					?.map(getOrQueryScreenTime)
					.filter((a) => a);
			// if (divName === 'Main')
			// 	console.log({
			// 		datesScreenTime,
			// 		divName,
			// 		datesToAnalyse,
			// 		data: $Data?.[`["timeseries","time_series/activitywatch","noCache"]`]
			// 	});
			events = datesScreenTime
				?.map((dateScreenTime) => (dateScreenTime?.window || dateScreenTime).events || [])
				.flat();
			sessions = datesScreenTime
				?.map((dateScreenTime) => dateScreenTime?.window?.sessions)
				.flat()
				.filter((a) => a && a.duration > 5);
			rawContexts = sessions?.map((s) => s.contexts).flat();

			contexts = [
				...(rawContexts || []).filter(
					(context) =>
						context &&
						($DateRange.daysInPast !== 0 ||
							!$TodaysData?.calendarEvents?.length ||
							!$TodaysData?.calendarEvents?.some((e) => {
								let startsDuringCalendarEvent = between(
									context.timestamp,
									e.start.dateTime,
									e.end.dateTime
								);
								let endsDuringCalendarEvent = between(
									context.endDate,
									e.start.dateTime,
									e.end.dateTime
								);
								if (startsDuringCalendarEvent && endsDuringCalendarEvent) {
									return true;
								} else if (startsDuringCalendarEvent) {
									context.timestamp = e.end.dateTime;
									context.duration = dayjs(context.endDate).diff(context.timestamp, 'second');
									return false;
								} else if (endsDuringCalendarEvent) {
									context.endDate = e.start.dateTime;
									context.duration = dayjs(context.endDate).diff(context.timestamp, 'second');
									return false;
								} else {
									return false;
								}
							}))
				),
				...($DateRange.daysInPast === 0 ? $TodaysData?.calendarEvents || [] : [])
			].sort(
				(a, b) =>
					dayjs(typeof a.timestamp == 'string' ? a.timestamp : a.timestamp) -
					dayjs(typeof b.timestamp == 'string' ? b.timestamp : b.timestamp)
			);
			// if ($TodaysData?.calendarEvents) console.log(contexts, $TodaysData?.calendarEvents);

			deepContexts = aggregateAndSort(
				contexts?.filter((a, i) => a?.focus && (a.duration > 300 || !i)),
				'byCategory'
			);
			shallowContexts = aggregateAndSort(
				contexts?.filter((a, i) => a?.work && !(a.focus && (a.duration > 300 || !i))),
				'byCategory'
			);
			unproductiveContexts = aggregateAndSort(
				contexts?.filter((a, i) => !a?.work),
				'byCategory'
			);

			total_time = {
				deep: deepContexts?.reduce((a, b) => a + (b?.[1]?.duration || 0), 0),
				shallow: shallowContexts?.map((a) => a[1])?.reduce((a, b) => a + (b?.duration || 0), 0),
				unproductive: unproductiveContexts
					?.map((a) => a[1])
					?.reduce((a, b) => a + (b?.duration || 0), 0),
				productive: contexts?.filter((e) => e?.work)?.reduce((a, b) => a + (b?.duration || 0), 0),
				total: contexts?.reduce((a, b) => a + (b?.duration || 0), 0)
			};

			console.log('done updating', divName, datesToAnalyse, total_time);
			// 	},
			// 	prevDatesToAnalyse?.length !== datesToAnalyse?.length
			// 		? 100
			// 		: datesToAnalyse?.length > 14
			// 		? 1000
			// 		: datesToAnalyse?.length > 3
			// 		? 300
			// 		: 0
			// );
			// }
			// console.log({ total_time }, $DateRange.daysInPast);
		}
		setTimeout(() => (debounce = null), 300);
	}
	function asyncDebounce() {}
	// $: console?.log({ datesScreenTime });
	async function updateDatesScreenTime() {}
	$: if (since) {
		events = $Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[
			since?.subtract(4, 'hour').format('YYYY-MM-DD')
		]?.window?.events
			?.filter(
				(event) =>
					event &&
					dayjs(event?.endDate) >= since &&
					dayjs(event?.timestamp) <= since?.add(numberOfMinutes, 'minute')
			)
			.map((a) => {
				if (
					a.duration &&
					Math.round(
						(a.duration /
							dayjs(a.endDate).diff(
								dayjs(typeof a.timestamp == 'string' ? a.timestamp : a.timestamp),
								'second',
								true
							)) *
							10
					) !== 10
				) {
					// console.log(a.duration, dayjs(a.endDate).diff(dayjs(typeof a.timestamp == "string" ? a.timestamp : a.timestamp), 'second', true), a);
					if (
						a.duration * 10 <
							dayjs(a.endDate).diff(
								dayjs(typeof a.timestamp == 'string' ? a.timestamp : a.timestamp)
							) &&
						dayjs(a.endDate).diff(
							dayjs(typeof a.timestamp == 'string' ? a.timestamp : a.timestamp) > 3600
						)
					) {
						return a;
					}
				}
				return {
					...(a || []),
					duration: dayjs(a.endDate).diff(
						dayjs(typeof a.timestamp == 'string' ? a.timestamp : a.timestamp),
						'second',
						true
					)
				};
			});
	}
	// $: console.log({ divName, datesToAnalyse, productivityMetrics });

	$: productivityLastHour = (
		since
			? $Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[
					since?.subtract(4, 'hour').format('YYYY-MM-DD')
			  ]
			: $TodaysData?.activitywatch
	)?.productivityLastHour;
	$: productivityMetrics = !since
		? datesScreenTime
				?.map((d, i, a) => ({
					...d?.productivityMetrics
				}))
				.reduce(accumulateObjects, {}) || $TodaysData.activitywatch?.productivityMetrics
		: summary
		  ? [
					...((since
						? $Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[
								since?.subtract(4, 'hour').format('YYYY-MM-DD')
						  ]
						: $TodaysData?.activitywatch
					)?.window?.sessions || [])
		    ]?.sort(
					(sessionA, sessionB) =>
						Math.abs(since - dayjs(sessionA.timestamp)) -
						Math.abs(since - dayjs(sessionB.timestamp))
		    )?.[0] || productivityLastHour
		  : productivityLastHour;

	$: if (since) {
		contexts = (productivityMetrics?.contexts || []).filter(
			(a, i) =>
				dayjs(a?.endDate) > since && dayjs(a?.timestamp) < since?.add(numberOfMinutes, 'minute')
		);
		// console.log({ contexts });
		total_time = {
			productive: events?.filter((e) => e?.work)?.reduce((a, b) => a + (b?.duration || 0), 0),
			unproductive: events?.filter((e) => !e?.work)?.reduce((a, b) => a + (b?.duration || 0), 0),
			total: events?.reduce((a, b) => a + (b?.duration || 0), 0),
			deep: contexts
				?.filter((a, i) => a?.focus && a.duration > 300)
				?.reduce((a, b) => a + (b?.duration || 0), 0),
			shallow: contexts
				?.filter((a, i) => a?.work && !(a.focus && (a.duration > 300 || !i)))
				?.reduce((a, b) => a + (b?.duration || 0), 0)
		};
		// console.log({ contexts, total_time });
	}
	$: if (!datesToAnalyse && !since)
		total_time = $TodaysData?.total_time && {
			...($TodaysData?.total_time || {}),
			total: $TodaysData?.total_time?.productive + $TodaysData?.total_time?.unproductive || 0
		};

	// $: console?.log({ since });

	// export let eventsIn10mins;
	// $: eventsIn10mins = {
	// 	productive: bucketEventsByTimePeriod($TodaysData?.productiveEvents, {
	// 		timePeriod: 'minute',
	// 		quantityOfPeriod: 10,
	// 		splitSpanningEvents: true
	// 	}),
	// 	unproductive: bucketEventsByTimePeriod($TodaysData?.unproductiveEvents, {
	// 		timePeriod: 'minute',
	// 		quantityOfPeriod: 10,
	// 		splitSpanningEvents: true
	// 	})
	// };
	let activityFilter = '';

	let dayInHours = (daysInPast) =>
		Array(25)
			.fill()
			.map((a, i) =>
				!daysInPast && (24 + dayjs().hour() - 4) % 24 === i - 1
					? dayjs()
					: dayjs()
							.subtract(4, 'hour')
							.subtract(daysInPast, 'day')
							.startOf('day')
							.hour(4)
							.startOf('hour')
							.add(i, 'hour')
			);

	let todayInHours = dayInHours($DateRange.daysInPast);

	let nowInMinutes;
	if (since)
		nowInMinutes = Array(
			(typeof numberOfMinutes === 'number' && numberOfMinutes > 0 && Math.round(numberOfMinutes)) ||
				25
		)
			.fill()
			.map((a, i) => dayjs(since).startOf('minute').add(i, 'minute'));
	let proposedIndex = (since ? nowInMinutes : todayInHours)?.findIndex(
		(time) => dayjs().subtract($DateRange?.daysInPast, 'day').subtract(5, 'minute') < time
	);
	let dataIndex =
		proposedIndex !== -1 ? proposedIndex : (since ? nowInMinutes : todayInHours).length - 1;
	$: todayInHours = dayInHours($DateRange.daysInPast) || todaysDate || updateOnInterval;

	$: if (since)
		nowInMinutes = Array(
			(typeof numberOfMinutes === 'number' && numberOfMinutes > 0 && Math.round(numberOfMinutes)) ||
				25
		)
			.fill()
			.map((a, i) => dayjs(since).startOf('minute').add(i, 'minute'));
	$: proposedIndex =
		(since ? nowInMinutes : todayInHours)?.findIndex(
			(time) => dayjs().subtract($DateRange?.daysInPast, 'day').subtract(5, 'minute') < time
		) || (since ? nowInMinutes : todayInHours).length - 1;
	$: if (dataIndex < proposedIndex && proposedIndex !== -1) dataIndex = proposedIndex;
	let updateOnInterval = Math.random();
	onMount(() => {
		setTimeout(() => {
			let proposedIndex = (since ? nowInMinutes : todayInHours)?.findIndex(
				(time) => dayjs().subtract($DateRange?.daysInPast, 'day').subtract(5, 'minute') < time
			);
			dataIndex =
				proposedIndex !== -1
					? proposedIndex
					: dataIndex || (since ? nowInMinutes : todayInHours).length - 1;
		}, 5000);
		setInterval(() => {
			updateOnInterval = Math.random();
			proposedIndex = (since ? nowInMinutes : todayInHours)?.findIndex(
				(time) => dayjs().subtract($DateRange?.daysInPast, 'day').subtract(5, 'minute') < time
			);
			dataIndex =
				proposedIndex !== -1
					? proposedIndex
					: dataIndex || (since ? nowInMinutes : todayInHours).length - 1;
		}, 50000);
		return () => console.log('destroying timetracked' + divName);
	});

	let showiOSModal;

	// let sleepFill;
	// $: if (wakeup && !since)
	// 	sleepFill = Object?.fromEntries(
	// 		todayInHours?.map((hour) => [
	// 			hour?.format('HH'),
	// 			[
	// 				{
	// 					duration:
	// 						dayjs(wakeup).subtract(2, 'hour').hour() > hour?.hour() && hour?.hour() > 3 ? 900 : 0
	// 				}
	// 			]
	// 		])
	// 	);

	let activityDetails = false;
	let showShare;

	// $: console.log({ productivityMetrics, divName, since });
	let workTypes = ['deep', 'shallow', 'unproductive'];

	$: switchesSorted = Object.entries(productivityMetrics?.switches || {}).sort(
		(a, b) => b[1] - a[1]
	);
	// $: console.log({ switchesSorted, productivityMetrics });
	// $: console.log({ $ShowDetailsFor });
	// $: console.log({ $TodaysData });
	let seeAll;
	let showActivity;
	// $: if (!!datesToAnalyse) console.log(productivityMetrics?.contexts);
	let avgContextLengthByCategory;
	$: if (divName === 'Insights')
		avgContextLengthByCategory = aggregateAndSort(
			mergeEventsCloserThan(
				120,
				productivityMetrics?.contexts?.filter((c) => c.duration < 3600 * 5) || [],
				'onlyMergeIfSameCategory'
			),
			true,
			true
		);
	$: categories = aggregateAndSort(
		events || [
			...($TodaysData?.productiveEvents || []),
			...($TodaysData?.unproductiveEvents || [])
		],
		'byCategory'
	);
	// $: console.log({ avgContextLengthByCategory, since: !!since, dates: !!datesToAnalyse });
	let seeAllContexts;
	let seeAllCategories;
	let recategorize = false && $IsDev && divName === 'Main';
	$: activities =
		aggregateAndSort(
			datesToAnalyse || since
				? events
				: [...($TodaysData?.productiveEvents || []), ...($TodaysData?.unproductiveEvents || [])]
		) || Array(5).fill(['', '']);
	$: bigMetrics = [
		[
			makeDecimalIntoTime(
				(Math.max(total_time?.total || 0, $TodaysData?.total_time?.total || 0) ||
					(total_time?.productive || 0) + (total_time?.unproductive || 0)) / 3600,
				'durationNoZero'
			),
			'Total time',
			[
				makeDecimalIntoTime(
					Math.max(total_time?.deep || 0, $TodaysData?.total_time?.deep || 0) / 3600,
					'durationNoZero'
				),
				'Deep Work'
			]
		],
		[Math.round(100 * productivityMetrics?.productivityScore), 'Productivity'],
		[Math.round(productivityMetrics?.contextSwitches), 'Context Switches'],
		[
			makeDecimalIntoTime(productivityMetrics?.sessionLength / 3600, 'durationNoZero'),
			'Session Length'
		]
	];
	let recategoriseToShow = {};
</script>

<svelte:window
	on:keydown={(e) => (e.key === 'Escape' ? (seeAll = false) && e.stopPropagation() : '')}
/>
{#if $UserEmail}
	{#if !$UserInfo?.syncActivityWatchEnabled && !$Path.includes('session')}
		<div class="h-64">
			<button
				on:click|stopPropagation={async () => {
					if ($Platform === 'macos') goto('/onboard/track');
					else {
						$UserInfo.syncActivityWatchEnabled = true;
						await postUserInfo();
						await window.api?.call('authenticated', [$UserEmail, $IsDev]);
						if (!$IsDev)
							window.analytics?.track?.('Connected Source', {
								source: 'activitywatch',
								platform: $Platform,
								environment: $IsDev ? 'dev' : 'production',
								email: $UserEmail
							});
						window.location.reload();
						setTimeout(async () => {
							query('screentime')(0, 'refresh');
							$DateRange.daysInPast = $DateRange.daysInPast;
						}, 10000);
					}
				}}>Start tracking screentime</button
			>
		</div>
	{:else}
		<!-- <Modal
		bind:show={$ShowDetailsFor.time}
		options={{
			fullScreen: true,
			topLevel: true
		}}
	> -->
		{#if $ShowDetailsFor.time && divName === 'Main'}
			<div class="h-full flex mt-8 flex-col justify-between items-stretch">
				<TimeDetails
					showDetailsFor={$ShowDetailsFor.time}
					bind:activityDetails
					bind:showActivity
					bind:summary
					{...{
						datesScreenTime,
						datesToAnalyse,
						aggregateAndSort,
						small,
						workTypes,
						since,
						categories,
						total_time,
						dataIndex,
						divName,
						productivityMetrics,
						productivityByHour,
						productivityLastHour,
						switchesSorted,
						browsers
					}}
				/>
			</div>
		{/if}
		<!-- </Modal> -->
		<div
			style={!($ShowDetailsFor.time && divName === 'Main')
				? ''
				: 'opacity:0;position:absolute;bottom:100%;pointer-events:none;max-width:100vw;z-index:0;'}
			class="{divName.includes('Preview')
				? ''
				: 'h-full'} flex flex-col w-full justify-between {show?.includes('bigMetrics')
				? 'items-start'
				: 'items-center'}"
		>
			{#if show?.includes('bigMetrics') && !small}
				<div class="flex pb-3 flex-col gap-3" style="width:130%;">
					{#each bigMetrics as [metric, title, secondMetric], i}
						<div
							style="padding-right:30%;"
							class="bigMetric flex justify-between items-end p-3 rounded-2xl {(!i &&
								!$ShowDetailsFor.time) ||
							$ShowDetailsFor.time === title.toLowerCase()
								? 'selected'
								: ''}"
							on:click={() => {
								if (i === 0) {
									$ShowDetailsFor.time = '';
								} else $ShowDetailsFor.time = title.toLowerCase();
							}}
						>
							<div class="metricText">
								<div class="text-3xl font-bold">
									{metric || '0'}
								</div>
								<div class="text-xl font-semibold">
									{title}
								</div>
							</div>
							{#if secondMetric}
								<div class="opacity-75 metricText">
									<div class="text-2xl font-bold">
										{secondMetric[0] || '0m'}
									</div>
									<div class="text-sm font-semibold">
										{secondMetric[1]}
									</div>
								</div>
							{/if}
						</div>
					{/each}
				</div>
			{/if}
			{#if show?.includes('categories') && !small}
				<div class="h-40 z-0">
					<CategoryPie
						divName={'ScreenTimeCategoryPie'}
						data={categories
							?.map(([cat, data]) => ({
								name: cat?.split('|')[0] || 'Uncategorized',
								value: Math?.floor(data?.duration / 36) / 100
							}))
							.slice(0, seeAllCategories ? categories.length : 6)}
					/>
				</div>
			{/if}
			{#if show?.includes('comparisons')}
				{#key show}
					<ComparisonGraph
						details={['time worked', 'totalSessionTime', 1 / 3600, '#387FFF']}
						{divName}
						{datesScreenTime}
						{datesToAnalyse}
						{sessions}
						{productivityMetrics}
					/>
				{/key}
			{/if}

			{#if show?.includes('contextLengths') && avgContextLengthByCategory?.length && !small}
				<div class="">
					<div class="text-sm font-semibold mb-2 flex justify-between">
						Your least distracted categories <div
							class="cursor-pointer"
							on:click={() => {
								seeAllContexts = !seeAllContexts;
							}}
						>
							{seeAllContexts ? 'See less' : 'See all'}
						</div>
					</div>
					{#each avgContextLengthByCategory?.slice(0, seeAllContexts ? avgContextLengthByCategory.length : 5) || [] as [cat, data]}
						<div
							class="grid text-xs text-left"
							style="grid-template-columns: 1fr 1.5fr 0.5fr !important;"
						>
							<div>{cat}</div>
							<HorizontalBar
								colors={[
									`#${(255 - Math.min(Math.floor((399 * data?.duration) / 3600) + 1, 239)).toString(
										16
									)}${(
										0xff -
										49 +
										Math.min(Math.floor((99 * data?.duration) / 3600) + 1, 49)
									).toString(16)}33`,
									'#ffffff'
								]}
								minMax={{ min: 0, max: avgContextLengthByCategory?.[0]?.[1].duration / 3600 }}
								attribute={'avg. time in context'}
								data={[[Math.round(data?.duration / 36) / 100]]}
							/>
							<div class="text-right">
								{makeDecimalIntoTime(data?.duration / 3600, 'durationNoZero')}
							</div>
						</div>
					{/each}
				</div>
			{/if}

			{#if show?.includes('breakdown')}
				{#key total_time}
					<BreakDown
						{workTypes}
						bind:showDetailsFor={$ShowDetailsFor.time}
						total_time={total_time || $TodaysData?.total_time}
					/>
				{/key}
			{/if}
			{#if show?.includes('metrics') && !small}
				<TopLevelMetrics
					{divName}
					{productivityMetrics}
					{since}
					{total_time}
					bind:showDetailsFor={$ShowDetailsFor.time}
				/>
			{/if}
			{#if show.includes('flow')}
				{#if !small}
					<TopLevelMetrics
						{divName}
						{showImpacts}
						{since}
						{total_time}
						slot={true}
						{productivityMetrics}
						bind:showDetailsFor={$ShowDetailsFor.time}
					>
						<FlowOrb
							{small}
							flowing={true}
							value={divName.includes('Activity') && !summary
								? productivityLastHour?.productivityScore
								: productivityMetrics?.productivityScore}
						/>
					</TopLevelMetrics>
				{:else}
					<FlowOrb
						{small}
						flowing={true}
						value={divName.includes('Activity') && !summary
							? productivityLastHour?.productivityScore
							: productivityMetrics?.productivityScore}
					/>
				{/if}
				<!-- <FlowBar {small} value={productivityLastHour?.productivityScore} /> -->
				{#if switchesSorted?.productivityMetrics?.[0]?.[0]?.length && !small}
					<p class="px-4 mb-2 text-xs text-center font-medium">
						Tip: Try batching up your {switchesSorted?.[0][0].split(',').join(' and ')}.
					</p>
				{/if}
			{/if}
			{#if show?.includes('workDistribution') && !small}
				<div class="text-center my-3">
					<div class="text-sm font-semibold mb-2">Distribution of hours worked per day</div>
					<BarLineCombo
						divName={'workDistribution'}
						minHeight={'112px'}
						xName={'hours worked'}
						data={{
							axis: Array(20)
								.fill()
								.map((_, i) => i),
							bar: {
								name: '# of days',
								data: Array(20)
									.fill()
									.map(
										(_, i) =>
											datesScreenTime
												?.map((date) => date?.productivityMetrics?.totalSessionTime / 3600)
												.filter((time) => i === Math.floor(time)).length
									)
							}
						}}
						colors={['#536664', '#607893']}
					/>
				</div>
			{/if}
			{#if show?.includes('tractionDays') && datesToAnalyse}
				<div class="flex justify-around w-full">
					{#each [['avg. traction', 'traction', 1 / 360 / (datesToAnalyse?.filter((d) => dayjs(d) < dayjs()
											.startOf('day')
											.subtract(1, 'minute')).length || 1), ''], ['avg. productivity', 'productivityScore', 100, '']] as [title, metric, multiplier, color], i}
						<div class="flex flex-col {small ? 'items-center' : 'items-stretch'} mb-2">
							<div class="text-center font-semibold text-2xl" style="white-space: nowrap;">
								{(productivityMetrics || $TodaysData?.activitywatch?.productivityMetrics || {}) &&
									Math?.round(
										(productivityMetrics || $TodaysData?.activitywatch?.productivityMetrics || {})[
											metric
										] * multiplier
									)}
							</div>
							<div
								class="flex gap-1 justify-center items-center uppercase w-max"
								style="white-space: nowrap;"
							>
								<Icon
									attribute={metric}
									includeText={true}
									textOverride={title}
									size={[1.25, 1.1]}
								/>
							</div>
						</div>
					{/each}
				</div>

				<div class="h-28 w-full">
					<BarLineCombo
						divName={'TractionWeekly'}
						minHeight={'112px'}
						data={{
							axis: datesToAnalyse?.map((date) => dayjs(date).format('ddd')),
							bar: {
								name: 'traction',
								data: datesToAnalyse?.map(
									(date) =>
										$Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[date] &&
										Math?.round(
											$Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[date]
												?.productivityMetrics?.traction / 360
										)
								)
							}
						}}
						colors={['#00ff33', '#00dd83']}
					/>
				</div>
			{/if}
			{#if show?.includes('productivityMetrics') && !small}
				<!-- bg-gradient-to-br from-gray-900
		via-black to-gray-900 rounded-2xl py-2 -z-10 -->
				<div class="w-full -mb-4">
					<TopLevelMetrics
						small={true}
						slot={true}
						{divName}
						{since}
						{productivityMetrics}
						total_time={$TodaysData?.total_time}
						bind:showDetailsFor={$ShowDetailsFor.time}
						{showImpacts}
					>
						<FlowOrb
							{small}
							medium={true}
							flowing={!!since}
							value={divName.includes('Activity') && !summary
								? productivityLastHour?.productivityScore
								: productivityMetrics?.productivityScore}
						/>
					</TopLevelMetrics>
				</div>
			{/if}

			{#if show?.includes('sessionOrbs')}
				<div
					class="flex h-20 gap-2 items-center max-w-full ml-auto overflow-x-scroll pt-1 -mb-1"
					style="direction:rtl;"
				>
					{#each $TodaysData?.activitywatch?.window?.sessions.filter((e, i) => e.duration >= 300 || !i) || [] as session, i}
						{#key session}
							<div
								on:click={() => {
									summary = true;
									console.log({ session, i });
									showActivity = session;
								}}
								class="flex cursor-pointer relative hover:opacity-90 hover:scale-105 hover:brightness-110 transition-all flex-col items-center text-center"
								style="min-width:45px;"
							>
								<FlowOrb small={true} flowing={false} value={session?.productivityScore} />
								<div class="text-xs text-center w-full">
									{makeDecimalIntoTime(session.duration / 3600, 'durationNoZero') || '0m'}
								</div>
							</div>
						{/key}
					{/each}
				</div>
			{/if}
			<Modal
				bind:show={showShare}
				options={{
					cssText: `background-image: url('/bg/bg-pinkblue-gradient.webp') !important;
	mix-blend-mode: normal;
	background-size: 100% 100%;width:min(100vw, 440px);padding:1.5rem 1rem 1.25rem `
				}}
			>
				{#if showShare && (total_time || $TodaysData.total_time)}
					<SharePeriod
						{...{
							divName,
							dayInHours,
							datesScreenTime,
							datesToAnalyse,
							total_time,
							todaysDayjs,
							categories,
							activities,
							events,
							since,
							nowInMinutes,
							dataIndex,
							todayInHours,
							numberOfMinutes
						}}
					/>
				{/if}
			</Modal>
			{#if show?.includes('time') && (!small || showTimeSmall)}
				<!-- on:click={() => (showTimeSmall || small ? '' : (showShare = true))} -->
				<div
					id="timeSparkLine"
					class="{showTimeSmall
						? 'relative h-5 bottom-6 z-0'
						: divName !== 'weekShowcase'
						  ? 'relative  h-24'
						  : 'top-[56%] absolute h-20'} w-full min-w-[300px] {showTimeSmall
						? 'mt-3 bg-gray-900 bg-opacity-20 rounded-lg'
						: 'my-2'} text-gray-400"
				>
					<AreaLineGraph
						options={{
							timePeriod: since ? 'minute' : datesScreenTime?.length > 1 ? 'day' : 'hour',
							type: 'time',
							hideAxisLabels: false,
							area: true,
							divName: 'timeSparkLine' + divName,
							colors: ['#00ff33', '#dd0033', '#7176ff'],
							minHeight: showTimeSmall ? '2.5rem' : divName !== 'weekShowcase' ? false : '5rem',
							data: [
								(since || datesScreenTime?.length > 1
									? bucketEventsByTimePeriod(
											(since
												? $Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[
														since?.subtract(4, 'hour').format('YYYY-MM-DD')
												  ]?.window?.events
												: events
											)?.filter(
												(event) =>
													event.work &&
													(!since ||
														(dayjs(event?.endDate) > since &&
															dayjs(event?.timestamp) < since?.add(numberOfMinutes, 'minute')))
											),
											{
												timePeriod: datesScreenTime?.length > 1 ? 'hour' : 'minute',
												quantityOfPeriod: since ? 1 : datesScreenTime?.length > 1 ? 2 : 30,
												splitSpanningEvents: true
											},
											since
									  )
									: $TodaysData?.productiveEventsBucketed) || {},
								(since || datesScreenTime?.length > 1
									? bucketEventsByTimePeriod(
											(since
												? $Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[
														since?.subtract(4, 'hour').format('YYYY-MM-DD')
												  ]?.window?.events
												: events
											)?.filter(
												(event) =>
													!event.work &&
													(!since ||
														(dayjs(event?.endDate) > since &&
															dayjs(event?.timestamp) < since?.add(numberOfMinutes, 'minute')))
											),
											{
												timePeriod: datesScreenTime?.length > 1 ? 'hour' : 'minute',
												quantityOfPeriod: since ? 1 : datesScreenTime?.length > 1 ? 2 : 30,
												splitSpanningEvents: true
											},
											since
									  )
									: $TodaysData?.unproductiveEventsBucketed) || {}
							],
							category: since
								? nowInMinutes
								: datesScreenTime?.length > 1
								  ? datesToAnalyse
											.map((date) =>
												dayInHours(dayjs().startOf('day').diff(dayjs(date), 'day')).filter(
													(a) => !(a.hour() % 4)
												)
											)
											.flat()
								  : todayInHours,
							name: 'TimeSparkLine',
							showAxis: true,
							xAxisInGraph: divName === 'Main' ? 30 : 8,
							markArea: false,
							flipIndexes: [1],
							lineOnlyIndexes: [2],
							minMax: {
								min: since ? -60 : datesScreenTime?.length > 1 ? -6000 : -2400,
								max: since ? 90 : datesScreenTime?.length > 1 ? 7600 : 2400
							}
						}}
						{dataIndex}
					/>
					<div
						class="{showTimeSmall
							? 'hidden'
							: ''} absolute -top-1 flex flex-col items-center justify-between w-full h-full pb-2"
					>
						{#each ['productive', 'unproductive'] as type, i}
							<div
								style="color:{i ? '#dd0033' : '#00ff33'}"
								class="font-semibold text-xs {i ? 'text-right' : ''}"
							>
								{#each (total_time || $TodaysData?.total_time) && (total_time || $TodaysData?.total_time)[type] !== undefined ? makeDecimalIntoTime((total_time || ($TodaysData?.total_time && $TodaysData?.total_time))[type] / 3600) : ['--', '--'] as time, i}
									{time || 0}<span>{i ? '' : 'h '}</span>
								{/each}
							</div>
						{/each}
					</div>
					{#if !$DateRange.daysInPast && !since}
						{#each $TodaysData.calendarEvents || [] as activity}
							<div
								on:pointerenter={() => (activity.show = true)}
								on:pointerleave={() => (activity.show = false)}
								class="border-blue-500 border-opacity-60 bg-blue-600 bg-opacity-10 opacity-60 hover:opacity-100 transition-opacity hover:z-50 absolute border-1 border-solid text-xs rounded-lg p-0.5 z-10"
								style="left:{dayjs(
									activity.startDate || activity.startedAt || activity.timestamp
								).diff(
									dayjs().subtract(4, 'hour').startOf('day').hour(4).startOf('hour'),
									'second'
								) /
									(24 * 36)}%;top:49%;"
							>
								<img class="h-4 w-4" alt="calendar logo" src="/logos/google_calendar_logo.svg" />
								{#if activity.show}
									<div class="bg-gray-900 h-max absolute top-full w-max rounded-lg">
										<div
											class="flex flex-col text-left h-max text-xs font-semibold justify-between w-max pl-4 p-2 text-blue-100"
										>
											{#if activity.name === 'Work'}
												{#each Array.from(new Set(activity?.contexts
															?.sort((a, b) => (b?.duration || 0) - a.duration)
															?.map((c) => c?.categories?.[0]) || ['None']))
													.filter((c) => c !== 'Untracked')
													.slice(0, 2) as category, i}
													{#if i}
														{' and '}
													{/if}
													{category}
												{/each}
											{:else}
												<div class="flex">{activity.name || 'Work session'}</div>
											{/if}
											<div class="opacity-70">
												{makeDecimalIntoTime(activity.duration / 3600, 'durationNoZero')}
											</div>
										</div>
									</div>
								{/if}
							</div>
						{/each}
					{/if}
				</div>
			{/if}
			{#if show?.includes('sankey') && !small}
				<div class="min-h-[15rem] flex-grow w-[102%] relative left-[-1%]" style="">
					{#if total_time?.total}
						{#key show}
							<Sankey
								divName={'timetracked'}
								{total_time}
								data={{
									data: [
										{
											name: 'Total time',
											itemStyle: {
												color: '#a0e0ff'
											},
											value: makeDecimalIntoTime(
												(1 / 3600) *
													Math.max(total_time?.total || 0, $TodaysData?.total_time?.total || 0),
												'durationNoZero'
											)
										},
										{
											name: 'Productive',
											itemStyle: { color: '#00ff8C' },
											value: makeDecimalIntoTime(
												(1 / 3600) *
													Math.max(total_time?.productive || 0 || 0, total_time?.deep || 0),
												'durationNoZero'
											)
										},
										{
											name: 'Unproductive',
											itemStyle: { color: '#dd0053' },
											value: makeDecimalIntoTime(
												(1 / 3600) *
													Math.max(
														total_time?.unproductive || 0,
														$TodaysData?.total_time?.unproductive || 0
													),
												'durationNoZero'
											)
										},
										{
											name: 'Deep',
											itemStyle: { color: '#0066dd' },
											value: makeDecimalIntoTime(
												(1 / 3600) *
													Math.max(total_time?.deep || 0, $TodaysData?.total_time?.deep || 0),
												'durationNoZero'
											)
										},
										{
											name: 'Shallow',
											itemStyle: { color: '#dddd33' },
											value: makeDecimalIntoTime(
												(1 / 3600) *
													Math.max(total_time?.shallow || 0, $TodaysData?.total_time?.shallow || 0),
												'durationNoZero'
											)
										},
										...[
											...(deepContexts
												?.filter(
													([cat, data]) =>
														data.duration > 180 ||
														data.duration / $TodaysData?.total_time?.deep > 0.1
												)
												.map(([cat, data], i) => ({
													itemStyle: { color: `#00${88 + i * 6}ee` },
													name: (cat?.split('|')[0] || 'Uncategorized') + ' (Deep)',
													duration: data.duration,
													value: makeDecimalIntoTime(
														Math?.floor(data?.duration / 36) / 100,
														'durationNoZero'
													)
												})) || []),
											...(shallowContexts
												?.filter(
													([cat, data]) =>
														data.duration > 180 ||
														data.duration / $TodaysData?.total_time?.shallow > 0.1
												)
												.map(([cat, data], i) => ({
													itemStyle: { color: `#dddd${33 + i * 6}` },
													name:
														(cat?.split('|')[0] || 'Uncategorized') +
														(data.focus || data.categories.includes('Untracked')
															? ' (Shallow)'
															: ''),
													duration: data.duration,
													value: makeDecimalIntoTime(
														Math?.floor(data?.duration / 36) / 100,
														'durationNoZero'
													)
												})) || [])
										]
											.sort((a, b) => b.duration - a.duration)
											.slice(0, datesToAnalyse?.length > 7 ? 9 : 6),
										...(
											unproductiveContexts
												?.filter(
													([cat, data]) =>
														!data.work &&
														data.duration > 60 &&
														data.duration / $TodaysData?.total_time?.unproductive > 0.1
												)
												.map(([cat, data], i) => ({
													itemStyle: { color: `#dd00${53 + i * 6}` },
													name: cat?.split('|')[0] || 'Uncategorized',
													value: makeDecimalIntoTime(
														Math?.floor(data?.duration / 36) / 100,
														'durationNoZero'
													)
												})) || []
										).slice(0, 4)
									],
									links: [
										{
											source: 'Total time',
											target: 'Productive',
											value:
												(1 / 3600) * Math.max(total_time?.productive || 0, total_time?.deep || 0)
										},
										{
											source: 'Productive',
											target: 'Deep',
											value:
												(1 / 3600) *
												Math.max(
													Math.max(total_time?.deep || 0, $TodaysData?.total_time?.deep || 0) || 0,
													total_time?.deep || 0
												)
										},
										{
											source: 'Productive',
											target: 'Shallow',
											value:
												(1 / 3600) *
													Math.max(
														Math.max(
															total_time?.shallow || 0,
															$TodaysData?.total_time?.shallow || 0
														) || 0,
														total_time?.shallow || 0
													) || 0
										},
										{
											source: 'Total time',
											target: 'Unproductive',
											value:
												(1 / 3600) *
													Math.max(
														Math.max(
															total_time?.unproductive || 0,
															$TodaysData?.total_time?.unproductive || 0
														) || 0,
														total_time?.unproductive || 0
													) || 0
										},
										...[
											...(deepContexts
												?.filter(
													([cat, data]) =>
														data.duration > 180 ||
														data.duration / $TodaysData?.total_time?.deep > 0.1
												)
												.map(([cat, data]) => ({
													source: 'Deep',
													target: (cat?.split('|')[0] || 'Uncategorized') + ' (Deep)',
													value: data?.duration / 3600
												})) || []),
											...(shallowContexts
												?.filter(
													([cat, data]) =>
														data.duration > 180 ||
														data.duration / $TodaysData?.total_time?.shallow > 0.1
												)
												.map(([cat, data]) => ({
													source: 'Shallow',
													target:
														(cat?.split('|')[0] || 'Uncategorized') +
														(data.focus || data.categories.includes('Untracked')
															? ' (Shallow)'
															: ''),
													value: data?.duration / 3600
												})) || [])
										]
											.sort((a, b) => b.value - a.value)
											.slice(0, datesToAnalyse?.length > 7 ? 9 : 6),
										...(
											unproductiveContexts
												?.filter(
													([cat, data]) =>
														!data.work &&
														(data.duration > 180 ||
															data.duration / $TodaysData?.total_time?.unproductive > 0.1)
												)
												.map(([cat, data]) => ({
													source: 'Unproductive',
													target: cat?.split('|')[0] || 'Uncategorized',
													value: data?.duration / 3600
												})) || []
										).slice(0, 4)
									]
								}}
							/>
						{/key}
					{/if}
				</div>
			{/if}
			{#if show.includes('timeLines') && datesToAnalyse?.length && datesScreenTime}
				<div class="w-full relative" style="min-height: {datesToAnalyse.length > 7 ? 25.7 : 33}%;">
					<div class="relative mx-8 mr-12 h-full">
						<TimeAxis
							orientation={'horizontal'}
							refreshable={true}
							lineHeight={''}
							position={datesToAnalyse.length > 7 ? 'start' : 'end'}
						/>
						{#each (sessions || []).filter((a) => a.contexts?.length && a?.duration > 39) as context}
							<div
								class="reinvert {datesToAnalyse.length > 7
									? 'h-4'
									: 'h-5'} absolute z-10 hover:scale-110 hover:animate-pulse transition-transform context rounded-full"
								on:mouseenter={() => {
									context.hovering = true;
									setTimeout(() => {
										if (context.hovering) context.show = true;
									}, 400);
								}}
								on:mouseleave={() => {
									let wasKilled = false;
									context.hovering = false;
									setTimeout(() => {
										if (!context.hovering) {
											context.show = false;
											wasKilled = true;
										}
									}, 20);

									setTimeout(
										() => (!context.hovering && !wasKilled ? (context.show = false) : false),
										600
									);
								}}
								style="background:hsla({-10 +
									context.productivityScore ** 2 * 150} 100% 45% / 100%);width:{context.duration /
									(24.5 * 36)}%;left:{1.6 +
									dayjs(context.timestamp).diff(
										dayjs(context.timestamp)
											.subtract(4, 'hour')
											.startOf('day')
											.hour(4)
											.startOf('hour'),
										'second'
									) /
										(25 * 36)}%;top:{5 +
									Math.round(
										datesToAnalyse.length <= 7
											? (6 + dayjs(context.timestamp).subtract(4, 'hour').day()) % 7
											: dayjs(context.timestamp).subtract(4, 'hour').date() - 1
									) *
										(100 / 8)}%;"
							>
								<!-- hidden context details that shows on hover -->
							</div>
							<div
								class="fixed z-20 -translate-x-full right-3/4 top-12 bg-gray-900 text-white p-4 rounded-2xl shadow-lg"
								on:mouseenter={() => {
									context.hovering = true;
									setTimeout(() => (context.hovering = true), 50);
								}}
								on:mouseleave={() => {
									context.hovering = false;
									setTimeout(() => (!context.hovering ? (context.show = false) : false), 600);
								}}
								style={context.show
									? `left:${
											36 +
											(0.622 *
												dayjs(context.timestamp).diff(
													dayjs(context.timestamp)
														.subtract(4, 'hour')
														.startOf('day')
														.hour(4)
														.startOf('hour'),
													'second'
												)) /
												(24.5 * 36)
									  }%;width:400px;background:linear-gradient(153.72deg, #05107f 14.22%, #05138a 39.02%, #05138a 49.16%, #05108f 58.26%, #001579 73%, #002F82 92.24%, #002Fa1 95.76%);justify-content:space-around;align-items:center;${'gap:0.5rem;font-size: 1.5rem;'}font-weight:600;`
									: 'display:none;'}
							>
								{#if context.show}
									<div class="text-lg text-center">
										{dayjs(context?.timestamp).format('ddd DD MMM, HH:mm')}
									</div>
									<div class=" text-center">
										{makeDecimalIntoTime(context.duration / 3600, 'durationNoZero')}
									</div>
									<div class="min-width:min(380px, 100vw);width:100%;">
										<svelte:self
											since={dayjs(context?.timestamp)}
											divName={'contextDetails' + context.id}
											summary={true}
											numberOfMinutes={Math.ceil(context?.duration / 60) || 25}
											show={['time', 'activities', 'flow']}
										/>
									</div>
								{:else}
									Click for details
								{/if}
							</div>
						{/each}
					</div>
					{#each datesToAnalyse as date, i}
						<div
							class="absolute left-0 w-full flex justify-between items-center"
							style="top:{6 + i * (100 / 8)}%;"
						>
							<div
								class="absolute centreInContext left-1/2 h-px w-full bg-gray-600 text-xs bg-opacity-20"
							/>
							<div class="text-gray-300 text-xs">
								{dayjs(date).format('ddd DD')}
							</div>
							<div class=" text-gray-300 z-30 relative font-medium opacity-80 text-xs">
								{makeDecimalIntoTime(
									datesScreenTime
										?.find((d) => dayjs(d.start).format('YYYY-MM-DD') === date)
										?.window?.sessions?.map((s) => s.contexts)
										.flat()
										.filter((s) => s)
										?.reduce((a, b) => a + b?.duration, 0) / 3600,
									'durationNoZero'
								)}
							</div>
							<div style="display:none" class=" animate-spin" />
							<div
								class="hidden cursor-pointer absolute -right-4 text-slate-400 opacity-80 text-xs"
							>
								<svg
									on:click={async (e) => {
										console.log(e, e.target?.classList);
										e.target?.classList?.add('animate-spin');
										await dataGetters['activitywatch'](
											dayjs().subtract(4, 'hour').diff(dayjs(date), 'day'),
											true,
											true
										);
										setTimeout(() => {
											e.target?.classList?.remove('animate-spin');
										}, 9000);
									}}
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fill-rule="evenodd"
										d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
						</div>
						<!-- <div class="-mb-[0.42rem]" style="height:{100 / 7}%;position:relative;top:1rem;">
					<AreaLineGraph
						options={{
							timePeriod: since ? 'minute' : 'hour',
							type: 'time',
							area: true,
							minHeight: 100 + '%',
							divName: 'timeSparkLine' + divName + date,
							colors: ['#00ff33', '#dd0033', '#7176ff'],
							grid: { top: 0, bottom: 0, left: 0, right: 0 },
							data: [
								bucketEventsByTimePeriod(
									datesScreenTime[i]?.window?.events
										?.filter((a) => a.work)
										?.filter(
											(event) =>
												!since ||
												(dayjs(event?.endDate) > since &&
													dayjs(event?.timestamp) < since?.add(numberOfMinutes, 'minute'))
										) || [],
									{
										timePeriod: 'minute',
										quantityOfPeriod: since ? 1 : 30,
										splitSpanningEvents: true
									}
								),
								bucketEventsByTimePeriod(
									datesScreenTime[i]?.window?.events
										?.filter((a) => !a.work)
										?.filter(
											(event) =>
												!since ||
												(dayjs(event?.endDate) > since &&
													dayjs(event?.timestamp) < since?.add(numberOfMinutes, 'minute'))
										) || [],
									{
										timePeriod: 'minute',
										quantityOfPeriod: since ? 1 : 30,
										splitSpanningEvents: true
									}
								)
							],
							category: datesScreenTime[i].productivityByHour.map((a) => dayjs(a.start)),
							name: 'TimeSparkLine',
							showAxis: false,
							xAxisInGraph: divName === 'Main' ? 30 : 8,
							markArea: false,
							flipIndexes: [1],
							lineOnlyIndexes: [2],
							minMax: { min: since ? -60 : -2400, max: since ? 120 : 2400 }
						}}
						{dataIndex}
					/>
				</div> -->
					{/each}
				</div>
			{/if}

			{#if show?.includes('timeLine') && !small && !$DateRange.daysInPast}
				{#key updateOnInterval}
					<div
						class="absolute h-[69%] w-px z-10"
						style="background:#387FFF;top:5.65rem;left: {1.6 +
							dayjs().diff(
								dayjs().subtract(4, 'hour').startOf('day').hour(4).startOf('hour'),
								'second'
							) /
								(24 * 36)}%;"
					/>
					<div
						class="absolute rounded-full h-[6px] w-[6px] z-10 -translate-x-1/2"
						style="background:#387FFF;top:5.65rem;left: {1.6 +
							dayjs().diff(
								dayjs().subtract(4, 'hour').startOf('day').hour(4).startOf('hour'),
								'second'
							) /
								(24 * 36)}%;"
					/>
				{/key}
				<!-- <div
			class="h-8 relative -mt-2 border-gray-800 border border-opacity-20 bg-black bg-opacity-5 rounded-lg"
		>
			<div class="font-bold text-xs absolute centreInContext left-10">Activities</div>
			{#each $TodaysData?.activitywatch?.window?.sessions || [] as session}
				<div
					class="absolute h-full top-0"
					style="width:{session.duration / (24 * 36)}%;left: {dayjs(session.timestamp).diff(
						dayjs().startOf('day').hour(4).startOf('hour'),
						'second'
					) /
						(24 * 36)}%;background: rgba(20, 85, 255, 0.2);
	mix-blend-mode: lighten;
	box-shadow: inset 0px 0px 4px 1px rgba(20, 85, 255, 0.8);
	border-radius: 8px;"
				/>
			{/each}
		</div> -->
			{/if}
			{#if show?.includes('metricGraphs') && !small}
				<MetricGraphs
					{divName}
					{productivityMetrics}
					{since}
					{total_time}
					{...{
						datesScreenTime,
						events,
						nowInMinutes,
						todayInHours,
						dayInHours,
						datesToAnalyse,
						dataIndex
					}}
					showImpacts={false}
					bind:showDetailsFor={$ShowDetailsFor.time}
				/>
			{/if}
			{#if show?.includes('categoryBreakdown') && !small}
				<div class="flex gap-2 justify-between flex-col sm:flex-row items-center">
					<div class="h-32 w-32 z-0 pt-1">
						<CategoryPie
							showLabel={false}
							minHeight="{'90px'};"
							divName={'ScreenTimeCategoryPie'}
							data={categories
								?.map(([cat, data]) => ({
									name: cat?.split('|')[0] || 'Uncategorized',
									value: Math?.floor(data?.duration / 36) / 100
								}))
								.slice(0, seeAllCategories ? categories.length : 6)}
						/>
					</div>
					<div class="flex items-start gap-4 flex-col sm:flex-row flex-grow justify-between">
						<div class="text-center p-1 flex-grow">
							<div class="text-sm font-semibold mb-1 flex justify-between">
								Your top categories
								{#if categories.length > 6}
									<div
										class="cursor-pointer"
										on:click={() => {
											seeAllCategories = !seeAllCategories;
										}}
									>
										{seeAllCategories ? 'See less' : 'See all'}
									</div>
								{/if}
							</div>
							<div
								class="grid text-xs gap-x-2 text-left items-center"
								style="grid-template-columns: 1fr 0.6fr 0.5fr !important;min-width:250px;min-height:90px;"
							>
								{#each (categories?.length && categories.slice(0, seeAllCategories ? categories.length : 6)) || Array(6).fill( ['Loading', { duration: 0 }] ) as [cat, data], i}
									<div>{cat}</div>
									<div
										class="h-2/3 rounded-lg"
										style="width:{(100 * data?.duration) /
											categories?.[0]?.[1]?.duration}%;background:{[
											'#00ff8C',
											'#00D5ff',
											'#cc00f3',
											'#1455ff',
											'#7176ff',
											'#1188ff',
											'#ff44f3',
											'#00cc4C',
											'#ff0073'
										][i % 9]}"
									/>
									<div class="text-right w-[50px]">
										{makeDecimalIntoTime(data?.duration / 3600, 'durationNoZero')}
									</div>
								{/each}
							</div>
						</div>
						<div class=" sm:w-1/2 relative">
							<div
								class="{seeAll
									? `absolute overflow-y-auto  ${
											$IsNative ? '' : 'sm:p-8  sm:w-[560px]'
									  } bottom-0 left-0 z-50 bg-slate-900 rounded-xl border-slate-800 border-2 border-solid`
									: 'relative  w-full'} grid max-w-md mt-1 text-xs gap-x-4 text-left"
								style="filter: saturate(0.66) brightness(0.8);grid-template-columns: 1fr 1fr !important;max-height:90vh;{seeAll
									? 'min-height:12rem;'
									: 'min-height:6.5rem'}"
							>
								<!-- {#key events} -->

								{#if seeAll}
									<button
										class="h-max absolute z-50 shadow-none {$IsNative
											? `top-2 left-2 rounded-full p-3 bg-transparent`
											: 'top-2 right-2 p-2 text-xs bg-gray-800'} text-gray-400"
										on:click={() => (seeAll = false)}
									>
										{#if $IsNative}
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class={'h-7 w-7'}
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										{:else}esc{/if}
									</button>
								{/if}
								{#each [['working', (datesToAnalyse || since ? events : $TodaysData?.productiveEvents)?.filter((event) => event.work)], ['not working', (datesToAnalyse || since ? events : $TodaysData?.unproductiveEvents)?.filter((event) => !event.work)]] as [name, events], isNotWork}
									<div>
										{#if !divName.includes('Preview')}
											<div class="flex justify-between">
												<div class="capitalize font-semibold">{name}</div>
												<div
													style="color:{isNotWork ? '#dd0033' : '#00ff33'}"
													class="font-semibold text-xs"
												>
													{#each (total_time || $TodaysData?.total_time) && (total_time || $TodaysData?.total_time)[isNotWork ? 'unproductive' : 'productive'] !== undefined ? makeDecimalIntoTime((total_time || ($TodaysData?.total_time && $TodaysData?.total_time))[isNotWork ? 'unproductive' : 'productive'] / 3600) : ['--', '--'] as time, i}
														{time || 0}<span>{i ? '' : 'h '}</span>
													{/each}
												</div>
											</div>
										{/if}
										<div>
											{#each ((events.length && aggregateAndSort(events)) || Array(5).fill( ['', ''] )).slice(0, seeAll || divName.includes('TimeWorkingDetails') ? events?.length : divName.includes('Preview') ? 2 : show?.includes('tractionDays') ? 10 : 7) as [type, data], i}
												{#if !(type && data)}
													<div class="h-4" />
												{:else}
													<div
														on:click|stopPropagation={() => {
															activityDetails = { type, data, work: data.work };
															console.log({ activityDetails, data, type });
														}}
														on:mouseover={delay(
															() => {
																if (
																	recategoriseToShow.breakdown ==
																	JSON.stringify(
																		data?.app + data?.title + data?.url + data?.categories?.[0] + i
																	)
																)
																	data.hovering = true;
																recategoriseToShow.breakdown = JSON.stringify(
																	data?.app + data?.title + data?.url + data?.categories?.[0] + i
																);
															},
															recategoriseToShow.breakdown ? 0 : 173
														)}
														on:mouseleave={() => {
															data.hovering = false;
															setTimeout(() => {
																if (
																	!data.hovering &&
																	recategoriseToShow.breakdown ==
																		JSON.stringify(
																			data?.app +
																				data?.title +
																				data?.url +
																				data?.categories?.[0] +
																				i
																		)
																)
																	recategoriseToShow.breakdown = false;
															}, 250);
														}}
														on:focus={delay(
															() => {
																if (
																	recategoriseToShow.breakdown ==
																	JSON.stringify(
																		data?.app + data?.title + data?.url + data?.categories?.[0] + i
																	)
																)
																	data.hovering = true;
															},
															recategoriseToShow.breakdown ? 0 : 173
														)}
														on:blur={() => {
															data.hovering = false;
															setTimeout(() => {
																if (
																	!data.hovering &&
																	recategoriseToShow.breakdown ==
																		JSON.stringify(
																			data?.app +
																				data?.title +
																				data?.url +
																				data?.categories?.[0] +
																				i
																		)
																)
																	recategoriseToShow.breakdown = false;
															}, 250);
														}}
														class="flex relative justify-between items-center cursor-pointer"
														style="line-height:1.15;color:{data?.work
															? `${data?.focus ? '#00ff33' : '#ccdd55'}`
															: '#ff5050'}"
													>
														<div
															style="white-space: nowrap;"
															class={type && type?.split(': ')[0] === 'Site' ? '' : 'capitalize'}
														>
															{type && type?.split(': ')[1]?.slice(0, 20)}
														</div>
														<div style="white-space: nowrap;">
															{(data &&
																data?.duration &&
																makeDecimalIntoTime(
																	data?.duration < 0 ? 0 : data?.duration / 3600,
																	'durationNoZeroWithSeconds'
																)) ||
																'0s' ||
																''}
														</div>
														{#if !$IsNative && recategoriseToShow.breakdown == JSON.stringify(data?.app + data?.title + data?.url + data?.categories?.[0] + i)}
															<QuickRecategorize
																{i}
																parentShow={'breakdown'}
																bind:recategoriseToShow
																{divName}
																{categories}
																{data}
																{type}
															/>
														{/if}
													</div>
												{/if}
											{/each}
										</div>
										<!-- what app/url, category -->
									</div>
								{/each}

								<!-- <button
									class="absolute hidden z-10 shadow-none bottom-full left-1/2 -translate-x-1/2"
									on:click={() => (recategorize = true)}
								>
									Bulk Recategorize
								</button> -->

								{#if !divName.includes('Preview') && !divName.includes('TimeWorkingDetails')}
									<button
										on:click={() => (seeAll = !seeAll)}
										class="h-max {seeAll
											? ''
											: 'seeAll opacity-0'} transition-opacity py-0 pb-0.5 px-2 mx-auto font-semibold col-span-2 bg-gray-200 text-black cursor-pointer text-center text-xs"
									>
										{!seeAll ? 'See all' : 'See less'}
									</button>
								{/if}
								<!-- {/key} -->
							</div>
						</div>
					</div>
				</div>
			{/if}
			<!-- {#if $TodaysData?.productiveEvents && show?.includes('bar') && !small}
		<div class="w-full mt-2">
			<div class="text-center flex justify-between w-full {!small ? 'mt-2' : '-mt-2'} text-xs">
				{#each $TodaysData?.proportions as proportion, i}
					<span style="color:{['#00ff33', '#ff5050'][i]}">
						{Math?.round(1000 * proportion) / 10}%
					</span>
				{/each}
			</div>
			<div class="h-2 w-full flex rounded-full overflow-hidden mb-2">
				{#each $TodaysData?.proportions as proportion, i}
					<div
						style="width:{100 * proportion}%;height:100%;background:{[
							'#00ff33',
							// '#aaaaaa',
							'#ff5050'
						][i]}"
					/>
				{/each}
			</div>
		</div>
	{/if} -->
			{#if show?.includes('activities') && !small}
				<ActivityLists
					bind:seeAll
					bind:activityDetails
					{...{ divName, categories, total_time, activities }}
				/>
			{/if}
		</div>
	{/if}
	{#if showActivity}
		<Activity increase={showActivity} {summary} bind:showActivity />
	{/if}
	<Modal bind:show={showiOSModal} absolute={true}>
		<p>
			You'll need to download our desktop app to start collecting and syncing screentime data with
			Magicflow. Drop us an email at <a
				href="mailto:founders@{$Version.app === 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com'}"
				>founders@{$Version.app === 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com'}</a
			> to get access!
		</p>
	</Modal>

	{#if activityDetails}
		<Modal
			bind:show={activityDetails}
			options={{ topLevel: true, cssText: 'z-index:52;', fullScreen: true }}
		>
			<ActivityDetails {todaysDayjs} {events} {activityDetails} />
		</Modal>
	{/if}
	{#if recategorize}
		<Modal
			bind:show={recategorize}
			on:close={() => {
				recategorize = false;
			}}
			options={{ topLevel: true, cssText: 'z-index:52;', fullScreen: true }}
		>
			<div>
				<Recategorize
					divName={divName + (datesToAnalyse || []).join(':')}
					events={events || $TodaysData?.activitywatch?.window?.events}
				/>
			</div>
		</Modal>
	{/if}
	{#if seeAll}
		<Modal
			bind:show={seeAll}
			on:close={() => (activityFilter = '')}
			options={{
				topLevel: true,
				fullScreen: true,
				name: 'seeAllActivities' + divName
			}}
		>
			<div class="flex justify-between gap-4">
				<div class="relative">
					<input
						class="top-0 left-2/3 absolute bg-black border-gray-700 rounded"
						placeholder="Filter"
						bind:value={activityFilter}
					/>
					<h2>Summarized activities</h2>
					<ActivityLists
						bind:seeAll
						bind:activityDetails
						{...{ divName, total_time, categories }}
						activities={activityFilter
							? activities.filter(
									(a) =>
										a?.[1]?.title?.toLowerCase().includes(activityFilter.toLowerCase()) ||
										a?.[0]?.split(':')[1]?.toLowerCase().includes(activityFilter.toLowerCase())
							  )
							: activities}
					/>
				</div>
				<div class="text-xs pr-12 overflow-y-auto pb-8" style="max-height:92vh;">
					<h2>Full event stream</h2>
					{#each [...(activityFilter ? (events || []).filter((a) => a?.title
										?.toLowerCase()
										.includes(activityFilter.toLowerCase())) : events || [])].reverse() || [] as data, i}
						<div
							on:click|stopPropagation={() => {
								activityDetails = { data, work: data.work };
								console.log({ activityDetails, data });
							}}
							on:mouseover={delay(
								() => {
									// console.log('mouseenter');
									if (
										recategoriseToShow.activities ===
										JSON.stringify(data?.app + data?.title + data?.url + data?.categories?.[0] + i)
									)
										data.hovering = true;
									recategoriseToShow.activities = JSON.stringify(
										data?.app + data?.title + data?.url + data?.categories?.[0] + i
									);
								},
								recategoriseToShow.activities ? 0 : 473
							)}
							on:mouseleave={() => {
								console.log('mouseleave');
								data.hovering = false;
								setTimeout(() => {
									if (
										!data.hovering &&
										recategoriseToShow.activities ==
											JSON.stringify(
												data?.app + data?.title + data?.url + data?.categories?.[0] + i
											)
									)
										recategoriseToShow.activities = false;
								}, 250);
							}}
							on:focus={delay(
								() => {
									console.log('focus');
									if (
										recategoriseToShow.activities ===
										JSON.stringify(data?.app + data?.title + data?.url + data?.categories?.[0] + i)
									)
										data.hovering = true;
									recategoriseToShow.activities = JSON.stringify(
										data?.app + data?.title + data?.url + data?.categories?.[0] + i
									);
								},
								recategoriseToShow.activities ? 0 : 473
							)}
							on:blur={() => {
								console.log('blur');
								data.hovering = false;
								setTimeout(() => {
									if (
										!data.hovering &&
										recategoriseToShow.activities ==
											JSON.stringify(
												data?.app + data?.title + data?.url + data?.categories?.[0] + i
											)
									)
										recategoriseToShow.activities = false;
								}, 250);
							}}
							class="flex my-0.5 relative justify-between gap-4 items-center cursor-pointer"
							style="line-height:1.15;filter:saturate(0.75);color:{data?.work
								? `${data?.focus ? '#00ff33' : '#ccdd55'}`
								: '#ff5050'}"
						>
							<div style="font-variant-numeric:tabular-nums;">
								{dayjs(data.timestamp).format('HH:mm:ss')}
							</div>
							<div style="white-space: nowrap;margin-right:auto;">
								{((data.title || 'No title') + ' - ' + (data.url || data.app)).slice(0, 50)}
							</div>

							<div
								class="rounded-full uppercase ml-auto font-semibold text-black px-2 py-0.5"
								style="background-color:{data.work ? '#00ff33' : '#ff5050'};"
							>
								{data.categories[0]}
							</div>

							<div style="white-space: nowrap;width:3rem;text-align:right;">
								{(data &&
									data?.duration &&
									makeDecimalIntoTime(
										data?.duration < 0 ? 0 : data?.duration / 3600,
										'durationNoZeroWithSeconds'
									)) ||
									'0s' ||
									''}
							</div>

							{#if !$IsNative && recategoriseToShow.activities == JSON.stringify(data?.app + data?.title + data?.url + data?.categories?.[0] + i)}
								<QuickRecategorize
									{divName}
									{i}
									parentShow={'activities'}
									bind:recategoriseToShow
									position={'left-full -bottom-2'}
									xShift={200}
									{categories}
									{data}
									type={data.url ? 'Site: ' + data.url : 'App: ' + data.app}
								/>
							{/if}
						</div>
					{/each}
				</div>
			</div>
		</Modal>
	{/if}
	{#if divName === 'Main'}
		<Modal
			absolute={true}
			seeThrough={true}
			showBg={false}
			show={productivityMetrics && !productivityMetrics.totalSessionTime}
			options={{ cssText: `width:auto;font-size:1.5rem;font-weight:600;padding:4rem;` }}
		>
			{$DateRange.daysInPast ? 'No ' : 'Getting '} data for {todaysDate}
		</Modal>
	{/if}
{/if}

<style lang="postcss">
	div:hover > .categorySwitcher {
		visibility: visible;
		pointer-events: all;
	}
	div:hover > .seeAll {
		opacity: 1;
	}
	div:hover > .hidden {
		display: block;
	}
	.bigMetric .metricText {
		background: linear-gradient(
			105deg,
			hsl(208, 99%, 173%) 7.76%,
			hsl(212, 99%, 70%) 32.35%,
			hsl(209, 99%, 71%) 99.72%
		);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}
	.bigMetric:not(.selected) {
		filter: brightness(0.9) saturate(0.7);
		opacity: 0.8;
		@apply bg-[#101527] bg-opacity-60;
	}
	.bigMetric:not(.selected):hover {
		@apply shadow-xl;
		filter: none !important;
	}
	.bigMetric {
		@apply transition-all cursor-pointer shadow-sm shadow-gray-800;
	}
	.bigMetric.selected {
		@apply bg-[#101527];
	}
	@media (prefers-color-scheme: light) {
		.bigMetric.selected {
			background: #060f1f !important;
		}
		.bigMetric:not(.selected):hover {
			@apply bg-opacity-90;
			filter: none;
		}
		.bigMetric:not(.selected) {
			@apply bg-[#060f1f] bg-opacity-40;
		}
	}
</style>
