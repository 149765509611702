<script>
	import Icon from '../Icon.svelte';
	import { TodaysData } from '$lib/store.js';
	import { makeDecimalIntoTime } from '$lib/connectUtils.js';

	export let small = false,
		showImpacts = true,
		since;
	$: if (small) showImpacts = false;
	export let metricsToShow;
	export let divName = 'default';
	export let showDetailsFor = undefined;
	export let productivityMetrics;
	export let total_time = $TodaysData?.total_time;
	export let slot;
	export let factors;
	// $: console.log({ total_time });

	$: productivityMetrics = productivityMetrics || $TodaysData?.activitywatch?.productivityMetrics;
	$: factors = [
		0.1 + (productivityMetrics?.contextSwitchFactor - 1) * productivityMetrics?.ratio,
		0.1 + productivityMetrics?.ratio - 1,
		0.1 +
			(productivityMetrics?.sessionLengthFactor - 1) *
				productivityMetrics?.contextSwitchFactor *
				productivityMetrics?.ratio
	];
	$: dayMetricsToShow = [
		[
			'context switches',
			Math.round(productivityMetrics?.contextSwitches),
			[
				{
					title: 'in flow',
					condition: () => productivityMetrics?.contextSwitchRate <= 2,
					readableCondition: '< 2'
				},
				{
					title: 'on it',
					condition: () => productivityMetrics?.contextSwitchRate < 5,
					readableCondition: '3 - 5'
				},
				{
					title: 'OK',
					condition: () => productivityMetrics?.contextSwitchRate < 12.5,
					readableCondition: '5 - 12'
				},
				{
					title: 'twitchy',
					condition: () => productivityMetrics?.contextSwitchRate < 30,
					readableCondition: '13 - 30'
				},
				{
					title: 'scattered',
					condition: () => productivityMetrics?.contextSwitchRate >= 30,
					readableCondition: '30+'
				}
			]
		],
		...(slot ? [['placeholder', 'a', []]] : []),
		[
			'deep work',
			makeDecimalIntoTime(total_time?.deep / 3600),
			[
				{
					title:
						Math.min(
							100,
							Math.round(
								100 *
									(total_time?.deep /
										(since
											? productivityMetrics?.sessionLength
											: total_time?.unproductive || 0 + total_time?.productive || 0)) || 0
							)
						) + '%',
					condition: () =>
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: total_time?.unproductive || 0 + total_time?.productive || 0) >
						0.8,
					readableCondition: '80 - 100%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: total_time?.unproductive || 0 + total_time?.productive || 0)) || 0
						) + '%',
					condition: () =>
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: total_time?.unproductive || 0 + total_time?.productive || 0) >
						0.6,
					readableCondition: '60 - 80%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: total_time?.unproductive || 0 + total_time?.productive || 0)) || 0
						) + '%',
					condition: () =>
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: total_time?.unproductive || 0 + total_time?.productive || 0) >
						0.3,
					readableCondition: '30 - 60%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: total_time?.unproductive || 0 + total_time?.productive || 0)) || 0
						) + '%',
					condition: () =>
						(total_time?.deep || total_time?.unproductive || 0 + total_time?.productive || 0) &&
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: total_time?.unproductive || 0 + total_time?.productive || 0) <=
							0.3,
					readableCondition: '1 - 30%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: total_time?.unproductive || 0 + total_time?.productive || 0)) || 0
						) + '%',
					condition: () =>
						!(total_time?.deep || total_time?.unproductive || 0 + total_time?.productive || 0),
					readableCondition: '0%'
				}
			]
		]
	];
	$: metricsToShow = [
		[
			'time working',
			Math.round(productivityMetrics?.ratio * 100),
			[
				{
					title: 'in flow',
					condition: () => productivityMetrics?.ratio >= 0.9,
					readableCondition: '90 - 100%'
				},
				{
					title: 'working',
					condition: () => productivityMetrics?.ratio >= 0.7,
					readableCondition: '70 - 90%'
				},
				{
					title: 'unfocused',
					condition: () => productivityMetrics?.ratio > 0.5,
					readableCondition: '50 - 70%'
				},
				{
					title: 'distracted',
					condition: () => productivityMetrics?.ratio <= 0.5,
					readableCondition: '0 - 50%'
				},
				{ title: 'break', condition: () => !productivityMetrics?.ratio, readableCondition: '0%' }
			]
		],
		...(slot ? [['placeholder', 'a', []]] : []),
		[
			'deep work',
			makeDecimalIntoTime(total_time?.deep / 3600),
			[
				{
					title:
						Math.min(
							100,
							Math.round(
								100 *
									(total_time?.deep /
										(since
											? productivityMetrics?.sessionLength
											: total_time?.unproductive || 0 + total_time?.productive || 0)) || 0
							)
						) + '%',
					condition: () =>
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: total_time?.unproductive || 0 + total_time?.productive || 0) >
						0.8,
					readableCondition: '80 - 100%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: total_time?.unproductive || 0 + total_time?.productive || 0)) || 0
						) + '%',
					condition: () =>
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: total_time?.unproductive || 0 + total_time?.productive || 0) >
						0.6,
					readableCondition: '60 - 80%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: total_time?.unproductive || 0 + total_time?.productive || 0)) || 0
						) + '%',
					condition: () =>
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: total_time?.unproductive || 0 + total_time?.productive || 0) >
						0.3,
					readableCondition: '30 - 60%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: total_time?.unproductive || 0 + total_time?.productive || 0)) || 0
						) + '%',
					condition: () =>
						(total_time?.deep || total_time?.unproductive || 0 + total_time?.productive || 0) &&
						total_time?.deep /
							(since
								? productivityMetrics?.sessionLength
								: total_time?.unproductive || 0 + total_time?.productive || 0) <=
							0.3,
					readableCondition: '1 - 30%'
				},
				{
					title:
						Math.round(
							100 *
								(total_time?.deep /
									(since
										? productivityMetrics?.sessionLength
										: total_time?.unproductive || 0 + total_time?.productive || 0)) || 0
						) + '%',
					condition: () =>
						!(total_time?.deep || total_time?.unproductive || 0 + total_time?.productive || 0),
					readableCondition: '0%'
				}
			]
		],
		[
			'context switches',
			Math.round(productivityMetrics?.contextSwitches),
			[
				{
					title: 'in flow',
					condition: () => productivityMetrics?.contextSwitchRate <= 2,
					readableCondition: '< 2'
				},
				{
					title: 'on it',
					condition: () => productivityMetrics?.contextSwitchRate < 5,
					readableCondition: '3 - 5'
				},
				{
					title: 'OK',
					condition: () => productivityMetrics?.contextSwitchRate < 12.5,
					readableCondition: '5 - 12'
				},
				{
					title: 'twitchy',
					condition: () => productivityMetrics?.contextSwitchRate < 30,
					readableCondition: '13 - 30'
				},
				{
					title: 'scattered',
					condition: () => productivityMetrics?.contextSwitchRate >= 30,
					readableCondition: '30+'
				}
			]
		],
		[
			'session length',
			makeDecimalIntoTime(productivityMetrics?.sessionLength / 3600, 'durationNoZero'),
			[
				{
					title: 'in flow',
					condition: () => productivityMetrics?.sessionLength > 2700,
					readableCondition: '> 45m'
				},
				{
					title: 'on it',
					condition: () => productivityMetrics?.sessionLength > 1800,
					readableCondition: '30 - 45m'
				},
				{
					title: 'OK',
					condition: () => productivityMetrics?.sessionLength > 900,
					readableCondition: '15 - 30m'
				},
				{
					title: 'twitchy',
					condition: () => productivityMetrics?.sessionLength > 300,
					readableCondition: '5 - 15m'
				},
				{
					title: 'scattered',
					condition: () => productivityMetrics?.sessionLength <= 300,
					readableCondition: '< 5m'
				}
			]
		]
	];
</script>

<div
	id="topLevel"
	class="grid max-w-xl mx-auto w-full gap-x-1 gap-y-4 mb-2 text-center items-center justify-items-center"
	style="grid-template-columns: 1fr 1fr 1fr !important"
>
	{#each small ? dayMetricsToShow : metricsToShow as [title, metric, judgement], i}
		{#if i === 1 && slot}
			<div
				on:click|stopPropagation={() => {
					if (!divName.startsWith('Activity')) showDetailsFor = 'productivity';
				}}
				class="{(small ? dayMetricsToShow : metricsToShow).length > 3
					? 'row-span-2'
					: ''} flex w-full h-min justify-center items-center {!divName.startsWith('Activity')
					? 'cursor-pointer opacity-90'
					: ''}"
			>
				<slot />
			</div>
		{:else}
			<div
				id="topLevel{title.replace(' ', '')}"
				on:click|stopPropagation={() => {
					if (!divName.startsWith('Activity')) showDetailsFor = title.replace('deep work', 'deep');
				}}
				class="{!divName.startsWith('Activity')
					? 'cursor-pointer hover:bg-gray-800 h-min hover:bg-opacity-50'
					: ''} {slot
					? '' //opacity-0
					: ''} transition-opacity bg-gray-900 bg-opacity-60 w-full border-2 border-gray-800 border-opacity-40 shadow-2xl p-0 pt-2 py-3 flex gap-2 items-center relative"
				style="border-radius: 1.25rem;"
			>
				<div class="flex flex-col items-start w-full flex-grow">
					<div class="flex gap-1 w-full items-center justify-center">
						<div class="border border-gray-800 border-opacity-60 p-1 rounded-lg">
							<Icon attribute={title} />
						</div>
						<div class="text-xl font-bold w-max text-left">
							{#if title === 'deep work'}
								{#each metric as time, i}
									{#if time || i}
										{time || 0}<span class="text-base">{i ? 'm' : 'h '}</span>
									{/if}
								{/each}
							{:else}
								{metric || 0}
							{/if}
							<div
								class="absolute reinvert centreInContext z-10 top-full px-2 font-semibold uppercase text-xs w-max rounded"
								style="background:hsla({120 -
									(judgement?.findIndex((j) => j.condition()) * 25 || 20)} 100% 37.5% / 100%);"
							>
								<div class="z-10">
									{judgement?.find((j) => j.condition())?.title || '-'}
								</div>
							</div>
						</div>
					</div>
					<div class="text-xs w-full capitalize text-gray-400">{title}</div>
				</div>
				<div
					class="absolute w-max hidden -bottom-16 font-semibold
					 z-20 bg-gray-900 border-2 border-gray-800 p-2 rounded-xl 
				{(!slot && !(i % 2)) || (slot && !(i % 3)) ? 'left-full' : 'right-full'} "
				>
					{#each judgement as j, i}
						<div class="flex gap-2 capitalize text-left my-1 justify-between">
							<div
								class="h-4 w-4 rounded"
								style="background:hsla({120 - (i * 25 || 20)} 100% 37.5% / 100%);"
							/>
							{#if title !== 'deep work'}
								<div class="text-xs w-max text-left mr-auto text-gray-100">{j.title}</div>
							{/if}
							<div class="text-xs text-gray-100">{j.readableCondition}</div>
						</div>
					{/each}
				</div>
			</div>
		{/if}
	{/each}
</div>
{#if showImpacts}
	<div
		class="relative max-w-sm text-sm w-3/4 mx-auto mt-2 col-span-3 rounded-2xl bg-gray-800 bg-opacity-60 border-2 border-gray-700 border-opacity-80 shadow-2xl p-3"
	>
		<div
			class="absolute w-3/4 text-xs text-center centreInContext top-0 bg-gray-800 rounded-full p-0.5 px-2 uppercase  border-2 border-gray-700 border-opacity-80"
		>
			Productivity Impacts
		</div>
		{#each [...(!small ? [['context switch rate', 'contextSwitchRate', 1, '/ hour']] : []), ['% time working', 'ratio', 100, '/ 100'], ...(!small ? [['session length', 'sessionLength', 1 / 60, 'minutes']] : [])] as [title, metric, multiplier, unit], i}
			<div class="flex justify-between items-center">
				<div class="capitalize text-gray-300 font-medium">
					{title === 'productivity' ? '% work' : title}
				</div>
				<div style="color:{factors[i] >= 0 ? '#00ff66' : '#ee3344'}">
					{(factors[i] > 0 ? '+' : '') + Math.round((factors[i] || 0) * 1000) / 10}
				</div>
			</div>
		{/each}
	</div>
{/if}

<style>
	/* div:hover > .hidden {
		display: block;
	} */
	/* div:hover > .opacity-0 {
		opacity: 1;
	} */
</style>
