<script context="module">
	import Echart from './Echart.svelte';
	import dayjs from 'dayjs';
	import { makeDecimalIntoTime } from '$lib/connectUtils.js';
</script>

<script>
	// 	import * as echarts from 'echarts/core';
	// import {
	//     GaugeChart
	// } from 'echarts/charts';
	// import {
	//     SVGRenderer
	// } from 'echarts/renderers';
	export let divName = 'echartPie';
	export let colors = ['#14c8d4', '#43eec6'];
	// export let showXAxis = true;
	export let minHeight = '120px';
	export let name;
	export let showLabel = true;
	export let data = [
		{ value: 1048, name: 'Search Engine' },
		{ value: 735, name: 'Direct' },
		{ value: 580, name: 'Email' },
		{ value: 484, name: 'Union Ads' },
		{ value: 300, name: 'Video Ads' }
	];

	$: option = {
		tooltip: {
			trigger: 'item',
			appendToBody: true,
			backgroundColor: '#112244',
			borderColor: '#223366',
			textStyle: {
				color: '#efefff'
			},
			valueFormatter: (v) => makeDecimalIntoTime(v, 'durationNoZero')
		},
		legend: {
			show: false
		},
		color: [
			'#00ff8C',
			'#00D5ff',
			'#cc00f3',
			'#1455ff',
			'#7176ff',
			'#1188ff',
			'#ff44f3',
			'#00cc4C',
			'#ff0073'
		],
		series: [
			{
				name: name || 'Category',
				type: 'pie',
				radius: ['50%', '77.5%'],
				center: ['50%', '50%'],
				startAngle: 180,
				avoidLabelOverlap: true,
				itemStyle: {
					borderRadius: 10,
					borderColor: '#222',
					borderWidth: 1
				},
				label: {
					show: showLabel,
					overflow: 'break',
					padding: 0,
					margin: 0,
					bleedMargin: 2,
					distanceToLabelLine: -20,
					color: 'white'
					// position: 'center'
				},
				emphasis: {
					label: {
						show: showLabel,
						fontSize: '20',
						fontWeight: 'normal'
					}
				},
				labelLine: {
					show: false
				},
				data
			}
		]
	};
</script>

<Echart {option} {divName} {minHeight} maxHeight={'250px'}>
	<slot />
</Echart>
<svg style="position:fixed;height:0;width:0;">
	<defs>
		<linearGradient
			id="{divName}23-gradient-0"
			x1="1"
			y1="0"
			x2="0"
			y2="0"
			gradientUnits="objectBoundingBox"
		>
			<stop offset="0%" stop-color={colors?.[0]} />
			<stop offset="100%" stop-color={colors[1]} />
		</linearGradient>
	</defs></svg
>

<style>
	:global([_echarts_instance_] svg > g:nth-child(2) > text) {
		stroke-width: 0;
	}
</style>
